import React, { useEffect, useState } from "react";
import { empDesignationData } from "../../services/api/user";

const EmpDetails = (subCatId, language) => {
  const [empList, setEmpList] = useState();
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);

  // Initialize form array with fields up to the maxFields prop valu
  const initialFormArray = Array(Math.min(1, 9)).fill({
    name: "",
    mobile: "",
    email: "",
    state: "",
    district: "",
  });

  const [formArray, setFormArray] = useState(initialFormArray);
  // Handler to add a new field, limited to a max length of 9
  const addField = () => {
    if (formArray.length < 9) {
      setFormArray([
        ...formArray,
        { name: "", mobile: "", email: "", state: "", district: "" },
      ]);
    }
  };

  // Handler to remove a field from the form array
  const removeField = (index) => {
    setFormArray(formArray.filter((_, i) => i !== index));
  };
  // Handler to update form field values
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedArray = [...formArray];
    updatedArray[index][name] = value;
    setFormArray(updatedArray);
  };
  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted Form Data:", formArray);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", subCatId?.subCatId);
      const response = await empDesignationData(formData);
      setEmpList(response?.data || []);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const empDetailsForm = async (item) => {
    try {
      console.log(item);
    } catch (err) {
      console.log(err?.message);
    }
  };

  return (
    <div className="">
      <div className="row">
        <div className="employe-details-tabs">
          <div className="emp-main-wrapper d-flex align-items-start justify-content-between">
            <div
              className="nav emp-tab-wrapper flex-column emp-left-tabs nav-pills me-5"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              {empList?.map((item, index) => (
                <div key={item?.id}>
                  <button
                    className={`nav-link ${
                      selectedModuleIndex === index ? " active" : ""
                    }`}
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={() => {
                      setSelectedModuleIndex(index);
                      empDetailsForm(item);
                    }}
                  >
                    {!language ? item?.name_hi : item?.name_en}
                  </button>
                </div>
              ))}
            </div>
            <form
              onSubmit={handleSubmit}
              class="tab-content emp-right-tab w-100"
              id="v-pills-tabContent"
            >
              <div className="emp-right-tab-wrapper">
                <div className="emp-right-header">
                  <h6 className="mb-0">Employee Details</h6>
                </div>

                <div className="tab-pane fade show active" id="v-pills-home">
                  <div className="row p-3 col-md-12">
                    {formArray.map((field, index) => (
                      <>
                        <div key={index} className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="en-label">
                                Name of GM/Manager
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Enter GM/Manager Name"
                              value={field.name}
                              onChange={(e) => handleInputChange(index, e)}
                              required
                            />
                          </div>
                        </div>
                        <div key={index} className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="en-label">
                                Mobile Number of GM/Manager
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="mobile"
                              placeholder="Enter Mobile Number"
                              value={field.mobile}
                              onChange={(e) => handleInputChange(index, e)}
                              required
                            />
                          </div>
                        </div>
                        <div key={index} className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="en-label">
                                Email of GM/Manager
                              </span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Enter Email"
                              value={field.email}
                              onChange={(e) => handleInputChange(index, e)}
                              required
                            />
                          </div>
                        </div>
                        <div key={index} className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="en-label">
                                State of GM/Manager
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="state"
                              placeholder="Select"
                              value={field.state}
                              onChange={(e) => handleInputChange(index, e)}
                              required
                            />
                          </div>
                        </div>
                        <div key={index} className="col-md-4 mb-3">
                          <div className="f_fields">
                            <label className="form-label">
                              <span className="en-label">
                                District of GM/Manager
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="district"
                              placeholder="Select"
                              value={field.district}
                              onChange={(e) => handleInputChange(index, e)}
                              required
                            />
                          </div>
                        </div>
                        {/* {formAr ray.length > 1 &&
                                                <button
                                                  type="button"
                                                  className="btn btn-danger mt-2"
                                                  onClick={() => removeField(index)}
                                                  disabled={formArray.length === 1}
                                              >
                                                  Remove
                                              </button>} */}
                      </>
                    ))}
                  </div>
                  {/* {formArray.length < 9  && (
                                      <button type="button" onClick={addField} className="btn btn-primary">
                                          Add Field
                                      </button>
                                  )} */}
                </div>
              </div>

              <div className="comment-form-cookies-consent d-flex align-items-center justify-content-start mt-4 mb-5">
                <input
                  id="wp-comment-cookies-consent"
                  className="mt-1"
                  name="wp-comment-cookies-consent"
                  type="checkbox"
                  value="yes"
                />
                <label htmlFor="wp-comment-cookies-consent" className="px-1">
                  I hereby confirm that all the information provided by me is
                  correct to my knowledge.
                </label>
              </div>

              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpDetails;
