import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon, AcceptRequest } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { RemoveSession } from "../../../Utils/Utils";
import Select from "react-select";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import { checkPattern } from "../../../Utils/common";
import { CgCheck } from "react-icons/cg";
import {
  getStateDropdown,
  getDistrictDropdown,
  addCityData,
  getCityListDataSort,
  activeInactiveCity,
  deleteCityData,
  updateCityData,
  getMobileEmailDataList,
  updateEmailMobileVerify,
} from "../../../services/api/admin";
import { BsChevronLeft } from "react-icons/bs";
import "../AdminGuidelines/AdminGuildlines.css";
import { HiPlus } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import { formatDateWithSlash } from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MobileEmailVerification = () => {
  const navigate = useNavigate();
  const [nameEn, setNameEn] = useState("");
  const [nameHi, setNameHi] = useState("");
  const [nameEnError, setNameEnError] = useState();
  const [nameHiError, setNameHiError] = useState();
  const [searchData, setSearchData] = useState("");
  const [sortByCity, setSortByCity] = useState("created_at");
  const [stateList, setStateList] = useState([]);
  const [editSateList, setEditStateList] = useState([]);
  const [editStateId, setEditStateId] = useState();
  const [editSateIdError, setEditStateIdError] = useState();
  const [searchState, setSearchState] = useState();
  const [fullDistrictListData, setfullDistrictListData] = useState();
  const [
    fullDistrictListDropdownData,
    setfullDistrictListDropdownData,
  ] = useState();
  const [editDistrictId, setEditDistrictId] = useState();
  const [editDistrictIdError, setEditDistrictIdError] = useState();
  const [searchDistrict, setSearchDistrict] = useState();
  const [searchDistrictList, setSearchDistrictList] = useState();
  const [editDistrictList, setEditDistrictList] = useState();
  const [editData, setEditData] = useState();
  const [requestList, setRequestList] = useState();
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [editContainer, setEditContainer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderByCity, setOrderByCity] = useState("desc");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });

  const handleKeyUpLoginDetails = (item) => {
    validateForm(item);
  };
  //validations
  const validateForm = (item) => {
    let isValid = true;
    if (item === "stateId" || item === "both") {
      if (!editStateId) {
        isValid = false;
      } else {
      }
    }
    if (item === "districtId" || item === "both") {
      if (!editDistrictId) {
        isValid = false;
      } else {
      }
    }
    if (item === "nameEn" || item === "both") {
      if (!nameEn || nameEn.trim() === "") {
        isValid = false;
      } else {
      }
    }
    if (item === "nameHi" || item === "both") {
      if (!nameHi || nameHi.trim() === "") {
        isValid = false;
      } else {
      }
    }

    return isValid;
  };
  // add city
  const addCity = async () => {
    if (!validateForm("both")) return;
    try {
      const formData = new FormData();
      formData.append("name_en", nameEn);
      formData.append("name_hi", nameHi);
      // const keyName = nameEn?.toLowerCase()?.replaceAll(" ", "_")
      // formData.append("tab_key", keyName)
      // formData.append("status", "1")
      // formData.append("font_family_id", fontFamily?.value)
      // formData.append("font_color", fontColor)
      // formData.append("font_size", fontSize)
      // formData.append("sort", 1)
      // formData.append("background_color", backgroundColor)
      const data = await addCityData(formData);
      if (data?.status == 200) {
        setEditContainer(false);
        setNameEn("");
        setNameHi("");
        getEmailMobileDataList("", "", "created_at", 1, "desc", "");
        setApiMessage({ type: "success", message: data?.message });
      } else if (data?.status == 403) {
        setApiMessage({ type: "error", message: data?.message });
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const resetFilter = () => {
    getEmailMobileDataList("", "", sortByCity, 1, orderByCity, searchData);
  };

  const handlePageChange = (selectedPage) => {
    getEmailMobileDataList(
      searchState,
      searchDistrict,
      sortByCity,
      selectedPage.selected + 1,
      orderByCity,
      searchData
    );
  };

  const handleAccept = async (item) => {
    try {
      const payload = { status: 1, request_id: item };
      const response = await updateEmailMobileVerify(payload);
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response?.message });
        getEmailMobileDataList(
          searchState,
          searchDistrict,
          sortByCity,
          currentPage + 1,
          orderByCity,
          searchData
        );
      } else {
        setApiMessage({ type: "error", message: response?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const handleReject = async (item) => {
    try {
      const payload = { status: 2, request_id: item }; // 2 for rejected
      const response = await updateEmailMobileVerify(payload);
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response?.message });
        getEmailMobileDataList(
          searchState,
          searchDistrict,
          sortByCity,
          currentPage + 1,
          orderByCity,
          searchData
        );
      } else {
        setApiMessage({ type: "error", message: response?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  // City list
  const getEmailMobileDataList = async (
    searchedState,
    searchedDistrict,
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy)
      setOrderByCity(orderBy)
      setSortByCity(sortBy)
      const requestList = await getMobileEmailDataList(pageNo)
      setTotalPages(Math.ceil(requestList?.data?.total / requestList?.data?.per_page));
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo)
      const data = requestList?.data?.data
      setRequestList(data)
      setTableDataLoaded(true)
    } catch (err) {
      console.log("error on requestList: ", err);
    }
  };

  useEffect(() => {
    //getFontFamily();
    getEmailMobileDataList("", "", "created_at", 1, "desc", "");
  }, []);

  const handleEmptyData = () => {
    setEditContainer(false);
    setNameEn("");
    setNameHi("");
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    // Check for "nameEn" field
    if (name === "nameEn") {
      if (await checkPattern(value)) {
        setNameEn(value);
      } else {
        e.preventDefault();
        return;
      }
    }

    // Check for "nameHi" field
    if (name === "nameHi") {
      if (await checkPattern(value)) {
        setNameHi(value);
      } else {
        e.preventDefault();
        return;
      }
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        {editContainer ? (
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span> City Form</span>
              </div>
            </div>
            <div className="aod-inner">
              <div
                className={
                  editData?.id ? "aod-update aod-bottom" : "aod-bottom"
                }
              >
                <div className="row py-4">
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">English Name</label>
                      <input
                        className="w-100"
                        placeholder="Enter English Name"
                        type="text"
                        name="nameEn"
                        value={nameEn}
                        maxLength={100}
                        onChange={handleInputChange}
                      />

                      {nameEnError && (
                        <p className="validate-error">{nameEnError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Hindi Name</label>
                      <input
                        className="w-100"
                        placeholder="Enter Hindi Name"
                        type="text"
                        name="nameHi"
                        value={nameHi}
                        maxLength={100}
                        onChange={handleInputChange}
                      />

                      {nameHiError && (
                        <p className="validate-error">{nameHiError}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block"></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span> Update-Details-Requests</span>
              </div>
            </div>
            <div className="aod-inner pt-0">
              <div className="aod-bottom">
                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                  <h4 className="mb-0 w-100">Update Details Requests</h4>
                  <div className="suf-input-box aod-form-input mb-2 ms-3 w-50">
                    {/* <input
                      className="w-100 form-control"
                      placeholder="search"
                      type="text"
                      onChange={(e) => {
                        const trimmedValue = e.target.value.trim(); // Trim the input value
                        getEmailMobileDataList(
                          searchState,
                          searchDistrict,
                          sortByCity,
                          1,
                          orderByCity,
                          trimmedValue
                        );
                      }}
                      onPaste={(e) => {
                        e.preventDefault(); // To prevent the default behaviour
                        const pastedData = e.clipboardData
                          .getData("Text")
                          .trim();
                        document.execCommand("insertText", false, pastedData); // To paste the trimmed value in input box
                      }}
                    /> */}
                  </div>
                </div>
                <div className="aod-head"></div>
                <div className="row py-4"></div>
                <div className="ad-reg-table">
                  <Table size="sm" className="ad-cat-table">
                    <thead>
                      <tr>
                        <th className="ad-sno">S No</th>
                        <th className="ad-long">Customer Id</th>
                        <th className="ad-long">Name</th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getEmailMobileDataList(
                              searchState,
                              searchDistrict,
                              "state_id",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Request Type{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getEmailMobileDataList(
                              searchState,
                              searchDistrict,
                              "district_id",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Existing{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getEmailMobileDataList(
                              searchState,
                              searchDistrict,
                              "district_id",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Requested{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th className="ad-long">Status</th>
                        <th className="ad-long">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestList?.length > 0 ? (
                        requestList?.map((item, index) => (
                          <tr key={index}>
                            <td>{currentPage * 10 + (index + 1)}</td>
                            <td>{item?.unique_id ? item?.unique_id : "--"}</td>
                            <td>{item?.name ? item?.name : "--"}</td>
                            <td>
                              {/* Display request type based on the 'type' field */}
                              {item.type === "1"
                                ? "Mobile"
                                : item.type === "2"
                                ? "Email"
                                : "Mobile | Email"}
                            </td>
                            <td>
                              {/* Show old and new mobile numbers based on the type */}
                              {item.type === "1" ? (
                                <>
                                  <div>{item.old_email}</div>
                                </>
                              ) : item.type === "3" ? (
                                <>
                                  <div>
                                    <strong>Mobile:</strong>{" "}
                                    {item.old_mobile_number} <br></br>{" "}
                                    <strong>Email:</strong> {item.old_email}
                                  </div>
                                </>
                              ) : item.type === "2" ? (
                                <>
                                  <div>{item.old_mobile_number}</div>
                                </>
                              ) : (
                                <div>--</div>
                              )}
                            </td>
                            <td>
                              {/* Show old and new emails based on the type */}
                              {item.type === "1" ? (
                                <>
                                  <div> {item.new_email || "--"}</div>
                                </>
                              ) : item.type === "3" ? (
                                <>
                                  <strong>Mobile:</strong> {item.new_mobile}{" "}
                                  <br></br> <strong>Email:</strong>{" "}
                                  {item.new_email}
                                </>
                              ) : item.type === "2" ? (
                                <>
                                  <div>{item.new_mobile}</div>
                                </>
                              ) : (
                                <div>--</div>
                              )}
                            </td>
                            <td>
                              {item.status === "0"
                                ? "Pending"
                                : item.status === "1"
                                ? "Accepted"
                                : "Rejected"}
                            </td>
                            <td>
                              <div className="action-btn d-flex">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`edit-tooltip-${item.id}`}>
                                      Accept
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-edit edit-check-icon"
                                    onClick={() => handleAccept(item.id)}
                                  >
                                    <CgCheck />
                                  </span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`delete-tooltip-${item.id}`}>
                                      Reject
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-delete"
                                    onClick={() => handleReject(item.id)}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : tableDataLoaded ? (
                        <NotFoundTable colSpan="8"></NotFoundTable>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex w-100 justify-content-end">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages} // Total number of pages from API
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageChange} // Method to handle page click
                      forcePage={currentPage}
                      containerClassName={"pagination justify-content-center"} // Custom CSS classes
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={`page-item${
                        !requestList?.length > 0 ? " disabled" : ""
                      }`}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete this City ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button>Yes</button>
            <button className="suf-can-butt" onClick={() => setDeleteData("")}>
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default MobileEmailVerification;
