import React, { useState, useEffect } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import "../User/User.css";
import { changePasswordAPI } from '../../services/api/user';
import { useNavigate } from 'react-router-dom';
import { Message } from '../../components/message'

const ChangePassword = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate()
  // State for password fields and errors
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  // Validation for passwords
  const validatePasswords = () => {
    const errors = {};
    // Validation for Old Password
    if (!oldPassword) {
      errors.oldPassword = "Old password is required";
    }
    // Validation for New Password
    if (!newPassword) {
      errors.newPassword = "New password is required";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters";
    } else if (newPassword.length > 39) {
      errors.newPassword = "Password cannot exceed 39 characters";
    } else {
      // Additional password strength requirements
      if (!/[a-z]/.test(newPassword)) {
        errors.newPassword = "Password must contain at least one lowercase letter (a-z)";
      }
      if (!/[A-Z]/.test(newPassword)) {
        errors.newPassword = "Password must contain at least one uppercase letter (A-Z)";
      }
      if (!/[0-9]/.test(newPassword)) {
        errors.newPassword = "Password must contain at least one number (0-9)";
      }
      if (!/[@$!%*?&]/.test(newPassword)) {
        errors.newPassword = "Password must contain at least one special character (@$!%*?&)";
      }
    }
    // Ensure Old Password and New Password are not the same
    if (oldPassword && newPassword && oldPassword === newPassword) {
      errors.newPassword = "New password must be different from the old password";
    }
    // Validation for Confirm Password
    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your new password";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    // Set error messages in English and Hindi
    if (Object.keys(errors).length > 0) {
      setErrors({
        ...errors,
        hindi: {
          oldPassword: "पुराना पासवर्ड आवश्यक है",
          newPassword: {
            required: "नया पासवर्ड आवश्यक है",
            length: "पासवर्ड कम से कम 6 और अधिकतम 39 वर्णों का होना चाहिए",
            lowercase: "पासवर्ड में कम से कम एक लोअरकेस अक्षर होना चाहिए",
            uppercase: "पासवर्ड में कम से कम एक बड़ा (अपरकेस) अक्षर होना चाहिए",
            numeric: "पासवर्ड में कम से कम एक नंबर होना चाहिए [0-9]",
            special: "पासवर्ड में कम से कम एक विशेष वर्ण होना चाहिए (@$!%*?&)",
            different: "नया पासवर्ड पुराने पासवर्ड से भिन्न होना चाहिए"
          },
          confirmPassword: {
            required: "कृपया अपना नया पासवर्ड पुष्टि करें",
            match: "पासवर्ड मेल नहीं खाते"
          }
        }
      });
    } else {
      setErrors(errors);
    }
  
    return Object.keys(errors).length === 0;
  };
  
  

  const handleSubmitLogin = async () => {
    if (!validatePasswords()) return;
  
    try {
      const formData = new FormData();
      formData.append("old_password", oldPassword);
      formData.append("password", newPassword);
      formData.append("password_confirmation", confirmPassword);
  
      const response = await changePasswordAPI(formData);
      
      // Assuming the API response structure as provided
      if (response?.status === 200) {
        setApiMessage({ type: "success", message: response.message });
      } else if (response?.data) {
        // Handle validation errors from the API response
        const errorMessages = [];
        if (response.data.password && response.data.password.length > 0) {
          errorMessages.push(response.data.password[0]);
        }
        if (response.data.password_confirmation && response.data.password_confirmation.length > 0) {
          errorMessages.push(response.data.password_confirmation[0]);
        }
  
        setApiMessage({
          type: "error",
          message: errorMessages.join(", ") || response.message,
        });
      }
    } catch (err) {
      setApiMessage({ type: "error", message: err.message });
    }
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Dashboard
        first={language ? "होम" : "Home"}
        second={language ? "प्रोफ़ाइल संपादित करें" : "Change Password"}
        head={language ? "प्रोफ़ाइल संपादित करें" : "Change Password"}
      >
        <div className="aod-bottom">
        {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">Old Password</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {errors.oldPassword && <small className="text-danger">{errors.oldPassword}</small>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">New Password</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {errors.newPassword && <small className="text-danger">{errors.newPassword}</small>}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">Confirm Password</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
              </div>
            </div>
          </div>
          <button onClick={handleSubmitLogin} type="submit" className="btn btn-primary user-theme-btn mt-3">
            Submit
          </button>
        </div>
      </Dashboard>
    </div>
  );
};

export default ChangePassword;
