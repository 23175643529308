import React, { useEffect, useState } from "react";
import {
  Apiurl,
  GetLocalStorage,
  Imageurl,
  RemoveLocalStorage,
  SetLocalStorageStringify,
  GetLocalStorageParsed,
} from "../../Utils/Utils";
import homebg from "../../assets/images/home-cat-bg.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeResidentialUnit } from "../Home/HomeIcons";
import {
  BsArrowRight,
  BsChevronDown,
  BsChevronUp,
  BsXLg,
} from "react-icons/bs";
import "./DashboardCategory.css";
import { BackgorundIcon, DashboardIcon } from "../Application/ApplicationIcon";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import { checkApplicationData, getGuideLines } from "../../services/api/user";
import { getSubCategories, getCategories } from "../../services/api/outer";
const DashCategory = () => {
  // token from local storage
  const { language } = useLanguage();
  const navigate = useNavigate();
  const getLoginSubCabData = GetLocalStorage("LoginSubCatData");
  const getNotSelectData = GetLocalStorage("catNotSelect");
  const userDetail = GetLocalStorageParsed("userDetail");
  const location = useLocation();
  const getUserInfo = GetLocalStorage("userInfo");
  const [registerMessage, setRegisterMessage] = useState(false);
  const [notSelectedMessage, setNotSelectedMessage] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    if (location.state === "register") {
      setRegisterMessage(true);
      navigate(location.pathname, { replace: true });
      const timer = setTimeout(() => {
        setRegisterMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);
  useEffect(() => {
    if (!getLoginSubCabData && getNotSelectData) {
      setNotSelectedMessage(true);
    }
  }, []);

  // get Categories List
  const GetCategoyList = () => {
    getCategories()
      .then((res) => {
        if (res) {
          setCategoryData(res);
          setSelectedCategoryId(res?.[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // const option = {
    //     method: "GET",
    //     headers: {
    //         "access-control-allow-origin": "*",
    //         "content-type": "application/json",
    //     },
    //     url: `${Apiurl()}/categories`,
    // };
    // axios(option)
    //     .then((e) => {
    //         setCategoryData(e?.data?.data)
    //         setSelectedCategoryId(e?.data?.data?.[0])
    //     })
    //     .catch((error) => {
    //     });
  };
  useEffect(() => {
    GetCategoyList();
  }, []);

  // get sub category
  const getSubCategory = () => {
    const formData = new FormData();
    formData.append("category_id", selectedCategoryId?.id);
    //console.log("test",selectedCategoryId?.id )
    getSubCategories(formData)
      .then((res) => {
        if (res) {
          setSubCategoryData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // try {
    //     const formData = new FormData();
    //     formData.append("category_id", selectedCategoryId?.id)
    //     const option = {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //             'access-control-allow-origin': '*',
    //         },
    //         url: `${Apiurl()}/get-subcategories-by-category-id `,
    //         data: formData
    //     };
    //     const response = await axios(option)
    //     setSubCategoryData(response?.data?.data)
    // } catch (error) {
    //     console.error(error);
    // }
  };
  useEffect(() => {
    if (selectedCategoryId) {
      getSubCategory();
    }
  }, [selectedCategoryId]);

  const getFormattedDate = (language) => {
    const today = new Date();
    // Define an array for month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNameshindi = [
      "जनवरी",
      "फरवरी",
      "मार्च",
      "अप्रैल",
      "मई",
      "जून",
      "जुलाई",
      "अगस्त",
      "सितम्बर",
      "अक्टूबर",
      "नवम्बर",
      "दिसम्बर",
    ];
    const day = String(today.getDate()).padStart(2, "0"); // Pad with leading zero if needed
    const month = language
      ? monthNameshindi[today.getMonth()]
      : monthNames[today.getMonth()]; // Get the full month name
    const year = today.getFullYear();
    // Format the date in the desired format
    const formattedDate = `${day} ${month}, ${year}`;
    // Return the formatted date with language-specific prefixes
    return language === "hindi"
      ? `तारीख: ${formattedDate}`
      : `Date: ${formattedDate}`;
  };

  // Sub category click function
  const handleSubCatClick = async (item) => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", item?.id);
      formData.append("user_id", userDetail?.id);
      const data = await checkApplicationData(formData);
      SetLocalStorageStringify("LoginSubCatData", item);
      if (data?.status == 200) {
        SetLocalStorageStringify("application_id", data?.data?.id);
        navigate("/application/application-form");
      } else {
        navigate("/guidelines");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {registerMessage && (
        <div className="dc-reg-msg">
          {" "}
          Registered Successfully.{" "}
          <span
            onClick={() => {
              setRegisterMessage(false);
            }}
          >
            <BsXLg />
          </span>
        </div>
      )}
      {notSelectedMessage && (
        <div className="dc-reg-msg">
          {" "}
          Please select category & subcategory first.{" "}
          <span
            onClick={() => {
              setNotSelectedMessage(false);
              RemoveLocalStorage("catNotSelect");
            }}
          >
            <BsXLg />
          </span>
        </div>
      )}
      <div className="gc-outline">
        <div className="ao-top">
          <div className="dashboard-icon">
            <DashboardIcon />
          </div>
          <div className="ao-toptext">
            <p>
              {language
                ? "ट्रैवल ट्रेड डैशबोर्ड में आपका स्वागत है!"
                : "Welcome to Travel Trade Dashboard!"}{" "}
            </p>
            <p>
              {language
                ? "जहां रोमांच शुरू होता है!"
                : " Where Adventures Begin!"}
            </p>
          </div>
          <div className=" wel-date-sec">
            {/* <p>{language ? "तारीख:15 जून 2024" : "Date: 15 June 2024"}</p> */}
            <p>{language ? getFormattedDate("hindi") : getFormattedDate()}</p>
            <p>
              {userDetail && userDetail.unique_id
                ? language
                  ? "अद्वितीय आईडी: " + userDetail.unique_id
                  : "Unique Id: " + userDetail.unique_id
                : ""}
            </p>
          </div>
        </div>
        <div className="registered-user-block d-none">
          <div className="registered-user-details">
            <div class="col-md-12">
              <div class="reciept-table table-responsive mt-2 mb-4">
                <table
                  class="table table mb-0 dataTable transaction_table"
                  id="receipt_table"
                  role="grid"
                >
                  <thead class="">
                    <tr role="row">
                      <th>S.No</th>
                      <th>Application Number</th>
                      <th>Tracking Number</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row">
                      <td>1</td>
                      <td>UTTT14706601</td>
                      <td>UTHS641182</td>
                      <td>Hotel Application</td>
                      <td>Approved/ Certificate Issued</td>
                      <td>
                        <button class="btn btn-green-view">View</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {categoryData?.length > 0 ? (
          <div className="hcat-second hcat-second-category pb-5">
            <img src={homebg} alt="homebg" className="hcat-bg-end" />
            <div className="hcat-left p-3">
              <ul className="hcat-ul">
                {/* category mapping */}
                {Array.isArray(categoryData) &&
                  categoryData?.map((cat) => {
                    return (
                      <>
                        <li onClick={() => setSelectedCategoryId(cat)}>
                          {/* <button className='hcat-green'><HomeResidentialUnit />Residential Unit <span></span></button> */}
                          <button
                            className={
                              cat?.id === selectedCategoryId?.id
                                ? "bg_image_green_home"
                                : "bg_image_white_home"
                            }
                          >
                            {" "}
                            <img
                              src={`${Imageurl()}/${cat?.category_image}`}
                              alt=""
                            />{" "}
                            <span>
                              {language
                                ? cat?.category_name_hi
                                : cat?.category_name}
                            </span>
                          </button>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
            <div className="hcat-right hcat-right-category dash-cat-right py-5">
              {subCategoryData?.length > 0 ? (
                <div className="hcat-col-div dash-cat-col-div">
                  {Array.isArray(subCategoryData) &&
                    subCategoryData?.map((sub) => {
                      return (
                        <div
                          className="dash-cat-main "
                          onClick={() => handleSubCatClick(sub)}
                        >
                          <BsArrowRight className="dash-cat-icon" />
                          <div className="dash-cat-inner">
                            <span className="dash-cat-name">
                              {" "}
                              {language
                                ? sub?.category_name_hi
                                : sub?.category_name}
                            </span>
                            <span className="dash-cat-no">0</span>
                          </div>
                          <div className="das-cat-sec-div">
                            <img
                              src={`${Imageurl()}/${sub?.category_image}`}
                              alt=""
                              className="subCat-img-das"
                            />
                            <div className="guide-main-div">
                              {/* <BackgorundIcon /> */}
                              <div className="dc-guide-par">
                                <p>
                                  {language
                                    ? "दिशानिर्देश पढ़ें"
                                    : "Read Guidelines"}{" "}
                                  <span></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="dash-cat-col-div text-center">
                  <div>
                    {language
                      ? "कोई उपश्रेणियाँ नहीं मिलीं"
                      : "No Sub Categories found"}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="hcat-second-res mob-hcat-second-category pb-5">
          {categoryData.map((cat, index) => {
            const active = index === activeIndex ? "active" : "";
            return (
              <div key={cat.title} className="hcat-res-bg">
                <div
                  className={`hcat-title ${active}`}
                  onClick={() => {
                    onTitleClick(index);
                    setSelectedCategoryId(cat);
                  }}
                >
                  <span>
                    <img src={`${Imageurl()}/${cat?.category_image}`} alt="" />{" "}
                    <span>
                      {language ? cat.category_name_hi : cat.category_name}
                    </span>
                  </span>
                  {active ? <BsChevronUp /> : <BsChevronDown />}
                </div>
                <div className={`hcat-content ${active}`}>
                  <div className="hcat-col-div">
                    {subCategoryData?.map((sub) => {
                      return (
                        <div
                          className="hcat-col "
                          onClick={() => handleSubCatClick(sub)}
                        >
                          <div className="hcat-col-text">
                            {" "}
                            {language
                              ? sub?.category_name_hi
                              : sub?.category_name}
                            <span className="hcat-inner-span">
                              <img
                                src={`${Imageurl()}/${sub?.category_image}`}
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default DashCategory;
