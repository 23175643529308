import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { CgPen } from "react-icons/cg";

const Preview = () => {
  return (
    <Dashboard>
      <div className="">
        <div className="preview-page-main-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="data-wrapper">
                <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                  <div className="data-wrapper-heading">
                    <h5 className="mb-0">Organization Specifications</h5>
                  </div>
                  <div className="data-wrapper-edit-icon">
                    <a href="#" className="edit-icon">
                      <CgPen />
                    </a>
                  </div>
                </div>
                <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                  <div className="preview-left-heading">
                    <h6 className="fw-bold mb-0">Apply for District</h6>
                  </div>
                  <div className="preview-right-heading">
                    <p className="mb-0">Pauri</p>
                  </div>
                </div>
                <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                  <div className="preview-left-heading">
                    <h6 className="fw-bold mb-0"> Tehsil</h6>
                  </div>
                  <div className="preview-right-heading">
                    <p className="mb-0">Dhari Gram</p>
                  </div>
                </div>
                <div className="preview-data py-2  px-3 d-flex justify-content-between align-items-center">
                  <div className="preview-left-heading">
                    <h6 className="fw-bold mb-0">State</h6>
                  </div>
                  <div className="preview-right-heading">
                    <p className="mb-0">pauri</p>
                  </div>
                </div>
                <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                  <div className="preview-left-heading">
                    <h6 className="fw-bold mb-0">Plot No./ Street No.</h6>
                  </div>
                  <div className="preview-right-heading">
                    <p className="mb-0">H.no.368</p>
                  </div>
                </div>
                <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                  <div className="preview-left-heading">
                    <h6 className="fw-bold mb-0">Name of Accommodation Unit</h6>
                  </div>
                  <div className="preview-right-heading">
                    <p className="mb-0">Pauri Niwas</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="card mb-3">
                <div class="card-body bg-card user-tab-table ">
                  <div class="form-bar d-flex justify-content-between align-items-center p-3">
                    <div class="bar-left-detail">
                      <h5 class="p-0 text-dark mb-0">Details of Partners</h5>
                    </div>
                    <div className="data-wrapper-edit-icon">
                      <a href="#" className="edit-icon text-dark">
                        <CgPen />
                      </a>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table  bg-table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No </th>
                          <th scope="col"> Owner/Partner Name </th>
                          <th scope="col"> Email</th>
                          <th scope="col">State </th>
                          <th scope="col">District</th>
                          <th scope="col">City/Village</th>
                          <th scope="col">House No</th>
                          <th scope="col">Landmark</th>
                          <th scope="col">Upload Photo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Mr. Rakesh Singh</td>
                          <td>rakesh134@gmail.com</td>
                          <td>Pauri</td>
                          <td>Dharigram</td>
                          <td>pauri</td>
                          <td>H.no 368</td>
                          <td>Near school </td>
                          <td>
                            <div id="img_preview" class="">
                              <img
                                class="ms-1 uplaoed_img_preview_partner"
                                src="blob:http://localhost:3000/481aa093-a03e-4b1a-9c45-32892452856f"
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Mr. Tajinder Singh</td>
                          <td>tajinder456@gmail.com</td>
                          <td>Pauri</td>
                          <td>Dharigram</td>
                          <td>pauri</td>
                          <td>H.no 890</td>
                          <td>Near school </td>
                          <td>
                            <div id="img_preview" class="">
                              <img
                                class="ms-1 uplaoed_img_preview_partner"
                                src="blob:http://localhost:3000/481aa093-a03e-4b1a-9c45-32892452856f"
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Mr. Rakesh Singh</td>
                          <td>rakesh134@gmail.com</td>
                          <td>Pauri</td>
                          <td>Dharigram</td>
                          <td>pauri</td>
                          <td>H.no 368</td>
                          <td>Near school </td>
                          <td>
                            <div id="img_preview" class="">
                              <img
                                class="ms-1 uplaoed_img_preview_partner"
                                src="blob:http://localhost:3000/481aa093-a03e-4b1a-9c45-32892452856f"
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="data-wrapper business-detail">
                <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                  <div className="data-wrapper-heading">
                    <h5 className="mb-0">Business Details</h5>
                  </div>
                  <div className="data-wrapper-edit-icon">
                    <a href="#" className="edit-icon">
                      <CgPen />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Year of Construction</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">2004</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Date of Starting Business (For old Units)
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">12-10-2006</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Title of Accommodation Unit 
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Dhari Gram</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Title of Accommodation Unit 
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Dhari Gram</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <div className="data-wrapper facilities-warpper">
                <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                  <div className="data-wrapper-heading">
                    <h5 className="mb-0">
                      Distance from Nearest (In KM) & Facilities Available{" "}
                    </h5>
                  </div>
                  <div className="data-wrapper-edit-icon">
                    <a href="#" className="edit-icon">
                      <CgPen />
                    </a>
                  </div>
                </div>
                <div className="row pt-1">
                  <div className="col-md-4">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">City</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Pauri</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Bus Stand</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">4km</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Railway Station</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">10km</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Airport</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">2km</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Shopping Mall</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">9km</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Distance from Tourist Point
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">3km</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Hospital/ Dispensary</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">2km</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-12">
                    <h6 className="m-3 py-2 fw-bold facilities-heading">
                      Facilities Available in Accommodation Unit
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Car Parking</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Restaurant</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Bar</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">No</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Swimming Pool</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">No</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Health SPA</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">Conference Hall</h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mt-4">
              <div class="card mb-3">
                <div class="card-body bg-card user-tab-table ">
                  <div class="form-bar d-flex justify-content-between align-items-center p-3">
                    <div class="bar-left-detail">
                      <h5 class="p-0 text-dark mb-0">Room Details</h5>
                    </div>
                    <div className="data-wrapper-edit-icon">
                      <a href="#" className="edit-icon text-dark">
                        <CgPen />
                      </a>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table  bg-table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Sr. No </th>
                          <th scope="col"> Total Area </th>
                          <th scope="col"> Floor Type</th>
                          <th scope="col">Bed Type </th>
                          <th scope="col">Total No. of Beds</th>
                          <th scope="col">Type of Room</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>856 Sq ft.</td>
                          <td>Wooden</td>
                          <td>Single</td>
                          <td>3</td>
                          <td>Double</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>856 Sq ft.</td>
                          <td>Wooden</td>
                          <td>Single</td>
                          <td>3</td>
                          <td>Double</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="card mb-3">
                <div class="card-body bg-card user-tab-table ">
                  <div class="form-bar d-flex justify-content-between align-items-center p-3">
                    <div class="bar-left-detail">
                      <h5 class="p-0 text-dark mb-0">
                        Details of Staff (Skilled/Unskilled)
                      </h5>
                    </div>
                    <div className="data-wrapper-edit-icon">
                      <a href="#" className="edit-icon text-dark">
                        <CgPen />
                      </a>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table  bg-table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Designation</th>
                          <th scope="col"> No. of People </th>
                          <th scope="col"> Average Experience</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>General Manager</td>
                          <td>1</td>
                          <td>10 years</td>
                        </tr>
                        <tr>
                          <td>Management</td>
                          <td>2</td>
                          <td>5 years</td>
                        </tr>
                        <tr>
                          <td>Supporting Staff</td>
                          <td>3</td>
                          <td>2 years</td>
                        </tr>
                        <tr>
                          <td>Ground Staff</td>
                          <td>2</td>
                          <td>5 years</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="data-wrapper">
                <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                  <div className="data-wrapper-heading">
                    <h5 className="mb-0">Additional Details</h5>
                  </div>
                  <div className="data-wrapper-edit-icon">
                    <a href="#" className="edit-icon">
                      <CgPen />
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Safety/Security related Equipments Installed( eg.
                          CCTV, Scanner, Smoke detector etc. )
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Whether building Map has been approved by competent
                          Authority
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">No</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Local Approvals by Municipal Authority obtained
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          NOC Obtained from Fire Department
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">No</p>
                      </div>
                    </div>
                    <div className="preview-data py-2 px-3 d-flex justify-content-between align-items-center">
                      <div className="preview-left-heading">
                        <h6 className="fw-bold mb-0">
                          Any other Information you want to share
                        </h6>
                      </div>
                      <div className="preview-right-heading">
                        <p className="mb-0">Yes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-4">
            <div class="card mb-3">
              <div class="card-body bg-card user-tab-table ">
                <div class="form-bar d-flex justify-content-between align-items-center p-3">
                  <div class="bar-left-detail">
                    <h5 class="p-0 text-dark mb-0">Employee’s Details</h5>
                  </div>
                  <div className="data-wrapper-edit-icon">
                    <a href="#" className="edit-icon text-dark">
                      <CgPen />
                    </a>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table  bg-table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Position Name</th>
                        <th scope="col"> Name </th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Email</th>
                        <th scope="col">State</th>
                        <th scope="col">District</th>
                        <th scope="col">City/Village</th>
                        <th scope="col">House No. /Street No.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>General Manager</td>
                        <td>Utkarash</td>
                        <td>7666666666</td>
                        <td>Utkarash5749@gmail.com</td>
                        <td>Uttarkhand</td>
                        <td>Deharadun</td>
                        <td>Pauri</td>
                        <td>H.no 7689</td>
                      </tr>
                      <tr>
                        <td>Management</td>
                        <td>Anmol Kumar</td>
                        <td>85677777767</td>
                        <td>anmol567@gmail.com</td>
                        <td>Uttarkhand</td>
                        <td>Deharadun</td>
                        <td>Pauri</td>
                        <td>H.no 7689</td>
                      </tr>
                      <tr>
                        <td>Supporting Staff</td>
                        <td>Tajinder Singh</td>
                        <td>85698777767</td>
                        <td>tajinder99@gmail.com</td>
                        <td>Uttarkhand</td>
                        <td>Deharadun</td>
                        <td>Pauri</td>
                        <td>H.no 7689</td>
                      </tr>
                      <tr>
                        <td>Ground Staff </td>
                        <td>Gopal</td>
                        <td>85677777767</td>
                        <td>gopal@gmail.com</td>
                        <td>Uttarkhand</td>
                        <td>Deharadun</td>
                        <td>Pauri</td>
                        <td>H.no 7689</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 mt-4">
            <div className="data-wrapper">
              <div className="data-wrapper-heading-block d-flex justify-content-between align-items-center">
                <div className="data-wrapper-heading">
                  <h5 className="mb-0">Upload Documents</h5>
                </div>
                <div className="data-wrapper-edit-icon">
                  <a href="#" className="edit-icon">
                    <CgPen />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          1. Companys Partnership Registration Certificate
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          1. Companys Partnership Registration Certificate
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          1. Companys Partnership Registration Certificate
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          1. Companys Partnership Registration Certificate
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          2. Character Certificate from District Administration
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">3. Land Ownership Proof</span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          1. Companys Partnership Registration Certificate
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                  <div className="upload-document documents_block">
                    <div class="documents_detail w-100">
                      <h5 class="mb-0">
                        <span class="en-lable">
                          1. Companys Partnership Registration Certificate
                        </span>
                      </h5>
                      <div class="upload-document-text">name of Upload</div>
                    </div>
                    <div className="preview-doc-btn">
                      <button className="btn">View</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Preview;
