import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useLocation, useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { getSubCategoriesDropdown, submitEmpDesignationListData, updateEmpDesignationListData } from '../../../services/api/admin';
import { Message } from '../../../components/message';
import { checkPattern, customStylesAcc, selectTheme } from '../../../Utils/common';
import Select from 'react-select';

const AddEmployeeDesignation = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [formValue, setFormValue] = useState({
        name_en: '',
        name_hi: ''
    })
    const [errors, setErrors] = useState({
        name_en: '',
        name_hi: '',
        catId: ''
    })
    const [catId, setCatId] = useState('')
    const [catList, setCatList] = useState()
    const [designationId, setDesignationId] = useState()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });


    useEffect(() => {
        if (location?.state && location?.pathname == "/admin/employee-designations/update") {
            editDesignation(location?.state)
        }
    }, [location?.state])

    useEffect(() => {
        getCategoryList();
    }, []);

    const getCategoryList = async () => {
        try {
            const categoryList = await getSubCategoriesDropdown()
            const data = categoryList?.data?.filter(i => i?.status === "1")?.map(item => ({
                value: item?.id,
                label: item?.category_name
            }))
            setCatList(data)
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }

    const editDesignation = (item) => {
        setDesignationId(item.id)
        setCatId(item.sub_category_id)
        setFormValue({
            name_en: item.name_en,
            name_hi: item.name_hi
        });
        window.scrollTo(0, 0);
    }


    const addDesignation = async (e) => {
        try {
            e.preventDefault();

            let validationErrors = { ...errors };
            await Promise.all(Object.keys(formValue).map(async (key) => {
                const value = formValue[key];
                const errors = await validateOnChangeHandle(key, value);
                if (!catId) {
                    errors.catId = " Please Select Category"
                }
                validationErrors = { ...validationErrors, ...errors };

            }));
            setErrors(validationErrors);
            if (Object.values(validationErrors).some(error => error !== '')) {
                return;
            }


            const DesignationData = new FormData();
            DesignationData.append("name_en", formValue.name_en)
            DesignationData.append("name_hi", formValue.name_hi)
            DesignationData.append("sub_category_id", catId)
            DesignationData.append("category_id", catId)

            const data = await submitEmpDesignationListData(DesignationData)
            if (data?.status == 200) {
                navigate("/admin/employee-designations", { state: { message: data.message } })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    const updateDesignation = async (e) => {
        try {
            e.preventDefault();

            let validationErrors = { ...errors };
            await Promise.all(Object.keys(formValue).map(async (key) => {
                const value = formValue[key];
                const errors = await validateOnChangeHandle(key, value);
                if (!catId) {
                    errors.catId = " Please Select Category"
                }
                validationErrors = { ...validationErrors, ...errors };

            }));
            setErrors(validationErrors);
            if (Object.values(validationErrors).some(error => error !== '')) {
                return;
            }


            const DesignationData = new FormData();
            DesignationData.append("name_en", formValue.name_en)
            DesignationData.append("name_hi", formValue.name_hi)
            DesignationData.append("sub_category_id", catId)
            DesignationData.append("designation_id", designationId)

            const data = await updateEmpDesignationListData(DesignationData)
            if (data?.status == 200) {
                navigate("/admin/employee-designations", { state: { message: data.message } })
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err.message })
        }
    }

    const handleInputChanges = async (e) => {
        const { name, value } = e?.target
        const cursorPosition = e.target.selectionStart;
        if (name == 'name_en') {
            if (value.trim()) {
                if (await checkPattern(value) == false) {
                    return;
                }
            }
        } else if (name == "name_hi") {
            if (await checkPattern(value) == false) {
                return;
            }
        }

        setFormValue(prevState => ({
            ...prevState,
            [name]: value
        }));

        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
        validateOnChangeHandle(name, value);
    }

    const validateOnChangeHandle = async (name, value) => {
        let formErrors = errors;
        switch (name) {
            case 'name_en':
                if (!value?.trim()) {
                    formErrors.name_en = 'Please Enter English Name';
                } else {
                    formErrors.name_en = ''
                }
                break;
            case 'name_hi':
                if (!value?.trim()) {
                    formErrors.name_hi = 'Please Enter Hindi Name ';
                } else {
                    formErrors.name_hi = ''
                }
                break;
        }
        setErrors(formErrors);
        return formErrors;
    }

    const validateOnBlurHandle = (e) => {
        const { name, value } = e.target;

        let formErrors = { ...errors };

        if (name === 'name_en') {
            if (!value.trim()) {
                formErrors.name_en = 'Please Enter English Name';
                formErrors.name_en = '';
            }
        } else if (name === 'name_hi') {
            if (!value.trim()) {
                formErrors.name_hi = 'Please Enter Hindi Name ';
            } else {
                formErrors.name_hi = '';
            }
            setErrors(formErrors);
        };
    }

    const validateCategory = () => {
        let formErrors = { ...errors };
        if (!catId) {
            formErrors.catId = 'Please select Sub Category';
        } else {
            formErrors.catId = '';
        }
        setErrors(formErrors);
    };


    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span> Employee Designation Form</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className={designationId ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"> <button onClick={() => navigate("/admin/employee-designations")}><BsChevronLeft /> Back</button>{designationId ? "Update" : "Add"} Employee Designation</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label htmlFor="roles" className='mb-1 w-100'><span className="en-lable">Select Sub-Category</span>
                                    </label>

                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        className="my-select selectpicker"
                                        classNamePrefix="ad-menu_open_select_class"
                                        aria-label="Default select example"
                                        name="role"
                                        value={catList?.find(option => option.value === catId) || null}
                                        options={catList}
                                        onBlur={validateCategory}
                                        onChange={(selectedOption) => {
                                            setCatId(selectedOption.value)
                                            errors.catId = ''
                                        }}// Use onChange for real-time validation
                                        placeholder="Select Sub-Category"
                                    />
                                    {errors.catId && <p className='validate-error'>{errors.catId}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name
                                    </label>
                                    <input maxLength={40} className='w-100' name="name_en" placeholder='Enter English Name' type="text" value={formValue.name_en} onChange={(e) => handleInputChanges(e)} onBlur={(e) => validateOnBlurHandle(e)} />

                                    {errors.name_en && <p className='validate-error'>{errors.name_en}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name
                                    </label>
                                    <input maxLength={40} className='w-100' name="name_hi" placeholder='Enter Hindi Name' type="text" value={formValue.name_hi} onChange={(e) => handleInputChanges(e)} onBlur={(e) => validateOnBlurHandle(e)} />

                                    {errors.name_hi && <p className='validate-error'>{errors.name_hi}</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className=" update-btns-block">

                                    {
                                        designationId ?
                                            <>
                                                <button onClick={(e) => updateDesignation(e)} className='suf-can-butt mx-3 mt-3 w-auto px-4'>
                                                    Update
                                                </button>

                                            </> :

                                            <button onClick={(e) => addDesignation(e)}>
                                                Submit
                                            </button>

                                    }

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default AddEmployeeDesignation
