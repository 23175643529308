import React from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { Message } from "../../../components/message";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import dataicon from "../../../../src/assets/images/data-icon.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon, EditIcon } from "../AdminIcon";

const RenewalApplications = () => {
  const navigate = useNavigate();
  return (
    <AdminDashboard>
      <div className="aod-outer">
        {/* {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)} */}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span>Renewal Application</span>
          </div>
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Renewal Application</h4>
            </div>
            <div className="aod-head"></div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table mt-4">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th className="ad-long">District </th>
                    <th className="ad-long">Tracking Number</th>
                    <th className="ad-long">Created At</th>
                    <th className="ad-long">Status</th>
                    <th className="ad-long">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Pauri</td>
                    <td>UTTT14706601</td>
                    <td>12-10-2024</td>
                    <td>Pending</td>
                    {/* <td><div className="action-btn d-flex">
                                                    <span className='ad-cat-edit' onClick={() => navigate("/admin/category/documents/update", { state: item })}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
                                                </div>
                                                </td> */}
                    <td>
                      <div className="action-btn d-flex">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <span className="ad-cat-edit">
                            <EditIcon />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <span className="ad-cat-delete">
                            <DeleteIcon />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Pauri</td>
                    <td>UTTT14706601</td>
                    <td>12-10-2024</td>
                    <td>Pending</td>
                    {/* <td><div className="action-btn d-flex">
                                                    <span className='ad-cat-edit' onClick={() => navigate("/admin/category/documents/update", { state: item })}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
                                                </div>
                                                </td> */}
                    <td>
                      <div className="action-btn d-flex">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <span className="ad-cat-edit">
                            <EditIcon />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <span className="ad-cat-delete">
                            <DeleteIcon />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Pauri</td>
                    <td>UTTT14706601</td>
                    <td>12-10-2024</td>
                    <td>Pending</td>
                    {/* <td><div className="action-btn d-flex">
                                                    <span className='ad-cat-edit' onClick={() => navigate("/admin/category/documents/update", { state: item })}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
                                                </div>
                                                </td> */}
                    <td>
                      <div className="action-btn d-flex">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Edit</Tooltip>}
                        >
                          <span className="ad-cat-edit">
                            <EditIcon />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Delete</Tooltip>}
                        >
                          <span className="ad-cat-delete">
                            <DeleteIcon />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </AdminDashboard>
  );
};

export default RenewalApplications;
