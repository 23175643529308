import React, { useEffect, useState } from "react";
import "../Admin.css";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Modal, Table } from "react-bootstrap";
import { RemoveSession } from "../../../Utils/Utils";
import Select from "react-select";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";
import {
  checkPattern,
  customStylesAcc,
  selectTheme,
} from "../../../Utils/common";
import {
  getStateDropdown,
  getDistrictDropdown,
  addCityData,
  getCityListDataSort,
  activeInactiveCity,
  deleteCityData,
  updateCityData,
} from "../../../services/api/admin";
import { BsChevronLeft } from "react-icons/bs";
import "../AdminGuidelines/AdminGuildlines.css";
import { HiPlus } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { Message } from "../../../components/message";
import { formatDateWithSlash } from "../../../Utils/common";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const Cities = () => {
  const navigate = useNavigate();
  const [nameEn, setNameEn] = useState("");
  const [nameHi, setNameHi] = useState("");
  const [nameEnError, setNameEnError] = useState();
  const [nameHiError, setNameHiError] = useState();
  const [searchData, setSearchData] = useState("");
  const [sortByCity, setSortByCity] = useState("created_at");
  const [stateList, setStateList] = useState([]);
  const [editSateList, setEditStateList] = useState([]);
  const [editStateId, setEditStateId] = useState();
  const [editSateIdError, setEditStateIdError] = useState();
  const [searchState, setSearchState] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [fullDistrictListData, setfullDistrictListData] = useState();
  const [
    fullDistrictListDropdownData,
    setfullDistrictListDropdownData,
  ] = useState();
  const [editDistrictId, setEditDistrictId] = useState();
  const [editDistrictIdError, setEditDistrictIdError] = useState();
  const [searchDistrict, setSearchDistrict] = useState();
  const [searchDistrictList, setSearchDistrictList] = useState();
  const [editDistrictList, setEditDistrictList] = useState();
  const [editData, setEditData] = useState();
  const [cityList, setCityList] = useState();
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [editContainer, setEditContainer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderByCity, setOrderByCity] = useState("desc");
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });

  const grantPermissionForAddCities = checkPermission(
    permissionsConfig.addCities.role,
    permissionsConfig.addCities.action,
    permissionsConfig.addCities.type
  );
  const grantPermissionForEditCities = checkPermission(
    permissionsConfig.editCities.role,
    permissionsConfig.editCities.action,
    permissionsConfig.editCities.type
  );

  const handleKeyUpLoginDetails = (item) => {
    validateForm(item);
  };
  //validations
  const validateForm = (item) => {
    let isValid = true;
    if (item === "stateId" || item === "both") {
      if (!editStateId) {
        setEditStateIdError("Please Select State");
        isValid = false;
      } else {
        setEditStateIdError("");
      }
    }
    if (item === "districtId" || item === "both") {
      if (!editDistrictId) {
        setEditDistrictIdError("Please Select District");
        isValid = false;
      } else {
        setEditDistrictIdError("");
      }
    }
    if (item === "nameEn" || item === "both") {
      if (!nameEn || nameEn.trim() === "") {
        setNameEnError("Please Enter English Name");
        isValid = false;
      } else {
        setNameEnError("");
      }
    }
    if (item === "nameHi" || item === "both") {
      if (!nameHi || nameHi.trim() === "") {
        setNameHiError("Please Enter Hindi Name");
        isValid = false;
      } else {
        setNameHiError("");
      }
    }



    return isValid;
  };
  // add city
  const addCity = async () => {
    if (!validateForm("both")) return;
    try {
      const formData = new FormData();
      formData.append("state_id", editStateId.value);
      formData.append("district_id", editDistrictId?.value);
      formData.append("name_en", nameEn);
      formData.append("name_hi", nameHi);
      const data = await addCityData(formData);
      if (data?.status == 200) {
        setEditContainer(false);
        setNameEn("");
        setNameHi("");
        setEditStateId("");
        setEditDistrictId("");
        // setFontFamily("");
        // setFontColor("")
        // setFontSize("")
        // setBackgroundColor("")
        getCityData("", "", "created_at", 1, "desc", "");
        setApiMessage({ type: "success", message: data?.message });
      } else if (data?.status == 403) {
        setApiMessage({ type: "error", message: data?.message });
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };
  // state list
  const getStateList = async () => {
    try {
      const stateDropdownData = await getStateDropdown();
      const data = stateDropdownData?.data?.map((i) => ({
        label: i?.name_en,
        value: i?.id,
      }));
      const editData = stateDropdownData?.data
        ?.filter((s) => s.status !== "0")
        ?.map((i) => ({
          label: i?.name_en,
          value: i?.id,
        }));
      setStateList(data);
      setEditStateList(editData);
    } catch (error) {
      console.log("fetch state list", error);
      if (error?.code === "ERR_NETWORK") {
        RemoveSession(navigate);
      }
    }
  };
  // district list
  const getDistrictList = async () => {
    try {
      const districtDropdownData = await getDistrictDropdown();
      const data = districtDropdownData?.data?.map((i) => ({
        label: i?.name_en,
        value: i?.id,
      }));
      setfullDistrictListData(districtDropdownData?.data);
      setfullDistrictListDropdownData(data);
    } catch (error) {
      console.log("fetch district list", error);
      if (error?.code === "ERR_NETWORK") {
        RemoveSession(navigate);
      }
    }
  };

  const resetFilter = () => {
    setSearchState("");
    setSearchDistrict("");
    getCityData("", "", sortByCity, 1, orderByCity, searchData);
  };

  const handlePageChange = (selectedPage) => {
    getCityData(
      searchState,
      searchDistrict,
      sortByCity,
      selectedPage.selected + 1,
      orderByCity,
      searchData
    );
  };

  // City list
  const getCityData = async (
    searchedState,
    searchedDistrict,
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy);
      setOrderByCity(orderBy);
      setSortByCity(sortBy);
      const cityList = await getCityListDataSort(
        sortBy,
        orderBy,
        searchBy ?? "",
        pageNo,
        searchedState?.value ? searchedState?.value : "",
        searchedDistrict?.value ? searchedDistrict?.value : ""
      );
      setTotalPages(
        Math.ceil(cityList?.data?.total / cityList?.data?.per_page)
      );
      setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
      const data = cityList?.data?.data;
      setCityList(data);
      setTableDataLoaded(true);
    } catch (err) {
      console.log("error on cityList: ", err);
    }
  };


  useEffect(() => {
    //getFontFamily();
    getCityData("", "", "created_at", 1, "desc", "");
    getStateList();
    getDistrictList();
  }, []);

  // delete city
  const deleteCity = async (item) => {
    try {
      const formData = new FormData();
      formData.append("city_id", item?.id);
      const data = await deleteCityData(formData);
      if (data.status == 200) {
        setEditContainer(false);
        setDeleteData("");
        getCityData(
          searchState,
          searchDistrict,
          sortByCity,
          currentPage,
          orderByCity,
          searchData
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  //edit city
  const editCity = (item) => {
    setEditData(item);
    setEditStateId(stateList?.find((i) => i?.value === item?.state_id));
    setEditDistrictId(
      fullDistrictListDropdownData?.find((i) => i?.value === item?.district_id)
    );
    setNameEn(item?.name_en);
    setNameHi(item?.name_hi);

    window.scrollTo(0, 0);
  };
  //update city
  const updateCity = async () => {
    if (!validateForm("both")) return;
    try {
      const formData = new FormData();
      formData.append("state_id", editData?.state_id);
      formData.append("district_id", editData?.district_id);
      formData.append("city_id", editData?.id);
      formData.append("name_en", nameEn);
      formData.append("name_hi", nameHi);

      const data = await updateCityData(formData);
      if (data.status == 200) {
        setEditContainer(false);
        setNameEn("");
        setNameHi("");
        setEditStateId("");
        setEditDistrictId("");
        // setFontFamily("");
        // setFontColor("")
        // setFontSize("")
        // setBackgroundColor("")
        setEditData("");
        getCityData("", "", "created_at", 1, "desc", "");
        setApiMessage({ type: "success", message: data?.message });
      } else if (data.status == 403) {
        setApiMessage({ type: "error", message: data.message });
      } else {
        setApiMessage({ type: "error", message: data.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };
  const handleEmptyData = () => {
    setEditContainer(false);
    setNameEn("");
    setNameHi("");
    setEditStateId("");
    setEditDistrictId("");

    setNameEnError("");
    setNameHiError("");

    setEditStateIdError("");
    setEditDistrictIdError("");
    // setFontFamilyError("");
    // setFontColorError("")
    // setFontSizeError("")
    // setBackgroundColorError("")
    setEditData();
  };
  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("city_id", item?.id);
      formData.append("status", e?.value);
      const data = await activeInactiveCity(formData);
      if (data?.status == 200) {
        getCityData(
          searchState,
          searchDistrict,
          sortByCity,
          currentPage,
          orderByCity,
          searchData
        );
      } else {
        setApiMessage({ type: "error", message: data.message });
      }
    } catch (error) {
      console.error(error);
      setApiMessage({ type: "error", message: error?.message });
    }
  };

  const handleEditBack = async () => {
    setEditContainer(false);
    setSearchData("");
    setCurrentPage(1);
    setSearchState("");
    setSearchDistrict("");
    setSearchDistrictList();
    setSortByCity("created_at");
    setOrderByCity("desc");
    setSearchTerm("")

    setNameEnError("");
    setNameHiError("");
    setEditStateIdError("");
    setEditDistrictIdError("");
    getCityData("", "", "created_at", 1, "desc", "");
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    const cursorPosition = e.target.selectionStart;

    // Check for "nameEn" field
    if (name === "nameEn") {
      if (await checkPattern(value)) {
        setNameEn(value);
        setNameEnError("");
      } else {
        e.preventDefault();
        return;
      }
    }

    // Check for "nameHi" field
    if (name === "nameHi") {
      if (await checkPattern(value)) {
        setNameHiError();
        setNameHi(value);
      } else {
        e.preventDefault();
        return;
      }
    }

    setTimeout(() => {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        {editContainer ? (
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span> City Form</span>
              </div>
            </div>
            <div className="aod-inner">
              <div
                className={
                  editData?.id ? "aod-update aod-bottom" : "aod-bottom"
                }
              >
                <div className="aod-head text-center adm-head">
                  {" "}
                  <button onClick={() => handleEditBack()}>
                    <BsChevronLeft /> Back
                  </button>
                  {editData?.id ? "Update" : "Add"} City
                </div>
                <div className="row py-4">
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="lf-label w-100">Select State</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        isDisabled={editData?.id}
                        value={editStateId}
                        options={editSateList}
                        onChange={(e) => {
                          const data = fullDistrictListData
                            ?.filter(
                              (i) =>
                                i?.state_id === e.value && i?.status !== "0"
                            )
                            ?.map((i) => ({
                              label: i?.name_en,
                              value: i?.id,
                            }));
                          setEditDistrictList(data);
                          setEditStateId(e);
                          setEditStateIdError("");
                        }}
                        placeholder="Select State"
                        onMenuOpen={() => handleKeyUpLoginDetails("stateId")}
                        onMenuClose={() => handleKeyUpLoginDetails("stateId")}
                      />
                      {editSateIdError && (
                        <p className="validate-error">{editSateIdError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="lf-label w-100">Select District</label>
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        isDisabled={editData?.id}
                        value={editDistrictId}
                        options={editDistrictList}
                        onChange={(e) => {
                          setEditDistrictId(e);
                          setEditDistrictIdError("");
                        }}
                        placeholder="Select District"
                        onMenuOpen={() => handleKeyUpLoginDetails("districtId")}
                        onMenuClose={() =>
                          handleKeyUpLoginDetails("districtId")
                        }
                      />
                      {editDistrictIdError && (
                        <p className="validate-error">{editDistrictIdError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">English Name</label>
                      <input
                        className="w-100"
                        placeholder="Enter English Name"
                        type="text"
                        name="nameEn"
                        value={nameEn}
                        maxLength={80}
                        onChange={handleInputChange}
                      />

                      {nameEnError && (
                        <p className="validate-error">{nameEnError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <label className="mb-1 w-100">Hindi Name</label>
                      <input
                        className="w-100"
                        placeholder="Enter Hindi Name"
                        type="text"
                        name="nameHi"
                        value={nameHi}
                        maxLength={80}
                        onChange={handleInputChange}
                      />

                      {nameHiError && (
                        <p className="validate-error">{nameHiError}</p>
                      )}
                    </div>
                  </div>

                </div>
                <div className="row ad-cat-row">
                  <div className=" update-btns-block">
                    {editData?.id ? (
                      <>
                        <button onClick={() => updateCity()}>Update</button>{" "}
                      </>
                    ) : (
                      <button onClick={addCity}>Submit</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
              <div>
                <HomeIcon />
                <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                <span> Cities</span>
              </div>
              {grantPermissionForAddCities ? (
                <button
                  className="adm-tab-btn"
                  onClick={() => {
                    handleEmptyData();
                    setEditContainer(true);
                  }}
                >
                  <span>
                    <HiPlus />
                  </span>{" "}
                  Add City
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="aod-inner pt-0">
              <div className="aod-bottom">
                <div className="adm-tabs-outer d-flex justify-content-between d-md-flex justify-content-between">
                  <h4 className="mb-0 w-100">Cities</h4>
                  <div className="suf-input-box aod-form-input mb-2 ms-3 w-50">
                    <div className="position-relative w-100">
                      <input
                        className="px-2 form-control"
                        placeholder="Search"
                        type="text"
                        value={searchTerm} // Add a state for the input value
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                          const trimmedValue = e.target.value.trim(); // Trim the input value
                          getCityData(searchState, searchDistrict, sortByCity, 1, orderByCity, trimmedValue)
                        }}
                        onPaste={(e) => {
                          e.preventDefault();  // To prevent the default behaviour
                          const pastedData = e.clipboardData.getData('Text').trim();
                          document.execCommand('insertText', false, pastedData); // To paste the trimmed value in input box 
                        }}
                      />
                      {searchTerm && ( // Show the clear button only when there's text
                        <button
                          className="btn btn-clear position-absolute"
                          onClick={(e) => {
                            setSearchTerm(""); // Clear the input value
                            getCityData(searchState, searchDistrict, sortByCity, 1, orderByCity, '') // Fetch data with empty search term
                          }}
                          style={{
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            border: "none",
                            background: "transparent",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                        >
                          &times; {/* This is the "X" icon */}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="aod-head"></div>
                <div className="row py-4">
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select w-100"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={searchState}
                        options={stateList}
                        onChange={(e) => {
                          setSearchState(e);
                          const data = fullDistrictListData
                            ?.filter((i) => i?.state_id === e.value)
                            ?.map((i) => ({
                              label: i?.name_en,
                              value: i?.id,
                            }));
                          setSearchDistrictList(data);
                          setSearchDistrict("");
                          getCityData(
                            e,
                            "",
                            sortByCity,
                            1,
                            orderByCity,
                            searchData
                          );
                        }}
                        placeholder="Select State"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 aod-resp-tab">
                    <div className="suf-input-box aod-form-input mb-2">
                      <Select
                        styles={customStylesAcc}
                        theme={selectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="menu_open_select w-100"
                        classNamePrefix="ad-menu_open_select_class"
                        aria-label="Default select example"
                        value={searchDistrict}
                        options={searchDistrictList}
                        onChange={(e) => {
                          setSearchDistrict(e);
                          getCityData(
                            searchState,
                            e,
                            sortByCity,
                            1,
                            orderByCity,
                            searchData
                          );
                        }}
                        placeholder="Select District"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={resetFilter}
                      className="ad-reset-btn w-auto px-4"
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <div className="ad-reg-table">
                  <Table size="sm" className="ad-cat-table">
                    <thead>
                      <tr>
                        <th className="ad-sno">S. No</th>

                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getCityData(
                              searchState,
                              searchDistrict,
                              "name_en",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          English Name{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getCityData(
                              searchState,
                              searchDistrict,
                              "name_hi",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Hindi Name{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getCityData(
                              searchState,
                              searchDistrict,
                              "state_id",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          State{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getCityData(
                              searchState,
                              searchDistrict,
                              "district_id",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          District{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        <th
                          className="ad-long cursor-pointer"
                          onClick={(e) =>
                            getCityData(
                              searchState,
                              searchDistrict,
                              "created_at",
                              currentPage + 1,
                              orderByCity == "asc" ? "desc" : "asc",
                              searchData
                            )
                          }
                        >
                          Created At{" "}
                          <span>
                            <i class="fa-solid fa-sort"></i>
                          </span>
                        </th>
                        {grantPermissionForEditCities ? (
                          <>
                            <th className="ad-long">Status</th>
                            <th className="ad-long">Actions</th>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {cityList?.length > 0 ? (
                        cityList?.map((item, index) => (
                          <tr key={index}>
                            <td>{currentPage * 10 + (index + 1)}</td>

                            <td>{item?.name_en}</td>
                            <td>{item?.name_hi}</td>
                            <td>
                              {
                                stateList?.find(
                                  (i) => i.value == item?.state_id
                                )?.label
                              }
                            </td>
                            <td>
                              {
                                fullDistrictListDropdownData?.find(
                                  (i) => i.value == item?.district_id
                                )?.label
                              }
                            </td>
                            <td>
                              {item?.created_at
                                ? formatDateWithSlash(item?.created_at)
                                : "--"}
                            </td>
                            {grantPermissionForEditCities ? (
                              <>
                                <td>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item?.status == 1}
                                      onChange={(e) =>
                                        handleEnableDisable(
                                          e.target.checked
                                            ? { value: "1" }
                                            : { value: "0" },
                                          item
                                        )
                                      }
                                    />
                                    <span className="slider"></span>
                                  </label>
                                </td>

                                {/* <td>
                            <div class="action-btn d-flex">
                              <span
                                className="ad-cat-edit"
                                onClick={() => {
                                  editCity(item);
                                  setEditContainer(true);
                                }}
                              >
                                <EditIcon />
                              </span>
                              <span
                                className="ad-cat-delete"
                                onClick={() => setDeleteData(item)}
                              >
                                <DeleteIcon />
                              </span>
                            </div>
                          </td> */}

                                <td>
                                  <div class="action-btn d-flex">
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id={`edit-tooltip-${item.id}`}>
                                          Edit
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="ad-cat-edit"
                                        onClick={() => {
                                          editCity(item);
                                          setEditContainer(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </span>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id={`delete-tooltip-${item.id}`}
                                        >
                                          Delete
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="ad-cat-delete"
                                        onClick={() => setDeleteData(item)}
                                      >
                                        <DeleteIcon />
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))
                      ) : tableDataLoaded ? (
                        <NotFoundTable colSpan="8"></NotFoundTable>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                  {totalPages > 1 && cityList?.length > 0 &&
                    <div className="d-flex w-100 justify-content-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={totalPages} // Total number of pages from API
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageChange} // Method to handle page click
                        forcePage={currentPage}
                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={`page-item${!cityList?.length > 0 ? ' disabled' : ''}`}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>

                  }
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete this City ?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button
              onClick={() => {
                deleteCity(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt no-btn"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default Cities;
