import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useLocation, useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { addDocumentData, getCategoriesDropdown, getSubCategoriesDropdown, updateDocumentData } from '../../../services/api/admin';
import { Message } from '../../../components/message';
import Select from 'react-select';
import { checkPatternFileSize, checkPatternFontSize, customStyles, customStylesAcc, selectTheme } from '../../../Utils/common';

const AddUploadDocument = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [nameEn, setNameEn] = useState('')
    const [nameHi, setNameHi] = useState('')
    const [size, setSize] = useState('')
    // const [type, setType] = useState('')
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [nameEnErr, setNameEnErr] = useState('')
    const [nameHiErr, setNameHiErr] = useState('')
    const [sizeErr, setSizeErr] = useState('')
    const [typeErr, setTypeErr] = useState('')
    const [categoryIdErr, setCategoryIdErr] = useState();
    const [editData, setEditData] = useState()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [selectedOptions, setSelectedOptions] = useState([]);
    const fileTypeList = [
        { label: 'Docx', value: '1' },
        { label: 'PDF', value: '2' },
        { label: 'Xlsx', value: '3' },
        { label: 'Pptx', value: '4' },
        { label: 'Txt', value: '5' },
        { label: 'Csv', value: '6' },
        { label: 'Jpg/Jpeg ', value: '7' },
        { label: 'Png', value: '8' },
    ];


    useEffect(() => {
        if (location?.state && location?.pathname == "/admin/category/documents/update") {
            editDocument(location?.state)
        }
    }, [location?.state])



    const handleChange = async (field, value) => {
        switch (field) {
            case 'nameEn':
                if (value.trim() === "") {
                    setNameEn('');
                    setNameEnErr("Please Enter English Name");
                } else {
                    setNameEnErr("");
                    setNameEn(value.replace(/[^a-zA-Z ]/g, ''));
                }
                break;

            case 'nameHi':
                if (value.trim() === "") {
                    setNameHi('')
                    setNameHiErr("Please Enter Hindi Name");
                } else {
                    setNameHiErr("");
                    setNameHi(value.replace(/[^a-zA-Z\u0900-\u097F ]/g, ''));
                }
                break;

            case 'size':
                if (await checkPatternFileSize(value)) {
                    // setSize(value); 
                    setSizeErr("");
                } else if (value === "") {
                    setSizeErr("Please Enter Document Maximum Size");
                } else {
                    return;
                }
                setSize(value);
                break;

            case 'type':
                setSelectedOptions(value.label);
                break;
            case 'categoryId':
                setCategoryId(value);
                setCategoryIdErr(!value ? "Please Select Sub Category" : "");
                break;

            default:
                break;
        }
    };

    const validateForm = () => {
        let isValid = true;

        if (!nameEn.trim()) {
            setNameEnErr("Please Enter English Name");
            isValid = false;
        }

        if (!nameHi.trim()) {
            setNameHiErr("Please Enter Hindi Name");
            isValid = false;
        }

        if (!categoryId) {
            setCategoryIdErr("Please Select Sub Category");
            isValid = false;
        }

        if (selectedOptions.length < 1) {
            setTypeErr("Please Select Valid Docuemnt Type (PDF, DOC, TXT)");
            isValid = false;
        }

        if (!size) {
            setSizeErr("Please Enter Valid Docuemnt Size");
            isValid = false;
        }

        return isValid;
    };


    const addDocument = async () => {
        if (!validateForm("both")) return
        try {
            console.log(categoryId)
            const formData = new FormData();
            formData.append("name_en", nameEn)
            formData.append("category_id", categoryId)

            formData.append("name_hi", nameHi)

            formData.append("size", size)

            formData.append("type", selectedOptions)

            const data = await addDocumentData(formData)
            if (data) {
                handleEmpty()
                navigate("/admin/category/documents", { state: { message: data.message } })
            }
        } catch (error) {
            console.error(error);
        }
    }

    const updateDocument = async () => {
        if (!validateForm("both")) return
        try {
            console.log(categoryId)
            const formData = new FormData();
            formData.append("name_en", nameEn)
            formData.append("category_id", categoryId)
            formData.append("name_hi", nameHi)

            formData.append("size", size)

            formData.append("type", selectedOptions)

            formData.append("document_id", editData?.id)
            const data = await updateDocumentData(formData, editData?.id)
            if (data.status == 200) {
                handleEmpty()
                navigate("/admin/category/documents", { state: { message: data.message } })
            }
        } catch (error) {
            console.error(error);
        }
    }

    const editDocument = (item) => {
        // const type = Array.isArray(item?.type)
        //     ? item.type
        //     : JSON.parse(item?.type || '[]');

        setSelectedOptions(item?.type); // Set as an array of { label, value }
        setEditData(item);
        setCategoryId(item.category_id);
        setNameEn(item.name_en);
        setNameHi(item.name_hi);
        setSize(item.size);
        window.scrollTo(0, 0);
    };


    const handleEmpty = () => {
        setNameEn('')
        setNameHi('')
        setSize('')
        setSelectedOptions([])
        setNameEnErr('')
        setCategoryId('')
        setSizeErr('')
        setTypeErr('')
        setCategoryIdErr('')
    }

    useEffect(() => {
        const getCategoryList = async () => {
            try {
                const subCategoryData = await getSubCategoriesDropdown();
                if (subCategoryData && subCategoryData?.status === 200) {
                    const data = subCategoryData?.data?.map(i => ({
                        label: i?.category_name,
                        value: i?.id
                    }));
                    setSubCategoryList(data);
                }
            } catch (error) {
                // console.log("error on sub category list: ", error);
                setApiMessage({ type: 'error', message: error?.message });
            }
        };
        getCategoryList();
    }, []);


    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span> Document Form</span>

                    </div>
                </div>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"> <button onClick={() => navigate("/admin/category/documents")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Document</div>
                        <div className="row py-4">
                            <div className="col-md-3 col-lg-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='lf-label w-100'>
                                        Select Sub Category
                                    </label>
                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        placeholder="Select Sub Category"
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        isDisabled={editData?.id}
                                        value={subCategoryList.find((option) => option.value === categoryId) || null}
                                        options={subCategoryList}
                                        onChange={(selectedOption) => handleChange('categoryId', selectedOption.value)}
                                    />
                                    {categoryIdErr && <p className='validate-error'>{categoryIdErr}</p>}
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name
                                    </label>
                                    <input
                                        maxLength={100}
                                        className='w-100'
                                        placeholder='Enter English Name'
                                        type="text"
                                        value={nameEn}
                                        onChange={(e) => {
                                            handleChange('nameEn', e.target.value)
                                        }}
                                    />
                                    {nameEnErr && <p className='validate-error'>{nameEnErr}</p>}


                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name
                                    </label>
                                    <input
                                        maxLength={100}
                                        className='w-100'
                                        placeholder='Enter Hindi Name'
                                        type="text"
                                        value={nameHi}
                                        onChange={(e) => handleChange('nameHi', e.target.value)}
                                    />
                                    {nameHiErr && <p className='validate-error'>{nameHiErr}</p>}

                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Document Maximum Size(in kb)
                                    </label>
                                    <input
                                        max={2000}
                                        className='w-100'
                                        placeholder='Enter Document Maximum Size Size'
                                        type="number"
                                        value={size}
                                        onChange={(e) => handleChange('size', e.target.value)}
                                    />
                                    {sizeErr && <p className='validate-error'>{sizeErr}</p>}

                                </div>
                            </div>
                            <div className="col-md-3 col-lg-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Document Type
                                    </label>
                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        options={fileTypeList}
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        // value={selectedOptions} // Array of objects
                                        value={fileTypeList.find((option) => option.label === selectedOptions) || null}
                                        onChange={(value) => handleChange('type', value)} // Handle changes correctly
                                        placeholder="Choose Document Types..."
                                    />
                                    {typeErr && <p className='validate-error'>{typeErr}</p>}

                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="role_b ad-cat-row">
                                    {
                                        editData?.id ?
                                            <>
                                                <div className="">
                                                    <button onClick={() => updateDocument()} className='suf-can-butt w-auto mx-3 px-4'>
                                                        Update
                                                    </button> </div></> :
                                            <div className="">
                                                <button onClick={addDocument}>
                                                    Submit
                                                </button></div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </AdminDashboard >
    )
}

export default AddUploadDocument
