import React, { useState, useEffect } from 'react';
import { CheckIcon, CircleIcon, MandatoryDocumentIcon } from '../Guidelines/GuidelineIcon'
import { ApplicationFees, CertificateTenure, Timeline } from './CategoryIcons'
import './CategoryInfo.css'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../LanguageProvider/LanguageProvider '
import { GetLocalStorage, GetLocalStorageParsed } from '../../Utils/Utils';
import { getGuideLines, getDetails } from '../../services/api/outer';

const CategoryInfoDetail = () => {
    const { language } = useLanguage();

    const navigate = useNavigate();

    const getLoginSubCabData = GetLocalStorage("subCategoryClick");

    const [guidelines, setGuidelines] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [details, setDetails] = useState(null);

    useEffect(() => {
        const fetchGuidelines = async () => {
            try {
                const getLoginSubCabData = GetLocalStorageParsed("subCategoryClick");
                console.log(getLoginSubCabData)
                if (getLoginSubCabData) {
                    const payload = {
                        category_id: getLoginSubCabData.id
                    };
                    const response = await getGuideLines(payload);
                    console.log("response", response);
                    setGuidelines(response);
                }
            } catch (error) {
                setError("Failed to load guidelines");
                console.error("API error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGuidelines();
    }, []);

    useEffect(() => {
        const fetchDetails = async () => {
            const getLoginSubCatData = GetLocalStorageParsed("subCategoryClick");
            try {
                if (getLoginSubCabData) {
                    const payload = {
                        category_id: getLoginSubCatData.id
                    };
                    const response = await getDetails(payload);
                    setDetails(response);

                }
            } catch (error) {
                setError("Failed to load details");
                console.error("API error:", error);
            } finally {
                setLoading(false);
            }

        };

        fetchDetails();

    }, []);

    return (
        <div className="container">
            <div className="gc-outline">
                <div className=' mb-5 d-flex gap-3 w-100 gc-main-div'>
                    <div className=" cid-main-div">
                        <div className="cid-circle">
                            <ApplicationFees />
                        </div>
                        <div className="cid-text">
                            <p className='app-fee'>{language ? "आवेदन शुल्क" : "Application Fees"}</p>
                            <p className='app-rs'>Rs {details?.fees}</p>
                        </div>
                    </div>
                    <div className="cid-timeline-div">
                        <div className="cid-circle">
                            <Timeline />
                        </div>
                        <div className="cid-text">
                            <p className='app-fee'>{language ? "समय" : "Timeline"}</p>
                            <p className='app-rs'>{details?.timeline} {language ? " दिन" : " Days"}</p>
                        </div>
                    </div>
                    <div className=" cid-certificate-div">
                        <div className="cid-circle">
                            <CertificateTenure />
                        </div>
                        <div className="cid-text">
                            <p className='app-fee'>{language ? "प्रमाणपत्र कार्यकाल" : "Certificate Tenure:"}</p>
                            <p className='app-rs'>{details?.certificate_tenure} {language ? " साल" : " Years"}</p>
                        </div>
                    </div>
                    <div className="apply-now-div ">
                        <button className='cid-apply-now' onClick={() => navigate("/login")}>{language ? "आवेदन करें" : " Apply Now"}</button>
                    </div>
                </div>
                <div className="gc-inner">
                    <div className="gc-col">
                        <div className="gc-head">
                            <MandatoryDocumentIcon /> {language ? "अनिवार्य दस्तावेज़" : "Mandatory Documents"}
                        </div>
                        <div className='gc-content'>
                            <span dangerouslySetInnerHTML={{ __html: language ? guidelines?.mandatory_guidelines_hi : guidelines?.mandatory_guidelines_en }}></span>
                        </div>
                    </div>
                    <div className="gc-col">
                        <div className="gc-head">
                            <MandatoryDocumentIcon />{language ? "वैकल्पिक दस्तावेज़" : "Optional Documents"}
                        </div>
                        <div className='gc-content-bottom'>
                            <span dangerouslySetInnerHTML={{ __html: language ? guidelines?.optional_guidelines_hi : guidelines?.optional_guidelines_en }}></span>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
export default CategoryInfoDetail