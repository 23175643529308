/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { UploadIcon } from '../AdminIcon'
import { Apiurl, GetLocalStorage, Imageurl } from '../../../Utils/Utils'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { addCategoryData, getFontFamilyData, updatCategoryData } from '../../../services/api/admin'
import { Message } from '../../../components/message'
import { checkPatternFontSize, checkPattern, customStylesAcc, selectTheme } from '../../../Utils/common'

const CategoryAdd = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const location = useLocation()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const token = GetLocalStorage("userInfo")
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState();

    const [nameHi, setNameHi] = useState("");
    const [nameHiError, setNameHiError] = useState();

    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState();

    const [image, setImage] = useState();
    const [imageError, setImageError] = useState();

    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();

    const [fontColor, setFontColor] = useState("#000000");
    const [fontColorError, setFontColorError] = useState();

    const [fontSize, setFontSize] = useState();
    const [fontSizeError, setFontSizeError] = useState();

    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [backgroundColorError, setBackgroundColorError] = useState();
    const [editData, setEditData] = useState()
    const [fontsList, setFontsList] = useState()
    const [file, setFile] = useState();

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            if (location?.state && location?.pathname == "/admin/categories/update") {
                editCategory(location?.state)
            }
        }

    }, [location?.state, fontsList])

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    }

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const pattern = /^[a-zA-Z\s]*$/;
        const cursorPosition = e.target.selectionStart;

        // Check for "nameEn" field
        if (name === "nameEn") {

            if (await checkPattern(value)) {
                setNameError("")
                setName(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "nameHi" field
        if (name === "nameHi") {
            if (await checkPattern(value)) {
                setNameHiError("")
                setNameHi(value);
            } else {
                e.preventDefault()
                return;
            }
        }

        // Check for "desc" field
        if (name === "desc") {
            if (await checkPattern(value)) {
                setDescriptionError("")
                setDescription(value);  // Update state if valid input
            } else {
                e.preventDefault()
                return;
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };



    //validations
    const validateForm = (item) => {
        let isValid = true;

        if (item === "name" || item === "both") {
            if (!name || name.trim() === "") {
                setNameError("Please Enter English Name");
                isValid = false;
            } else {
                setNameError("");
            }
        }
        if (item === "nameHi" || item === "both") {
            if (!nameHi || nameHi.trim() === "") {
                setNameHiError("Please Enter Hindi Name");
                isValid = false;
            } else {
                setNameHiError("");
            }
        }

        if (item === "desc" || item === "both") {
            if (!description || description.trim() === "") {
                setDescriptionError("Please Enter Description");
                isValid = false;
            } else {
                setDescriptionError("");
            }
        }

        if (item === "img" || item === "both") {
            if (!image) {
                console.log('imageErr', "Please Upload Image")
                setImageError("Please Upload Image");
                isValid = false;
            } else {
                setImageError("");
            }
        }

        if (item === "family" || item === "both") {
            if (!fontFamily) {
                setFontFamilyError("Please Select Font Family");
                isValid = false;
            } else {
                setFontFamilyError("");
            }
        }

        if (item === "color" || item === "both") {
            if (!fontColor || fontColor.trim() === "") {
                setFontColorError("Please Select Font Color");
                isValid = false;
            } else {
                setFontColorError("");
            }
        }

        if (item === "size" || item === "both") {
            if (!fontSize || isNaN(fontSize) || fontSize <= 0) {
                setFontSizeError("Please Enter Valid Font Size");
                isValid = false;
            } else {
                setFontSizeError("");
            }
        }

        if (item === "bgcolor" || item === "both") {
            if (!backgroundColor || backgroundColor.trim() === "") {
                setBackgroundColorError("Please Select Background Color");
                isValid = false;
            } else if (backgroundColor == fontColor) {
                setBackgroundColorError("Font color and background color cannot be same.");
                isValid = false;
            } else {
                setBackgroundColorError("");
            }
        }

        return isValid;
    };

    // add Category
    const addCategory = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            const keyName = name?.toLowerCase()?.replaceAll(" ", "_")
            // formData.append("category_key", keyName)
            formData.append("category_name", name)
            formData.append("category_name_hi", nameHi)
            formData.append("category_description", description)
            formData.append("category_image", image)
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            const data = await addCategoryData(formData)
            if (data.status == 200) {
                navigate("/admin/categories", { state: { message: data.message } })
            } else if (data.status == 403) {
                setApiMessage({ type: 'error', message: data.message });
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error.message })
        }
    }

    //get font families
    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
        }
    };

    useEffect(() => {
        getFontFamily()
    }, [])

    //edit category
    const editCategory = (item) => {
        setEditData(item)
        setName(item?.category_name);
        setNameHi(item?.category_name_hi);
        setImage(item?.category_image)
        setDescription(item?.category_description)
        setFontFamily(fontsList?.find(i => i?.value == item?.font_family_id));
        setFontColor(item?.font_color)
        setFontSize(item?.font_size)
        setImage(item?.category_image)
        setBackgroundColor(item?.background_color)
        window.scrollTo(0, 0);
    }
    //update category
    const updateCategory = async () => {
        if (!validateForm("both")) {
            return
        }
        try {
            const formData = new FormData();
            const keyName = name?.toLowerCase()?.replaceAll(" ", "_")
            // formData.append("category_key", keyName)
            formData.append("category_id", editData?.id)
            formData.append("category_name", name)
            formData.append("category_name_hi", nameHi)
            formData.append("category_description", description)
            formData.append("category_image", image)
            formData.append("font_family_id", fontFamily?.value)
            formData.append("font_color", fontColor)
            formData.append("font_size", fontSize)
            formData.append("background_color", backgroundColor)
            const response = await updatCategoryData(formData)
            if (response.status == 200) {
                navigate("/admin/categories", { state: { message: response.message } })
            } else {
                setApiMessage({ type: 'error', message: response.message });
            }

        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error?.message });
        }
    }

    const handleFileChange = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                setImage(file);
                setFile(URL.createObjectURL(e.target.files[0]));
                setImageError('');
            } else {
                setImage(null);
                setImageError('Please Upload Image.');
                setFile('')
                e.target.value = '';
            }
        }
    };
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <b onClick={() => navigate("/admin/")}>Home  /</b><span> Category Form</span>
                    </div>
                </div>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"><button onClick={() => navigate("/admin/categories")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Category</div>
                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        English Name <span class="text-danger required_f">*</span>
                                    </label>
                                    <input
                                        className='w-100'
                                        maxLength={100}
                                        name='nameEn'
                                        placeholder='Enter English Name'
                                        type="text"
                                        onChange={handleInputChange} // Use onChange for real-time validation
                                        value={name}
                                    />
                                    {/* <input className='w-100' maxLength={100} name='name' placeholder='Enter Category English Name' type="text" onChange={(e) => handleKeyUpLoginDetails(e)} value={name} /> */}

                                    {nameError && <p className='validate-error'>{nameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Hindi Name <span class="text-danger required_f">*</span>
                                    </label>
                                    <input maxLength={100} className='w-100' placeholder='Enter Hindi Name' type="text" name='nameHi' value={nameHi} onChange={handleInputChange} />

                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>

                                    <label className='mb-1 w-100'>
                                        Description <span class="text-danger required_f">*</span>
                                    </label>
                                    <input className='w-100' maxLength={250} placeholder='Enter Description' type="text" name='desc' value={description} onChange={handleInputChange} />

                                    {descriptionError && <p className='validate-error'>{descriptionError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab d-flex  align-items-top">
                                <div className='suf-input-box aod-form-input mb-2 w-100'>
                                    <label className='mb-1 w-100'>
                                        Image <span class="text-danger required_f">*</span>
                                    </label>
                                    <input type="file" name="partsuploadfile" id="profileimg" className="w-100 ad-cat-input-field-image" accept="image/*" onKeyUp={() => handleKeyUpLoginDetails("img")} onChange={(e) => handleFileChange(e)} />
                                    <label htmlFor="profileimg" className="ad-cat-input-upload-image p-0">
                                        <p className="ad-cat-text-upload-image">
                                            <span><UploadIcon /></span>  {
                                                typeof image === 'string' ? (
                                                    <p className="mb-0">{image.split('\\').pop().split('/').pop()}</p>
                                                ) : (
                                                    image?.name ? (
                                                        <p className="mb-0 fw-semibold">{image?.name}</p>
                                                    ) : (
                                                        "Choose Category Image"
                                                    )
                                                )
                                            }
                                        </p>
                                    </label>
                                    {

                                        imageError ? <p className="validate-error">{imageError}</p> : ""


                                    }

                                </div>
                                {file &&
                                    <div id="img_preview" class="pt-4">
                                        <img className="ms-1 uplaoed_img_preview" src={file} alt="" />
                                    </div>

                                }
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Family <span class="text-danger required_f">*</span></label>
                                    <Select
                                        styles={customStylesAcc}
                                        theme={selectTheme}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        className='menu_open_select'
                                        classNamePrefix='ad-menu_open_select_class'
                                        aria-label="Default select example"
                                        value={fontFamily}
                                        options={fontsList}
                                        onChange={(e) => { setFontFamily(e); setFontFamilyError(""); }}
                                        placeholder="Select Font Family"
                                        onMenuOpen={() => handleKeyUpLoginDetails("family")}
                                        onMenuClose={() => handleKeyUpLoginDetails("family")}
                                    />
                                    {fontFamilyError && <p className='validate-error'>{fontFamilyError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Color <span class="text-danger required_f">*</span></label>
                                    <label className='adm-label-color'>
                                        {fontColor ? fontColor : <span>Select Font Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("color")} onBlur={() => handleKeyUpLoginDetails("color")} value={fontColor} onChange={(e) => setFontColor(e?.target?.value)} />
                                    </label>
                                    {fontColorError && <p className='validate-error'>{fontColorError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Font Size (in pixels) <span class="text-danger required_f">*</span></label>
                                    <input className='w-100' max={20} placeholder='Enter Font Size' type="number" value={fontSize} onKeyUp={() => handleKeyUpLoginDetails("size")}
                                        onKeyDown={(e) => {
                                            if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={async (e) => {
                                            const value = e.target.value;
                                            if (value > 0) {
                                                if (await checkPatternFontSize(value)) {
                                                    setFontSize(value);
                                                    setFontSizeError("")
                                                }
                                            } else {
                                                setFontSize("");

                                            }
                                        }} />
                                    {fontSizeError && <p className='validate-error'>{fontSizeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>Background Color <span class="text-danger required_f">*</span></label>
                                    <label className='adm-label-color'>
                                        {backgroundColor ? backgroundColor : <span>Select Background Color</span>}
                                        <input className='adm-color' placeholder='Enter Font Color' type="color" onKeyUp={() => handleKeyUpLoginDetails("bgcolor")} onBlur={() => handleKeyUpLoginDetails("bgcolor")} value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
                                    </label>
                                    {backgroundColorError && <p className='validate-error'>{backgroundColorError}</p>}
                                </div>
                            </div>

                        </div>
                        <div className="row ad-cat-row">
                            <div className=" update-btns-block">
                                {
                                    editData?.id ?
                                        <>

                                            <button onClick={() => updateCategory()}>
                                                Update
                                            </button></> :

                                        <button onClick={addCategory}>
                                            Submit
                                        </button>

                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default CategoryAdd
