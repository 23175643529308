import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import {
  deleteGuidelinesFieldsData,
  getGuidelinesFieldsDataSort,
  getSubCategoriesDropdown,
  getSubCategoryListData,
} from "../../../services/api/admin";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { GetLocalStorage, RemoveSession } from "../../../Utils/Utils";
import "./AdminGuildlines.css";
import { HiPlus } from "react-icons/hi";
import sortByIcon from "../../../assets/images/sort.png";
import ReactPaginate from "react-paginate";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import { Message } from "../../../components/message";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FaPencilAlt } from "react-icons/fa";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import { formatDateWithSlash } from "../../../Utils/common";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const AdminGuidelineslist = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState()
    const [searchSubCategory, setSearchSubCategory] = useState();
    const [searchData, setSearchData] = useState('')
    const [sortBy, setSortBy] = useState('created_at')
    const [subCategoryList, setSubCategoryList] = useState([])
    const [guidelineslist, setGuidelineslist] = useState()
    const [tableDataLoaded, setTableDataLoaded] = useState(false)
    const [deleteData, setDeleteData] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("desc");
    const token = GetLocalStorage("userInfo")
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const { state } = useLocation();

    useEffect(() => {
        if (!token) {
            navigate("/")
        } else {
            getSubCategoryList()
            getGuidelinesData('', "created_at", 1, "desc", "");
        }
    }, [])

    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
            // Clear the message once it is rendered
            window.scrollTo(0, 0);
            window.history.replaceState({}, document.title);
        }
    }, [state])

    const getSubCategoryList = async () => {
        try {
            const subCategoryList = await getSubCategoriesDropdown()
            const data = subCategoryList?.data?.map(item => ({
                value: item?.id,
                label: item?.category_name
            }))
            setSubCategoryList(data)
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message });
        }
    }
  // }, []);

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      // Clear the message once it is rendered
      window.scrollTo(0, 0);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  

  const grantPermissionForEditGuidelinesList = checkPermission(
    permissionsConfig.editGuidelines.role,
    permissionsConfig.editGuidelines.action,
    permissionsConfig.editGuidelines.type
  );
  const grantPermissionForAddGuidelinesList = checkPermission(
    permissionsConfig.addGuidelines.role,
    permissionsConfig.addGuidelines.action,
    permissionsConfig.addGuidelines.type
  );

  const resetFilter = () => {
    setSearchSubCategory("");
    getGuidelinesData("", sortBy, 1, orderBy, searchData);
  };

  const getGuidelinesData = async (
    searchedSubCategory,
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const guidelinesList = await getGuidelinesFieldsDataSort(
        sortBy,
        orderBy,
        searchBy,
        pageNo,
        searchedSubCategory?.value ? searchedSubCategory?.value : ""
      );
      if (guidelinesList?.status == 200) {
        setTotalPages(
          Math.ceil(
            guidelinesList?.data?.total / guidelinesList?.data?.per_page
          )
        );
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        const data = guidelinesList?.data?.data;
        setGuidelineslist(data);
      } else {
        setGuidelineslist([]);
        setApiMessage({ type: "error", message: guidelinesList?.message });
      }
      setTableDataLoaded(true);
    } catch (error) {
      console.log("error on guidelinesList list: ", error);
    }
  };

  const deleteGuideline = async (item) => {
    try {
      const payload = {
        guideline_id: item.id,
        category_id: item.category_id,
      };
      const data = await deleteGuidelinesFieldsData(payload);
      if (data.status == 200) {
        setDeleteData("");
        getGuidelinesData(
          searchSubCategory,
          sortBy,
          currentPage,
          orderBy,
          searchData
        );
        setApiMessage({ type: "success", message: data.message });
      } else {
        setDeleteData("");
      }
    } catch (error) {
      console.error(error);
      setDeleteData("");
      setApiMessage({ type: "error", message: error.message });
    }
  };

  const handlePageChange = (selectedPage) => {
    getGuidelinesData(
      searchSubCategory,
      sortBy,
      selectedPage.selected + 1,
      orderBy,
      searchData
    ); // Increment by 1 if the API starts page numbers from 1
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>
            <span> Guidelines</span>
          </div>
          {grantPermissionForAddGuidelinesList ? (
            <button
              className="adm-tab-btn"
              onClick={() => navigate("/admin/guidelines/add")}
            >
              <span>
                <HiPlus />
              </span>{" "}
              Add Guideline
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer">
              <h4 className="mb-0 w-100">Guidelines</h4>
              <input
                className="w-25 px-2w-25 px-2 form-control"
                placeholder="search"
                type="text"
                onChange={(e) =>
                  getGuidelinesData(
                    searchSubCategory,
                    sortBy,
                    1,
                    orderBy,
                    e.target.value
                  )
                }
              />
            </div>
            <div className="aod-head"></div>
            <div className="row py-4">
              <div className="col-md-4 aod-resp-tab">
                <div className="suf-input-box aod-form-input mb-2">
                  <Select
                    className="menu_open_select w-100"
                    classNamePrefix="ad-menu_open_select_class"
                    aria-label="Default select example"
                    value={searchSubCategory}
                    options={subCategoryList}
                    onChange={(e) => {
                      setSearchSubCategory(e);
                      getGuidelinesData(e, sortBy, 1, orderBy, searchData);
                    }}
                    placeholder="Select Sub Category"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <button
                  onClick={resetFilter}
                  className="ad-reset-btn w-auto px-4"
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table">
                <thead>
                  <tr>
                    <th className="ad-sno">S No</th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getGuidelinesData(
                          searchSubCategory,
                          "mandatory_guidelines_en",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      English Mandatory Guidelines{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getGuidelinesData(
                          searchSubCategory,
                          "mandatory_guidelines_hi",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Hindi Mandatory Guidelines{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getGuidelinesData(
                          searchSubCategory,
                          "optional_guidelines_en",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      English Optional Guidelines{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getGuidelinesData(
                          searchSubCategory,
                          "optional_guidelines_hi",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Hindi Optional Guidelines{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getGuidelinesData(
                          searchSubCategory,
                          "sub_category_name",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Sub Category{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getGuidelinesData(
                          searchSubCategory,
                          "created_at",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Created At{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    {grantPermissionForEditGuidelinesList ? (
                      <th className="ad-long">Actions</th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {guidelineslist?.length > 0 ? (
                    guidelineslist?.map((item, index) => (
                      <tr key={index}>
                        <td>{currentPage * 10 + (index + 1)}</td>
                        {/* <td>{item?.category_id || "--"}</td> */}
                        <td>
                          {item?.mandatory_guidelines_en
                            ? item.mandatory_guidelines_en.length > 8
                              ? item.mandatory_guidelines_en.substring(0, 18) +
                                "..."
                              : item.mandatory_guidelines_en
                            : "--"}
                        </td>
                        <td>
                          {item?.mandatory_guidelines_hi
                            ? item.mandatory_guidelines_hi.length > 8
                              ? item.mandatory_guidelines_hi.substring(0, 18) +
                                "..."
                              : item.mandatory_guidelines_hi
                            : "--"}
                        </td>
                        <td>
                          {item?.optional_guidelines_en
                            ? item.optional_guidelines_en.length > 8
                              ? item.optional_guidelines_en.substring(0, 18) +
                                "..."
                              : item.optional_guidelines_en
                            : "--"}
                        </td>
                        <td>
                          {item?.optional_guidelines_hi
                            ? item.optional_guidelines_hi.length > 8
                              ? item.optional_guidelines_hi.substring(0, 18) +
                                "..."
                              : item.optional_guidelines_hi
                            : "--"}
                        </td>
                        <td>
                          {item?.sub_category_name
                            ? item?.sub_category_name
                            : item?.sub_category_name_hi
                            ? item?.sub_category_name_hi
                            : "--"}
                        </td>
                        <td>
                          {item?.created_at
                            ? formatDateWithSlash(item?.created_at)
                            : "--"}
                        </td>
                       
                           
                        {grantPermissionForEditGuidelinesList ? (
                          <td>
                            <div className="action-btn d-flex">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`edit-tooltip-${item.id}`}>
                                    Edit
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="ad-cat-edit"
                                  onClick={() =>
                                    navigate("/admin/guidelines/update", {
                                      state: item,
                                    })
                                  }
                                >
                                  <EditIcon />
                                </span>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`delete-tooltip-${item.id}`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <span
                                  className="ad-cat-delete"
                                  onClick={() => setDeleteData(item)}
                                >
                                  <DeleteIcon />
                                </span>
                              </OverlayTrigger>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  ) : tableDataLoaded ? (
                    <NotFoundTable colSpan="8"></NotFoundTable>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {totalPages > 1 && guidelineslist?.length > 0 &&
                                <div className="d-flex w-100 justify-content-end">
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={totalPages} // Total number of pages from API
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={handlePageChange} // Method to handle page click
                                        forcePage={currentPage}
                                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={`page-item${!guidelineslist?.length > 0 ? ' disabled' : ''}`}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>

                            }
                                                           
                                                       
                           
                        </div>
                    </div>
                </div>
            </div>
         
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Guideline ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2">
            <button onClick={() => deleteGuideline(deleteData)}>Yes</button>
            <button
              className="suf-can-butt no-btn"
              onClick={() => setDeleteData(false)}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default AdminGuidelineslist;
