import React, { useState } from "react";
import checkicon from "../../assets/images/tic-png.png";
import paymentImage from "../../assets/images/payment-img.png";
import { FaRegCheckCircle } from "react-icons/fa";
import { CgCheckO, CgCloseO } from "react-icons/cg";

const Payments = () => {
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [apiMessage, setApiMessage] = useState(false);
  const [stateObject, setStateObject] = useState(false);
  const handleSubmitLogin = async (event) => {
    if (!condition1 || !condition2 || !condition3) {
      setApiMessage("Please agree to all conditions before proceeding.");
      event.preventDefault();
      return;
    } else {
      setStateObject(true);
      event.preventDefault();
      return;
    }
  };

  return (
    <div>
      {!stateObject ? (
        <>
          <div className="payment-wrap">
            <div className="row">
              <div className="col-xl-8 col-md-12 col-sm-12">
                <div className="pay-main-block">
                  <div className="pay-bar">
                    <h5 className="mb-0">Applicant Details</h5>
                  </div>
                  <div className="pay-inner-block">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="pay-detail-block">
                          <div className="pay-left-icon mx-3">
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>Organization Name</h6>
                            <span>Tour & Travels</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>Email</h6>
                            <span>abc@drisinfo.com</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>District</h6>
                            <span>Chandigarh</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block mt-4">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>Owner Name</h6>
                            <span>Rakesh</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block mt-4">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>Mobile Number</h6>
                            <span>9877653452</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="pay-detail-block mt-4">
                          <div className="pay-left-icon mx-3">
                            {" "}
                            <img src={checkicon} alt="check-icon" />
                          </div>
                          <div className="pay-right-name">
                            <h6>Amount</h6>
                            <span>2000</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 d-lg-none d-xl-block">
                <div className="payment-img">
                  <img src={paymentImage} alt="payment-image" />
                </div>
              </div>

              <div className="conditions-block">
                <div className="form-group">
                  <input
                    type="checkbox"
                    id="condition1"
                    checked={condition1}
                    onChange={() => setCondition1(!condition1)}
                  />
                  <label htmlFor="condition1">
                    I/ We agree to pay the amount of Rs. 500 as fees to
                    Uttarakhand Tourism Department.
                  </label>
                </div>

                <div className="form-group">
                  <input
                    type="checkbox"
                    id="condition2"
                    checked={condition2}
                    onChange={() => setCondition2(!condition2)}
                  />
                  <label htmlFor="condition2">
                    I hereby confirm that all the information stated above is
                    correct and authentic to the best of my understanding...
                  </label>
                </div>

                <div className="form-group">
                  <input
                    type="checkbox"
                    id="condition3"
                    checked={condition3}
                    onChange={() => setCondition3(!condition3)}
                  />
                  <label htmlFor="condition3">
                    I acknowledge and agree to upload the required documents
                    accurately and truthfully...
                  </label>
                </div>
              </div>
            </div>
            <div className="red-text">{apiMessage}</div>
            <button
              className="btn btn-primary user-theme-btn mt-3"
              onClick={handleSubmitLogin}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="payment-successfull-block hide">
            <div className="row">
              <div className="col-md-12">
                <div className="payment-detail-blocks payment-success-inner-wrapprer">
                  <div className="paymnet-done-icon">
                    {" "}
                    <CgCheckO />
                  </div>
                  <div className="payment-done-text">
                    <p className="mb-2">Payment ID : 8900956845896</p>
                    <h5 className="">Payment Successful!</h5>
                    <p>
                      The Payment has been done successfully! Thanks for being
                      their with us.
                    </p>
                  </div>
                  <div className="dashboard-btn">
                    <button className="user-theme-btn"> Dashboard</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="payment-failed-block hide">
            <div className="row">
              <div className="col-md-12 hide">
                <div className=" payment-detail-blocks payment-failed-inner-wrapprer">
                  <div className="paymnet-done-icon">
                    {" "}
                    <CgCloseO />
                  </div>
                  <div className="payment-done-text">
                    <p className="mb-2">Payment ID : 8900956845896</p>
                    <h5 className="">Payment Failed!</h5>
                    <p>
                      The Payment has been done successfully! Thanks for being
                      their with us.
                    </p>
                  </div>
                  <div className="dashboard-btn">
                    <button className="user-theme-btn theme-btn-danger">
                      {" "}
                      Try Again
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Payments;
