import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
    GoogleMap,
    Autocomplete,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";

const dehradunCenter = { lat: 30.3165, lng: 78.0322 }; // Dehradun's coordinates

const Geolocation = ({ subCatId, initialAddress }) => {
    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(dehradunCenter);
    const [searchAddress, setSearchAddress] = useState(initialAddress || "");
    const [autocomplete, setAutocomplete] = useState(null);
    const inputRef = useRef(null);

    const [formData, setFormData] = useState({
        latitude: dehradunCenter.lat,
        longitude: dehradunCenter.lng
    });

    const GmapOptions = {
        disableDefaultUI: false,
        draggable: true,
        scrollwheel: true,
        streetViewControl: false,
    };

    // Use useMemo to keep the loader options constant
    const loaderOptions = useMemo(() => ({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCnOLiPcQ3RCoPoLG5tEd8gcqGqHVL3Nuc', //'',
        libraries: ["places"],
    }), []);

    const { isLoaded } = useJsApiLoader(loaderOptions);

    const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
        if (initialAddress) {
            geocodeAddress(initialAddress, mapInstance);
        } else {
            mapInstance.setCenter(dehradunCenter);
            mapInstance.setZoom(12);
        }
    }, [initialAddress]);

    const geocodeAddress = (address, mapInstance) => {
        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === "OK" && results[0]) {
                    const location = results[0].geometry.location;
                    const latLng = { lat: location.lat(), lng: location.lng() };
                    setMarkerPosition(latLng);
                    setSearchAddress(address);
                    setFormData({
                        latitude: latLng.lat,
                        longitude: latLng.lng
                    });
                    mapInstance.setCenter(latLng);
                    mapInstance.setZoom(15);
                }
            });
        }
    };

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                const latLng = { lat: location.lat(), lng: location.lng() };
                setMarkerPosition(latLng);
                setSearchAddress(`${place.name} ${place.formatted_address}` || "");
                map?.panTo(latLng);
                map?.setZoom(15); // Zoom in on the searched location

                setFormData({
                    latitude: location.lat(),
                    longitude: location.lng(),
                });
            }
        }
    };

    const onAutocompleteLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onMarkerDragEnd = (e) => {
        const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setMarkerPosition(latLng);
        setFormData({ latitude: latLng.lat, longitude: latLng.lng });

        if (window.google) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    setSearchAddress(results[0].formatted_address);
                }
            });
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <>
            <div className="form-block">
                <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                        type="text"
                        ref={inputRef}
                        placeholder="Search for an address"
                        value={searchAddress}
                        onChange={(e) => setSearchAddress(e.target.value)}
                        className="form-control"
                    />
                </Autocomplete>
                <GoogleMap
                    onLoad={onLoad}
                    center={markerPosition}
                    zoom={15}
                    mapContainerStyle={{ height: "400px", width: "100%" }}
                    options={GmapOptions}
                >
                    {markerPosition && (
                        <Marker
                            position={markerPosition}
                            draggable={true}
                            onDragEnd={onMarkerDragEnd}
                        />
                    )}
                </GoogleMap>
            </div>
        </>
    );
};

export default Geolocation;
