import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { getEnableDisableUser, getFrontendUserList , deleteUserFromUserId } from '../../../services/api/admin';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import ReactPaginate from 'react-paginate';
import NotFoundTable from '../../../components/notFound/NotFoundTable';
import { formatDateWithSlash } from '../../../Utils/common';
import { useNavigate } from 'react-router-dom';
import { Modal, Table } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeleteIcon } from "../AdminIcon";
import { Message } from "../../../components/message";

const FrontendUserList = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate()
    const [userList, setUserList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [orderBy, setOrderBy] = useState("asc");
    const [deleteData, setDeleteData] = useState();
    const [apiMessage, setApiMessage] = useState({ type: "", message: "" });
    const [tableDataLoaded, setTableDataLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    useEffect(() => {
        userDataList(currentPage)
    }, [])

    const userDataList = async (pageNo) => {
        try {
            const usersListData = await getFrontendUserList(pageNo)
            if (usersListData.status == 200) {
                setUserList(usersListData?.data)
            }
        } catch (err) {
            setApiMessage({ type: 'error', message: err?.message })
        }
    }

    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("user_id", item?.id);
            formData.append("status", e?.value);
            const data = await getEnableDisableUser(formData);
            if (data?.status == 200) {
                setApiMessage({ type: "success", message: data.message });
                userDataList(currentPage);
            }
        } catch (error) {
            setApiMessage({ type: "error", message: error.message });
        }
    };

    const searchSortFromList = async (e, sortBy) => {
        // try {
        //     // const searchBy = e.target.value ? e.target.value : "";
        //     // const validInput = /^[A-Za-z\s]*$/;
        //     // if (!validInput.test(searchBy)) {
        //     //     e.target.value = searchBy.replace(/[^A-Za-z\s]/g, "");
        //     //     return;
        //     // }
        //     // setOrderBy(orderBy === "asc" ? "desc" : "asc");
        //     // // const tabList = await getRoleListDataSort(sortBy, orderBy, searchBy);
        //     // const data = tabList?.data?.data;
        //     // setUserList(data);
        // } catch (err) {
        //     console.log("error on categoryList: ", err);
        // }
    };
    const deleteUser = async (item) => {
        try {
            const formData = new FormData();
            formData.append("user_id", item?.id);
            const data = await deleteUserFromUserId(formData);
            if (data.status == 200) {
                setDeleteData("");
                userDataList(currentPage);
                setApiMessage({ type: "success", message: data.message });
            } else {
                setDeleteData("");
            }
        } catch (error) {
            console.error(error);
            setDeleteData("");
            setApiMessage({ type: "error", message: error.message });
        }
    }

    return (
        <AdminDashboard>
            <div className="aod-outer">
                {apiMessage && (
                    <Message
                        apiMessage={apiMessage}
                        setApiMessage={setApiMessage}
                    ></Message>
                )}
                <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
                        <span>Users</span>
                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-inner pt-0">
                        <div className="aod-bottom">
                            <div className="adm-tabs-outer d-flex justify-content-between">
                                <h4 className='mb-0'>Users </h4>
                                {/* <div className="position-relative w-25">
                                    <input
                                        className="px-2 form-control"
                                        placeholder="Search"
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value); 
                                            searchSortFromList(e, 'title')
                                        }}
                                    />
                                    {searchTerm && ( 
                                        <button
                                            className="btn btn-clear position-absolute"
                                            onClick={(e) => {
                                                setSearchTerm(""); 
                                                searchSortFromList(e, 'title') 
                                            }}
                                            style={{
                                                top: "50%",
                                                right: "10px",
                                                transform: "translateY(-50%)",
                                                border: "none",
                                                background: "transparent",
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                            }}
                                        >
                                            &times;
                                        </button>
                                    )}
                                </div> */}
                            </div>
                            <div className="aod-head"></div>
                            <div className="ad-reg-table">
                                <Table size="sm" className='ad-cat-table mt-4'>
                                    <thead>
                                        <tr>
                                            <th className='ad-sno'>S. No</th>
                                            <th className='ad-long '>Customer Id<span><i className="fa-solid fa-sort"></i></span></th>
                                            <th className='ad-long cursor-pointer' onClick={(e) => searchSortFromList(e, 'title')}>Name <span><i className="fa-solid fa-sort"></i></span></th>
                                            <th className='ad-long '>Email Id<span><i className="fa-solid fa-sort"></i></span></th>
                                            <th className='ad-long cursor-pointer' onClick={(e) => searchSortFromList(e, 'created_at')}>Created At <span><i className="fa-solid fa-sort"></i></span></th>
                                            <th className='ad-long'>Status</th>
                                            <th className='ad-long'>Action</th>

                                            {/* <th className='ad-long' style={{ paddingLeft: '30px' }}>Actions</th> */}
                                            {/* {(grantPermissionForEditRoles) ? (<>
                                                <th className='ad-long'>Status</th>

                                                <th className='ad-long' style={{ paddingLeft: '30px' }}>Actions</th></>
                                            ) : ''} */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userList?.length > 0 ? userList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.unique_id ? item?.unique_id : '--'}</td>
                                                <td>{item?.name ? item?.name : '--'}</td>
                                                <td>{item?.email ? item?.email : '--'}</td>
                                                <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                <td>
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.status == 1}
                                                            onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip
                                                                id={`delete-tooltip-${item.id}`}
                                                            >
                                                                Delete
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span
                                                            className="ad-cat-delete"
                                                            onClick={() => setDeleteData(item)}
                                                        >
                                                            <DeleteIcon />
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                                {/* {(grantPermissionForEditRoles) ? (<>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={item?.status == 1}
                                                                onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </td>

                                                   <td>
                                                        <div className='icon-col'>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-edit"
                                                                    onClick={() => navigate("/admin/roles/update", { state: item })}
                                                                >
                                                                    <EditIcon />
                                                                </span>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`delete-tooltip-${item.id}`}>Delete</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-delete"
                                                                    onClick={() => setDeleteData(item)}
                                                                >
                                                                    <DeleteIcon />
                                                                </span>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`permission-tooltip-${item.id}`}>Permissions</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-permission"
                                                                    onClick={() => {
                                                                        navigate("/admin/roles/permissions/edit", { state: item })
                                                                    }}>
                                                                    <PermissionIcon />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td> 
                                                </>) : ''} */}
                                            </tr>
                                        )) : tableDataLoaded ? <NotFoundTable colSpan="8"></NotFoundTable> : <></>
                                        }
                                    </tbody>
                                </Table>
                                {/* {totalPages > 1 && userList?.length > 0 &&
                                    <div className="d-flex w-100 justify-content-end">
                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            breakLabel={"..."}
                                            pageCount={totalPages} // Total number of pages from API
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={handlePageChange} // Method to handle page click
                                            containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to delete this User ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        <button
                            className="mx-2"
                            onClick={() => {
                                deleteUser(deleteData);
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="suf-can-butt no-btn mx-2"
                            onClick={() => setDeleteData("")}
                        >
                            No
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}


export default FrontendUserList;
