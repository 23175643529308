import React, { useEffect, useState } from "react";
import {
  AccomodationIcon,
  AdditionalIcon,
  EmployeeIcon,
  OrganizationIcon,
  PaymentIcon,
  UploadIcon,
  UploadIconPhoto,
} from "./ApplicationIcon";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { Apiurl, GetLocalStorage, GetLocalStorageParsed } from "../../Utils/Utils";
import axios from "axios";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { BsXLg } from "react-icons/bs";
// import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import {
  createApplicationForm,
  getApplicationForm,
  getSection,
  getSectionFields,
  staticDropDownsList,
} from "../../services/api/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { customStylesAcc, customStylesAccUser, firstCharUpperCase, selectTheme, selectThemeUser } from "../../Utils/common";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import InputTypeText from "../inputType/inputTypeText";
import SelectType from "../inputType/SelectType";
import SubSection from "../inputType/subSection";
import TableTab from "../inputType/tableTab";
import DocsUpload from "../inputType/DocsUpload";
import Payment from "../inputType/Payments"
import Geolocation from "../inputType/geoloaction";
import EmpDetails from "../inputType/EmpDetails";
import { set } from "react-hook-form";

const OrganizationDetails = ({
  tabsData,
  tabActiveData,
  setTabActiveData,
  inputFormData,
  setInputFormData,
  getLoginSubCabData,
}) => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const [isEmpDetailsTab, setIsEmpDetailsTab] = useState(false);
  const location = useLocation();
  const [tabActive, setTabActive] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [applicationFromData, setApplicationFromData] = useState([]);
  const [sectionFormData, setSectionFormData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isLastTab, setIslastTab] = useState(false)
  const [isFirstTab, setIsFirstTab] = useState(false)
  const [isLocationTab, setIsLocationTab] = useState(false)
  const [sectionFieldsData, setSectionFieldsData] = useState({});
  const [isDocumnetTab, setIsDocumentTab] = useState(false)
  const [isPaymentTab, setIsPaymentTab] = useState(false)
  const [subcategoryId, setSubCategoryId] = useState()
  const applicationId = GetLocalStorage("application_id");
  const userDetail = GetLocalStorageParsed("userDetail");
  const [showEmpDetailsTab, setShowEmpDetailsTab] = useState(false)
  const combinedTabsData = [
    ...(tabsData || []), // Default to empty array if tabsData is undefined
    { id: "emp-details", name_en: "Employee Details", name_hi: "कर्मचारी विवरण" },
    { id: "doc-upload", name_en: "Document Uploads", name_hi: "दस्तावेज़ अपलोड" },
    { id: "geo-location", name_en: "Geo-Location", name_hi: "भू-स्थान" },
    { id: "make-payment", name_en: "Make Payment", name_hi: "भुगतान करें" },
  ];

  const [tabsWholeData, setTabsWholeData] = useState({
    section_fields: {},
    sub_section_fields: []
  });

  const inputTypeList = [
    { label: "text", value: 1 },
    { label: "radio", value: 4 },
    { label: "password", value: 7 },
    { label: "file", value: 5 },
    { label: "dropdown", value: 2 },
    // { label: "Sub Section", value: 6 },
    { label: "checkbox", value: 3 },
    { label: "Designation Table", value: 8 },
  ];

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (tabsData?.length) {
      setSubCategoryId(tabsData[0]?.category_id);
      fetchApplicationFormData();
    }
     const isAdditionalTabPresent = tabsData?.filter(item => item.name_en.includes("Additional Details"));
    if (isAdditionalTabPresent && isAdditionalTabPresent.length > 0){
      setShowEmpDetailsTab(true)
    }else{
      setShowEmpDetailsTab(false)
    }

    // Set the initial active tab and fetch data
    // if (combinedTabsData && combinedTabsData?.length > 0) {
    //   const initialActiveTab = combinedTabsData[0];
    //   setTabActive(initialActiveTab);
    //   setIsFirstTab(true)
    //   //setSubCategoryData(initialActiveTab.category_id)
    //   fetchSectionData(initialActiveTab, false);
    // }
  }, [tabsData]);

  useEffect(() => {
    // if (tabsData?.length) {
    //   setSubCategoryId(tabsData[0]?.category_id)
    //   fetchApplicationFormData()
    // }
    // Set the initial active tab and fetch data
    if (combinedTabsData && combinedTabsData?.length > 0) {
      const initialActiveTab = combinedTabsData[0];
      setTabActive(initialActiveTab);
      setIsFirstTab(true)
      //setSubCategoryData(initialActiveTab.category_id)
      fetchSectionData(initialActiveTab, false);
    }
  }, [applicationFromData]);

  const fetchApplicationFormData = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", tabsData[0]?.category_id);
      formData.append("application_detail_id", applicationId);
      const applicationFormResponse = await getApplicationForm(formData);
      if (applicationFormResponse.status === 200) {
        setApplicationFromData(applicationFormResponse.data);
      } else {
        setApplicationFromData(null);
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const fetchSectionData = async (tab) => {
    try {
      const formData = new FormData();
      formData.append("category_id", tab.category_id);
      formData.append("tab_id", tab.id);
      const sectionResponse = await getSection(formData);
      if (sectionResponse.status === 200) {
        fetchSectionFormData(sectionResponse.data, tab.id);
        setSectionData(sectionResponse.data);
      } else {
        setSectionData(null);
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const cretaeApplicationFormData = async (sectionFields, subSectionFields) => {
    try {
      const payload = {
        sub_category_id: tabsData[0].category_id,
        section_fields: sectionFields, // pass as an object
        sub_section_fields: subSectionFields, // pass as an object
      };

      const sectionResponse = await createApplicationForm(payload); // Send as JSON
      if (sectionResponse.status === 200) {
      } else {
      }
    } catch (err) {
      console.error("Error fetching section data:", err);
    }
  };

  const fetchSectionFormData = async (sectionObj, tabId) => {
    try {
      for (let i = 0; i < sectionObj.length; i++) {
        const formData = new FormData();
        formData.append("category_id", sectionObj[i].category_id);
        formData.append("tab_id", tabId);
        formData.append("section_id", sectionObj[i].id);

        const sectionFormResponse = await getSectionFields(formData);
        console.log('sectionFormResponse', i, sectionFormResponse);
        if (sectionFormResponse.status === 200) {
          setSectionFormData((prevData) => ({
            ...prevData,
            [sectionObj[i].id]: sectionFormResponse.data,
          }));

          sectionFormResponse.data.forEach((item) => {
            const value = applicationFromData?.["f_" + item.field_key] || ""; // Get initial value from applicationFormData if exists

            // Update formValues
            setFormValues((prevValues) => {
              if (
                !prevValues[sectionObj[i].id]?.hasOwnProperty(item.field_key)
              ) {
                return {
                  ...prevValues,
                  [sectionObj[i].id]: {

                    ...prevValues[sectionObj[i].id],
                    [item.field_key]: item.sub_section_id
                      ? value === null || value === undefined || value === '' ? "1" : value
                      : String(value),
                  },
                };
              }
              return prevValues; // No update if key already exists
            });

            setErrors({});

            if (item.sub_section_id) {
              // Populate tabsWholeData for sub-sections
              setTabsWholeData((prevData) => {
                const existingSubSection = prevData?.sub_section_fields?.find(
                  (sub) => Object.keys(sub)[0] === item.field_key
                );
                if (!existingSubSection) {
                  const fieldKeyJson = item.field_key + "_json"; // Create dynamic key like `fieldKey_json`

                  return {
                    ...prevData,
                    sub_section_fields: [
                      ...prevData.sub_section_fields,
                      {
                        [item.field_key]: value, // Existing field key with value
                        [fieldKeyJson]: { [item.field_key]: value }, // Dynamic key with value as an object
                      },
                    ],
                  };
                }
                return prevData;
              });
            } else {
              // Populate tabsWholeData for section fields
              setTabsWholeData((prevData) => ({
                ...prevData,
                section_fields: {
                  ...prevData.section_fields,
                  [item.field_key]: value,
                },
              }));
            }
          });
        }
      }
    } catch (err) {
      console.error("Error fetching section form data:", err);
    }
  };

  const handleInputChange = (e, field, sectionId, value) => {
    const name = e.target.name;
    const isRequired = field?.required === "1";

    setFormValues({
      ...formValues,
      [sectionId]: {
        ...formValues[sectionId],
        [name]: value,
      },
    });
    
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isRequired && !value ? `${field?.name_en} is required` : null, // Include field name in the error message
    }));

    setSectionFieldsData({
      ...sectionFieldsData,
      [name]: value,
    });

    setTabsWholeData(prevData => {
      if (field.sub_section_id) {
        const fieldKeyJson = field.field_key + "_json"; // Create dynamic key

        return {
          ...prevData,
          sub_section_fields: prevData.sub_section_fields.map((subsection) =>
            subsection.sub_section_id === field.sub_section_id
              ? {
                  ...subsection,
                  [fieldKeyJson]: {
                    ...subsection[fieldKeyJson],
                    [name]: value,
                  },
                }
              : subsection
          ),
        };
      } else {
        return {
          ...prevData,
          section_fields: { ...prevData.section_fields, [name]: value }
        };
      }
    });

  };

  const handleTabChange = async (tab) => {
    setTabActive(tab);
    fetchSectionData(tab);
    // console.log(tab?.id)
    staticsTab(tab)
  };


  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const newErrors = {}; 
  
    Object.keys(sectionFormData).forEach((sectionId) => {
      sectionFormData[sectionId].forEach((field) => {
        const value = formValues[sectionId]?.[field.field_key] || ""; // Get the value of the field
        const isRequired = field.required === "1"; // Check if the field is required
  
        // If required and the value is empty, set an error message
        if (isRequired && !value) {
          newErrors[field?.field_key] = `${field?.name_en} is required`;
        } else {
          newErrors[field?.field_key] = null; // Clear any existing error if field is filled
        }
      });
    });

    tabsWholeData.sub_section_fields.forEach((section, sectionIndex) => {
      const sectionName = Object.keys(section)[0]; // Dynamic field key name
      const sectionKeyJson = sectionName + "_json"; // Create the dynamic JSON key

      if (section[sectionKeyJson] && Array.isArray(section[sectionKeyJson])) {
        section[sectionKeyJson].forEach((item, itemIndex) => {
          Object.keys(item).forEach((key) => {
            if (item[key] === null || item[key].trim() === "") {
              // Check if value is null or empty
              newErrors[
                `json_${sectionName}_${itemIndex}_${key}`
              ] = `${key} is required`;
            }
          });
        });
      }
    });
  
    // Update the state with new errors
    setErrors(newErrors);
  
    // Check if there are any errors before proceeding
    const hasErrors = Object.values(newErrors).some(error => error !== null);
    if (hasErrors) {
      console.log("Validation Errors:", newErrors);
      return; // Stop submission if there are validation errors
    }

    // Find the index of the current active tab in the combined array
    const currentIndex = combinedTabsData.findIndex(
      (tab) => tab.id === tabActive.id
    );
    const nextTab = combinedTabsData[currentIndex + 1];
    staticsTab(nextTab)

   
    const application_data = {
      "category_id": tabsData[0].category_id,
      ...tabsWholeData
    };

    const mergedSubSectionFields = tabsWholeData?.sub_section_fields?.reduce(
      (acc, current) => {
        return { ...acc, ...current };
      },
      {}
    );

    const prefixedSectionFields = Object.fromEntries(
      Object.entries(tabsWholeData.section_fields).map(([key, value]) => [
        `f_${key}`,
        value,
      ])
    );

    // Prefix 'f_' to keys in mergedSubSectionFields
    const prefixedMergedSubSectionFields = Object.fromEntries(
      Object.entries(mergedSubSectionFields).map(([key, value]) => [
        `f_${key}`,
        value,
      ])
    );

    console.log("tabsWholeData", JSON.stringify(application_data, null, 2));

    cretaeApplicationFormData(
      prefixedSectionFields,
      prefixedMergedSubSectionFields
    );

    if (nextTab) {
      setTabActive(nextTab);
      fetchSectionData(nextTab);
    } else {
      console.log("Reached the last tab");
    }
  };
  const goBack = () => {
    const currentIndex = combinedTabsData.findIndex((tab) => tab.id === tabActive.id);
    const prevTab = combinedTabsData[currentIndex - 1];

    if (prevTab) {
      setTabActive(prevTab);
      fetchSectionData(prevTab);
    } else {
      console.log("Reached the last tab");
    }
  }

  const staticsTab = (nextTab) =>{    // to show ststic active tabs content
    if (nextTab?.id == 'doc-upload') {
      setIsDocumentTab(true)
    } else {
      setIsDocumentTab(false)
    }
    if(nextTab?.id === 'make-payment'){
      setIsPaymentTab(true);
    } else {
      setIsPaymentTab(false)
    }
    if (nextTab?.name_en == combinedTabsData[combinedTabsData.length - 1]?.name_en) {
      setIslastTab(true)
    } else {
      setIslastTab(false)
    }
    if (nextTab?.name_en == combinedTabsData[0]?.name_en) {
      setIsFirstTab(true)
    } else {
      setIsFirstTab(false)
    }

    if (nextTab?.id == 'geo-location') {

      setIsLocationTab(true)
    } else {
      setIsLocationTab(false)
    }
    if (nextTab?.id == "emp-details") {
      setIsEmpDetailsTab(true);
    } else {
      setIsEmpDetailsTab(false);
    }

  }
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "आवेदन फार्म" : "Application Form"}
      head={language ? "आवेदन फार्म" : "Application Form "}
    >
      <div className="aod-outer">
        {tabsData?.length > 0 ? <div className="aod-inner">
          <div className="aod-top justify-content-start">
            {combinedTabsData?.filter((tab) => (tab.id !== 'emp-details' || showEmpDetailsTab))
              .sort((a, b) => new Date(a.id) - new Date(b.id))
              .map((tab) => (
                <nav key={tab.id} className="nav nav-pills nav-fill">
                  <a
                    className={tabActive?.id === tab?.id ? "green" : "white"}
                    onClick={() => handleTabChange(tab)}
                  >
                    {language ? tab?.name_hi : firstCharUpperCase(tab?.name_en)}
                  </a>
                </nav>
              ))}
          </div>
        </div> : <></>}

        <div className="aod-bottom">
          {tabsData?.length > 0 ? sectionData
            ?.sort((a, b) => new Date(a?.id) - new Date(b?.id))
            .map((section) => (
              <div key={section.id}>
                <div
                  className="m-wrapper-heading"
                  style={{
                    backgroundColor: section?.background_color,
                    color: section?.font_color,
                  }}
                >
                  <div style={{ fontSize: `${section?.font_size}px` }}>
                    {language ? section?.name_hi : firstCharUpperCase(section?.name_en)}
                  </div>
                </div>

                <div className="m-form-wrapper">
                  <form>
                    <div className="row">
                      {sectionFormData[section?.id]?.map((field) => (
                        <div
                          className={
                            inputTypeList?.find((x) => x?.value == field?.type)
                              ?.label == "Designation Table" ||
                              field?.sub_section_id != undefined ||
                              field?.sub_section_id != null
                                ? "col-lg-12"
                                : "col-md-4"
                            }
                            key={field.id}
                          >
                            <div className="mb-3 f_fields">
                              {inputTypeList?.find(
                                (x) => x.value == field?.type
                              )?.label != "Designation Table" ? (
                                <label
                                  htmlFor={field.field_key}
                                  className="form-label"
                                >
                                  <span
                                    className={`field-label${
                                      field?.required === "1" ? " required" : ""
                                    }`}
                                    style={{
                                      color: field?.font_color,
                                      fontSize: `${field?.font_size}px`,
                                      backgroundColor: field?.background_color,
                                    }}
                                  >
                                    {language ? field?.name_hi : field?.name_en}
                                  </span>
                                </label>
                              ) : null}

                              {field?.sub_section_id ? (
                                <SubSection
                                  fieldData={field}
                                  handleInputChange={handleInputChange}
                                  inputTypeList={inputTypeList}
                                  sectionId={field?.section_id}
                                  language={language} // or true for Hindi
                                  formValues={formValues}
                                  setFormValues={setFormValues}
                                  subSectionFieldKey={field.field_key}
                                  setTabsWholeData={setTabsWholeData}
                                  subSectionId={field?.sub_section_id}
                                  errors={errors}
                                  setErrors={setErrors}
                                  applicationFromData={applicationFromData}
                                  // subSectionsData={/* Your sub-section data */}
                                />
                              ) : ["text", "checkbox", "password"].includes(
                                  inputTypeList?.find(
                                    (x) => x.value == field?.type
                                  )?.label
                                ) ? (
                                <div key={field.field_key}>
                                  <InputTypeText
                                    key={field.field_key}
                                    type={
                                      inputTypeList?.find(
                                        (x) => x.value == field?.type
                                      )?.label
                                    }
                                    placeholder={
                                      language
                                        ? field?.placeholder_hi
                                        : field?.placeholder_en
                                    }
                                    id={field.field_key}
                                    name={field.field_key}
                                    value={
                                      formValues[section.id]?.[
                                        field.field_key
                                      ] || ""
                                    } // Controlled value
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        field,
                                        section.id,
                                        e?.target?.value
                                      )
                                    } // Change handler
                                  />
                                  {errors[field.field_key] && (
                                    <span className="validate-error">
                                      {errors[field.field_key]}
                                    </span>
                                  )}
                                </div>
                              ) : inputTypeList?.find(
                                  (x) => x.value == field?.type
                                )?.label === "dropdown" ? (
                                <div key={field.field_key}>
                                  <SelectType
                                    key={field.field_key}
                                    dropdownId={field?.dropdown_id}
                                    fieldKey={field?.field_key}
                                    value={
                                      formValues[section.id]?.[
                                        field.field_key
                                      ] || ""
                                    } // Pass current value
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        field,
                                        section.id,
                                        e?.target?.value?.value
                                      )
                                    } // Change handler
                                    placeholder={
                                      language
                                        ? "विकल्प चुनें"
                                        : "Select Option"
                                    }
                                    styles={customStylesAccUser}
                                    theme={selectThemeUser}
                                  />
                                  {errors[field.field_key] && (
                                    <span className="validate-error">{errors[field.field_key]}</span>
                                  )}
                                </div>
                              ) : inputTypeList?.find(
                                  (x) => x.value == field?.type
                                )?.label === "Designation Table" ? (
                                <TableTab
                                  fieldData={field}
                                  inputTypeList={inputTypeList}
                                  subCatId={field?.category_id}
                                  language={false} // or true for Hindi
                                  formValues={formValues[section.id]?.[field.field_key] || []}
                                 onChange={(e) => handleInputChange(e, field, section.id,e?.target?.value)} // Change handler
                                //  addTab={additionalDetailsTab}
                                />
                              ) : null}
                          </div>
                        </div>
                      ))}

                    </div>

                  </form>


                </div>
              </div>
            )) :
            <><div className="text-center">{language ? "इस श्रेणी के लिए कोई आवेदन पत्र उपलब्ध नहीं है" : "No Application form is available for this category"}</div></>}
          {
            isLocationTab &&
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div
                    className="col-lg-12">
                    <div className="mb-3 f_fields">
                        <Geolocation initialAddress={userDetail?.address}
                        subCatId={subcategoryId} />

                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
          {isEmpDetailsTab && (
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <EmpDetails
                        subCatId={subcategoryId}
                        language={language}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
          {isDocumnetTab && (
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div
                    className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <DocsUpload subCatId={subcategoryId} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
)}
            {
            isPaymentTab &&
            <div className="m-form-wrapper">
              <form>
                <div className="row">
                  <div
                    className="col-lg-12">
                    <div className="mb-3 f_fields">
                      <Payment subCatId={subcategoryId} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
          {!isLastTab && tabsData?.length > 0 && (
            <button
              type="submit"
              className="btn btn-primary user-theme-btn mt-3 "
              onClick={handleFormSubmit}
            >
              Next
            </button>
          )}
        </div>

      </div>
    </Dashboard>
  );
};

export default OrganizationDetails;