import { useNavigate } from "react-router-dom";
import AdminDashboard from "../../pages/Admin/AdminDashboard/AdminDashboard"
import { HomeIcon } from "../Application/ApplicationIcon"
import "../viewData/viewData.css"

const ViewData = () => {
    const navigate = useNavigate();
    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span> View Details</span>

                    </div>
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="row">
                            <div class="detail_card_header ps-0 col-md-12">
                                <h5 class="mb-0"><strong>View Details</strong></h5>
                            </div>
                            <div class="aod-head mb-4"></div>
                            <div class="col-md-2 ps-0">

                                <div class=" mt-3 view-detail-card-c user_card_h">
                                    <div class="view-detail-c">
                                        <img class="mb-4" src="https://utdbhomestayapi.dilonline.in/assets/backend/img/dummy_user.svg" alt="" srcset="" />

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-10 col-sm-12 mt-0">


                                <div class="row g-2 mt-2">
                                    <div class="col-md-6 detail_card_desc left-card-desc mt-0">
                                        <p class="mb-0">English Name</p>
                                        <div className="view-card-d">
                                            <small>test</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 detail_card_desc left-card-desc mt-0">
                                        <p class="mb-0">Hindi Name</p>
                                        <div className="view-card-d">
                                            <small>test mail</small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 detail_card_desc left-card-desc mt-0">
                                        <p class="mb-0"> Description </p>
                                        <div className="view-card-d">
                                            <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea</small>
                                        </div>
                                    </div>

                                    <div class="col-md-6 detail_card_desc left-card-desc mt-0">
                                        <p class="mb-0">Status </p>
                                        <div className="view-card-d">
                                            <span class="badge rounded-pill text-bg-success">Active</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AdminDashboard >
    )

}

export default ViewData