import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { Message } from '../../../components/message'
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../components/Application/ApplicationIcon';
import { Modal, Table } from 'react-bootstrap';
import sortByIcon from '../../../assets/images/sort.png';
import { allAdminUserDataSort, allAdminUserList, deleteAdminUserData, getEnableDisableAdminUserData } from '../../../services/api/admin';
import { DeleteIcon, EditIcon, PermissionIcon } from '../AdminIcon';
import ReactPaginate from 'react-paginate';
import { HiPlus } from 'react-icons/hi';
import { formatDateWithSlash } from '../../../Utils/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkPermission , permissionsConfig } from '../AdminPermission/PermissionChecker';

const AdminUsers = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
    const [userList, setuserList] = useState([])
    const [orderBy, setOrderBy] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteData, setDeleteData] = useState();
    const [totalPages, setTotalPages] = useState(0); // Total number of pages
    const [searchTerm, setSearchTerm] = useState()
    useEffect(() => {
        if (state && state.message) {
            setApiMessage({ type: 'success', message: state.message });
            // Clear the message once it is rendered
            window.history.replaceState({}, document.title);
        }
    }, [state])

    const grantPermissionForAddAdminUser = checkPermission(permissionsConfig.addAdminUser.role, permissionsConfig.addAdminUser.action, permissionsConfig.addAdminUser.type);
    const grantPermissionForEditAdminUser = checkPermission(permissionsConfig.editAdminUser.role, permissionsConfig.editAdminUser.action, permissionsConfig.editAdminUser.type);
  

    useEffect(() => {
        getAdminUserList(currentPage)
    }, [])

    const getAdminUserList = async (pageNo) => {
        try {
            const data = await allAdminUserList(pageNo)
            if (data.status == 200) {
                setuserList(data?.data?.data)
                setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page)); // Calculate total pages
            }
        } catch (err) {
            setApiMessage({ type: 'err', message: err.message });
        }
    }

    const handleEnableDisable = async (e, item) => {
        try {
            const formData = new FormData();
            formData.append("status", e?.value)
            await getEnableDisableAdminUserData(formData, item.id)
            getAdminUserList(currentPage)
        } catch (err) {
            setApiMessage({ type: 'err', message: err.message });
        }
    }

    const searchSortFromList = async (e, sortBy) => {
        try {
            const searchBy = e.target.value ? e.target.value : '';
            setOrderBy(orderBy == 'asc' ? 'desc' : 'asc')
            const tabList = await allAdminUserDataSort(sortBy, orderBy, searchBy)
            const data = tabList?.data?.data
            setuserList(data)
        } catch (err) {
            console.log("error on categoryList: ", err)
            setApiMessage({ type: 'err', message: err.message });
        }
    }

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        getAdminUserList(selectedPage.selected + 1); // Increment by 1 if the API starts page numbers from 1
    };

    const deleteCategory = async (obj) => {
        try {
            const data = await deleteAdminUserData(obj.id)
            if (data?.status == 200) {
                getAdminUserList(currentPage)
                setDeleteData("");
                setApiMessage({ type: 'success', message: data.message });
            } else {
                setDeleteData("");
                setApiMessage({ type: 'err', message: data.message });
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>Admin Users </span>

                    </div>
                    {(grantPermissionForAddAdminUser) ? (
                        <button className='adm-tab-btn' onClick={() => navigate("/admin/admin-users/add")}><span><HiPlus />
                        </span> Add</button>
                    ) : ''}
                </div>
                <div className="aod-inner pt-0">
                    <div className="aod-bottom">
                        <div className="adm-tabs-outer d-flex justify-content-between">
                            <h4 className='mb-0'>Admin Users </h4>
                            {/* <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => searchSortFromList(e, 'name')} /> */}
                            <div className="position-relative w-25">
                                <input
                                    className="px-2 form-control"
                                    placeholder="Search"
                                    type="text"
                                    value={searchTerm} // Add a state for the input value
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value); // Update the state
                                        searchSortFromList(e, 'name')
                                    }}
                                />
                                {searchTerm && ( // Show the clear button only when there's text
                                    <button
                                        className="btn btn-clear position-absolute"
                                        onClick={(e) => {
                                            setSearchTerm(""); // Clear the input value
                                            searchSortFromList(e, 'name') // Fetch data with empty search term
                                        }}
                                        style={{
                                            top: "50%",
                                            right: "10px",
                                            transform: "translateY(-50%)",
                                            border: "none",
                                            background: "transparent",
                                            fontSize: "1rem",
                                            cursor: "pointer",
                                        }}
                                    >
                                        &times; {/* This is the "X" icon */}
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="aod-head"></div>
                        <div className="ad-reg-table">
                            <Table size="sm" className='ad-cat-table mt-4'>
                                <thead>
                                    <tr>
                                        <th className='ad-sno'>S. No</th>
                                        <th className='ad-long cursor-pointer' onClick={(e) => searchSortFromList(e, 'name')}>Name <span><i className="fa-solid fa-sort"></i></span></th>
                                        <th className='ad-long cursor-pointer' onClick={(e) => searchSortFromList(e, 'created_at')}>Created At <span><i className="fa-solid fa-sort"></i></span></th>
                                        {(grantPermissionForEditAdminUser) ? (<>
                                            <th className='ad-long'>Status</th>
                                            <th className='ad-long' style={{ paddingLeft: '30px' }}>Actions</th>
                                        </>) : ''}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userList?.length > 0 ? userList?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.name ? item?.name : '--'}</td>
                                                <td>{item?.created_at ? formatDateWithSlash(item?.created_at) : '--'}</td>
                                                {(grantPermissionForEditAdminUser) ? (<>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={item?.status == 1}
                                                                onChange={(e) => handleEnableDisable(e.target.checked ? { value: "1" } : { value: "0" }, item)}
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div className='icon-col'>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`edit-tooltip-${item.id}`}>Edit</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-edit"
                                                                    onClick={() => { navigate("/admin/admin-users/update", { state: item }) }}
                                                                >
                                                                    <EditIcon />
                                                                </span>
                                                            </OverlayTrigger>

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`delete-tooltip-${item.id}`}>Delete</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-delete"
                                                                    onClick={() => setDeleteData(item)}
                                                                >
                                                                    <DeleteIcon />
                                                                </span>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`permission-tooltip-${item.id}`}>Permissions</Tooltip>}
                                                            >
                                                                <span
                                                                    className="ad-cat-permission"
                                                                    onClick={() => {
                                                                        navigate("/admin/admin-users/user-permissions/edit", { state: item })
                                                                    }}>
                                                                    <PermissionIcon />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                </>) : ''}
                                            </tr>
                                        )) : ''
                                    }
                                </tbody>
                            </Table>
                            {totalPages > 1 && userList?.length > 0 &&
                                <div className="d-flex w-100 justify-content-end">
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={totalPages} // Total number of pages from API
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={handlePageChange} // Method to handle page click
                                        containerClassName={"pagination justify-content-center"} // Custom CSS classes
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you really want to delete this User ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div className="suf-btn p-2 d-flex flex-row">
                        <button className='mx-2' onClick={() => { deleteCategory(deleteData) }}>Yes</button>
                        <button className='suf-can-butt mx-2' onClick={() => setDeleteData("")}>No</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </AdminDashboard>
    )
}

export default AdminUsers
