import React from "react";

const NotFoundTable = ({colSpan}) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div
          className="d-flex justify-content-center align-items-center py-3"
        >
          <span className="text-center fs-5">No Records Found</span>
        </div>
      </td>
    </tr>
  );
};

export default NotFoundTable;
