import React, { useEffect, useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useLocation, useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { addRolesData, updateRolesData } from '../../../services/api/admin';
import { Message } from '../../../components/message';

const AddRoles = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [roles, setRoles] = useState('')
    const [rolesErr, setRolesErr] = useState('')
    const [editData, setEditData] = useState()
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });

    useEffect(() => {
        if (location?.state && location?.pathname == "/admin/roles/update") {
            editRoles(location?.state)
        }
    }, [location?.state])

    const handleKeyUpLoginDetails = (item) => {
        validateForm(item);
    };

    const validateForm = (item) => {
        let isValid = true;
        if (item === "roles") {
            if (!roles || roles.trim() === "") {
                setRolesErr("Please Enter Name");
                isValid = false;
            } else {
                setRolesErr("");
            }
        }
        return isValid;
    }

    const addRoles = async () => {
        const isValid = validateForm("roles")
        if (!isValid) {
            setRolesErr("Please Enter Name");
            return;
        }
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("title", roles)
            const data = await addRolesData(formData)
            if (data?.status == 200) {
                setRoles('')
                navigate("/admin/roles", { state: { message: data.message } })
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error.message });
        }
    }

    const updateRoles = async () => {
        if (!validateForm("both")) return
        try {
            const formData = new FormData();
            formData.append("title", roles)
            formData.append("role_id", editData?.id)
            const data = await updateRolesData(formData)
            if (data?.status == 200) {
                setRoles('')
                navigate("/admin/roles", { state: { message: data.message } })
            } else {
                setApiMessage({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error(error);
            setApiMessage({ type: 'error', message: error.message });
        }
    }

    const handleRoleInputChange = (e) => {
        const inputValue = e.target.value;
        const validInput = /^[A-Za-z ]*$/; // Only allow English letters
        if (inputValue.length > 30) { // Limit input length to 30 characters
            setRoles(inputValue.slice(0, 30)); // Truncate to 30 characters
            return;
        }
        if (!validInput.test(inputValue)) {
            setRoles(inputValue.replace(/[^A-Za-z]/g, ''));
        } else {
            setRoles(inputValue);
            setRolesErr("");
        }
    }

    //edit sub-category
    const editRoles = (item) => {
        setEditData(item)
        setRoles(item?.title);
        window.scrollTo(0, 0);
    }



    return (
        <AdminDashboard>
            <div className='aod-outer'>
                {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage}></Message>)}
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon /><b onClick={() => navigate("/admin/")}>Home  /</b>   <span>Roles/Add</span>

                    </div>
                </div>
                <div className="aod-inner">
                    <div className={editData?.id ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head"> <button onClick={() => navigate("/admin/roles")}><BsChevronLeft /> Back</button>{editData?.id ? "Update" : "Add"} Role</div>
                        <div className="row py-4">
                            <div className="col-md-4 col-lg-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'>
                                        Name
                                    </label>
                                    <input maxLength={15} className='w-100' placeholder='Enter Name' type="text"
                                        onKeyUp={() => handleKeyUpLoginDetails("roles")}
                                        value={roles}
                                        onChange={(e) => { handleRoleInputChange(e) }}
                                    />
                                    {rolesErr && <p className='validate-error'>{rolesErr}</p>}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12">
                                <div className="role_b ad-cat-row">
                                    {
                                        editData?.id ?
                                            <>
                                                <div className="">
                                                    {/* <button onClick={() => { setRoles(''); navigate("/admin/roles") }} className='suf-can-butt w-auto px-4'>
                                                        Cancel
                                                    </button> */}
                                                    <button onClick={() => updateRoles()} className='suf-can-butt w-auto px-4'>
                                                        Update
                                                    </button> </div></> :
                                            <div className="">
                                                <button onClick={addRoles}>
                                                    Submit
                                                </button></div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default AddRoles
