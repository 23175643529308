import React, { useEffect, useState } from "react";
import "../Home/Home.css"
import { ClearLocalStorage } from "../../Utils/Utils";
import MainLayout from "../MainLayout/MainLayout";
import { getHomePageContent } from "../../services/api/outer";

const PermissionDenied = () => {
    const [homePageData, setHomePageData] = useState(null);
    const [loading, setLoading] = useState(true);
    useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [homePageDataResponse] =
                    await Promise.all([
                        getHomePageContent(),
                    ]);
                setHomePageData(homePageDataResponse);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data", error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        ClearLocalStorage();
    }, []);

    if (
        loading ||
        homePageData === null
    ) {
        return <></>;
    }

    return (
        <div>
            <MainLayout homePageData={homePageData}>
                <div class="not-found-section">
                    <h1 class="error">403</h1>
                    <div class="page-heading">You don't have permission to view this page</div>
                    <a class="back-home" href="/">Back to home</a>
                </div>
            </MainLayout>
        </div>
    );
}

export default PermissionDenied
