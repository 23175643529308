import React, { useEffect, useState } from 'react';
import InputTypeText from './inputTypeText';
import SelectType from './SelectType';
import { customStylesAcc, selectTheme } from '../../Utils/common';
import { subSectionData } from '../../services/api/user';

const SubSection = ({
    fieldData,
    handleInputChange,
    inputTypeList,
    sectionId,
    language,
    formValues,
    subSectionFieldKey,
    setTabsWholeData,
    subSectionId,
    errors,
    setErrors,
    setFormValues,
    applicationFromData
}) => {
    const [inputValue, setInputValue] = useState('1');
    const [parsedJsonValue, setParsedJsonValue] = useState([]);
    const [sectionFormData, setSectionFormData] = useState([])
    useEffect(() => {
        setInputValue(formValues[sectionId]?.[fieldData?.field_key] || "")   //prepopulating the input field for the formarray
        const fetchOptions = async () => {
            try {
                const formData = new FormData()
                formData.append("sub_section_id", subSectionId)

                const response = await subSectionData(formData)

                setSectionFormData(response?.data)
                console.log('response?.data', response?.data)

                const parsedValue = JSON.parse(response?.data?.json_value)?.items || [];


                // setTabsWholeData(prevData => {
                //     const updatedSections = prevData.sub_section_fields?.map(section => {
                //         if (section.hasOwnProperty(subSectionFieldKey)) {
                //             const applicationDataKey = `f_${subSectionFieldKey}_json`;
                //             const jsonDataFromAppData = applicationFromData?.[applicationDataKey] || []; // Use empty array as fallback if data is missing
                
                //             // Map through json data from application data, and ensure it's correctly structured
                //             const updatedJson = section[`${subSectionFieldKey}_json`]?.map((item, index) => {
                //                 const appDataItem = jsonDataFromAppData[index];
                                
                //                 // If corresponding data exists in applicationFromData, use it; otherwise, retain the current item or set default values
                //                 return appDataItem
                //                     ? {
                //                         ...item, // Preserve existing values if they exist
                //                         ...appDataItem // Overwrite with values from applicationFromData
                //                     }
                //                     : item;
                //             });
                
                //             return {
                //                 ...section,
                //                 [`${subSectionFieldKey}_json`]: updatedJson, // Update section with new JSON array
                //             };
                //         }
                //         return section;
                //     });
                
                //     return {
                //         ...prevData,
                //         sub_section_fields: updatedSections,
                //     };
                // });
                // const applicationDataKey = `f_${subSectionFieldKey}_json`;
                // const jsonDataFromAppData = applicationFromData?.[applicationDataKey];
                
                // if(jsonDataFromAppData) {
                //     jsonDataFromAppData.map((item, index) => {
                //         Object.keys(item).forEach((field) => {
                //             setFormValues(prevState => ({
                //                 ...prevState,
                //                 [sectionId]: {
                //                     ...prevState[sectionId],
                //                     [`${subSectionFieldKey}_json`] : {
                //                         [index]: {
                //                             ...prevState[sectionId]?.[index],
                //                             [field]: item[field],
                //                         }
                //                     }
                //                 }
                //             }));
    
                //             setTabsWholeData(prevData => ({
                //                 ...prevData,
                //                 [sectionId]: {
                //                     ...prevData[sectionId],
                //                     [`${subSectionFieldKey}_json`] : {
                //                         [index]: {
                //                             ...prevData[sectionId]?.[index],
                //                             [field]: item[field],
                //                         }
                //                     }
                //                 }
                //             }));
                //         });
                //     });
                // } else {
                //     parsedValue.map((item, index) => {
                //         setFormValues(prevState => ({
                //             ...prevState,
                //             [sectionId]: {
                //                 ...prevState[sectionId],
                //                 [index]: {
                //                     ...prevState[sectionId]?.[index],
                //                     [item.name_en]: null,
                //                 }
                //             }
                //         }));

                //         setTabsWholeData(prevData => ({
                //             ...prevData,
                //             [sectionId]: {
                //                 ...prevData[sectionId],
                //                 [index]: {
                //                     ...prevData[sectionId]?.[index],
                //                     [item.name_en]: null,
                //                 }
                //             }
                //         }));    
                //     });
                // }

                setTabsWholeData(prevData => {
                    const updatedSubSections = prevData.sub_section_fields.map(section => {
                      if (section[subSectionFieldKey] !== undefined) {
                        return {
                          ...section,
                          [`${subSectionFieldKey}_json`]: Array(inputValue).fill().map(() => {
                            // Create an object with each field in parsedValue set to null
                            return parsedValue.reduce((acc, item) => {
                              const fieldKeyJson = `${item.name_en}`;  // Using `field_key_json` format
                              acc[fieldKeyJson] = null;
                              return acc;
                            }, {});
                          })
                        };
                      }
                      return section;
                    });
              
                    return {
                      ...prevData,
                      sub_section_fields: updatedSubSections,
                    };
                });


                setParsedJsonValue(parsedValue);

            } catch (error) {
                console.error("Error fetching dropdown options:", error);
            }
        };

        fetchOptions();
    }, [fieldData]);

    const handleInputValueChange = (e) => {
        const { value } = e.target;
        const newValue = parseInt(e.target.value);
        if (value === '' || (/^\d*$/.test(value) && Number(value) <= 20)) {
            setInputValue(value);

            handleInputChange(e, fieldData, sectionId, e?.target?.value); // Call parent handler if needed

            setTabsWholeData(prevData => {
                const updatedSections = prevData.sub_section_fields.map(section => {
                    if (section.hasOwnProperty(subSectionFieldKey)) {
                        let updatedJsonArray = section[`${subSectionFieldKey}_json`] || []; // Access the correct json key
                        
                        if (newValue > updatedJsonArray.length) {
                            // Add new objects if `newValue` is greater than the current length
                            const itemsToAdd = newValue - updatedJsonArray.length;
                            const additionalItems = Array(itemsToAdd).fill(null).map(() => {
                                // Initialize each new object with string keys
                                return parsedJsonValue.reduce((acc, item) => {
                                    acc[`${item.name_en}`] = null; // Use the correct key format
                                    return acc;
                                }, {});
                            });
                            updatedJsonArray = [...updatedJsonArray, ...additionalItems];
                        } else if (newValue < updatedJsonArray.length) {
                            // Trim the array if `newValue` is smaller than the current length
                            updatedJsonArray = updatedJsonArray.slice(0, newValue);
                        }
            
                        return {
                            ...section,
                            [subSectionFieldKey]: newValue,
                            [`${subSectionFieldKey}_json`]: updatedJsonArray, // Update with the correct key
                        };
                    }
                    return section;
                });
            
                return {
                    ...prevData,
                    sub_section_fields: updatedSections,
                };
            });
        }
    };

    // Function to handle change in each form array item
    const handleFormArrayChange = (e, index, fieldKey) => {
        const { value } = e.target;
        console.log('e', e)
        setFormValues(prevState => ({
            ...prevState,
            [sectionId]: {
                ...prevState[sectionId],
                [index]: {
                    ...prevState[sectionId]?.[index],
                    [fieldKey]: value,
                }
            }
        }));

        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
    
            if (!value) {
                newErrors[`json_${fieldData.field_key}_${index}_${fieldKey}`] = `${fieldKey} is required`;
            } else {
                // Clear the error if the input is filled
                delete newErrors[`json_${fieldData.field_key}_${index}_${fieldKey}`];
            }
    
            return newErrors;
        });

        setTabsWholeData(prevData => {
            const updatedSections = prevData.sub_section_fields.map(section => {
                if (section.hasOwnProperty(subSectionFieldKey)) {
                    const updatedJson = [...section[`${subSectionFieldKey}_json`]]; // Access the correct json array
                    
                    if (updatedJson[index]) {
                        // Update the specific field in the json object at the correct index
                        updatedJson[index] = {
                            ...updatedJson[index],
                            [fieldKey]: value,
                        };
                    }
        
                    return {
                        ...section,
                        [`${subSectionFieldKey}_json`]: updatedJson, // Update with the correct key
                    };
                }
                return section;
            });
        
            return {
                ...prevData,
                sub_section_fields: updatedSections,
            };
        });

    };

    // Dynamically determine the number of form arrays
    const formArrayLength = Number(inputValue) > 0 ? Number(inputValue) : 0;

    return (
        <div className="row partner-subsection">
            <div className="col-md-12">
                <div className="col-md-4 mb-4">
                    <InputTypeText
                        id={fieldData.field_key}
                        name={fieldData.field_key}
                        value={inputValue} // Controlled value
                        onChange={handleInputValueChange} // Change handler for main input
                        placeholder={language ? fieldData.placeHolder_hi : fieldData.placeHolder_en} // Placeholder based on language
                    />
                    {errors[fieldData.field_key] && (
                        <span className="validate-error">{errors[fieldData.field_key]}</span>
                    )}
                </div>
                {formArrayLength > 0 && (
                    <div className="form-array-section col-md-12">
                        {[...Array(formArrayLength)].map((_, index) => (
                            <div key={index} className="form-array-item">
                                <h5>{language ? sectionFormData.name_hi : sectionFormData.name_en} {index + 1}</h5> {/* Display index numbering */}
                                <div className="row">
                                    {parsedJsonValue.map((item, idx) => (
                                        <div className="col-md-4" key={idx}>
                                            <div className="mb-3 f_fields">
                                                <label
                                                    htmlFor={item.name_en}
                                                    style={{
                                                        color: item?.font_color,
                                                        fontSize: `${item?.font_size}px`,
                                                        backgroundColor: item?.background_color,
                                                    }}
                                                    className="form-label"
                                                >
                                                    {language ? item.name_hi : item.name_en}
                                                </label>

                                                {/* Controlled input field for each form array item */}
                                                <InputTypeText
                                                    id={item.name_en}
                                                    name={item.name_en}
                                                    value={formValues[sectionId]?.[index]?.[item.name_en] || ''}
                                                    onChange={(e) =>
                                                        handleFormArrayChange(e, index, item.name_en)
                                                    } // Change handler for form array fields
                                                    placeholder={language ? item.placeHolder_hi : item.placeHolder_en}
                                                />

                                                {errors[`json_${fieldData.field_key}_${index}_${item.name_en}`] && (
                                                    <div className="validate-error">
                                                        {errors[`json_${fieldData.field_key}_${index}_${item.name_en}`]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubSection;




