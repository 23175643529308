import React, { useEffect, useState } from 'react';
import Select from 'react-select'; // Ensure you have installed react-select
import { dropDownsList, staticDropDownsList } from '../../services/api/user';

const SelectType = ({ value, dropdownId, fieldKey, onChange, placeholder, styles, theme }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const formData = new FormData();
                formData.append("dropdown_id", dropdownId);
                const response = await dropDownsList(formData);
                const ParsedDropdownValue = JSON.parse(response?.data?.dropdown_values);
                const formattedOptions = ParsedDropdownValue?.items?.map((option, index) => ({
                    value: String(option?.id ? option?.id : `${index + 1}`), // Concatenate id with index
                    label: option.name_en
                }));
                setOptions(formattedOptions);

                if (!response?.data?.dropdown_values || response?.data?.dropdown_values == "null") {
                    const staticFormData = new FormData();
                    staticFormData.append("title", response?.data?.name_en.toLowerCase());
                    const staticList = await staticDropDownsList(staticFormData);
                    const formattedStaticOptions = staticList?.data?.map(option => ({
                        value: String(option.id),
                        label: option.name_en
                    }));
                    setOptions(formattedStaticOptions);
                }
            } catch (error) {
                //console.error("Error fetching dropdown options:", error);
            }
        };

        fetchOptions();
    }, [dropdownId]);

    return (
       <>
            {/* <div>{JSON.stringify(value)}</div> */}
            <Select
                components={{
                    IndicatorSeparator: () => null,
                }}
                styles={styles}
                theme={theme}
                options={options}
                placeholder={placeholder}
                value={options?.find(option => option?.value === value)}
                onChange={(selectedOption) => {
                    onChange({ target: { value: selectedOption, name: fieldKey } });
                }}
            />
       </>
    );
};

export default SelectType;
