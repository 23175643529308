import React, { useEffect, useState } from "react";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import { HiPlus } from "react-icons/hi";
import { HomeIcon } from "../../../components/Application/ApplicationIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import {
  deleteRoleData,
  getEnableDisableRolesData,
  getDocumenListDataSort,
  getDocumentListData,
  getSubCategoriesDropdown,
  getCategoriesDropdown,
  deleteUploadDocumentData,
  handleEnableDisableDocumentData,
} from "../../../services/api/admin";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { DeleteIcon, EditIcon } from "../AdminIcon";
import sortByIcon from "../../../assets/images/sort.png";
import { Message } from "../../../components/message";
import NotFoundTable from "../../../components/notFound/NotFoundTable";
import {
  customStylesAcc,
  formatDateWithSlash,
  selectTheme,
} from "../../../Utils/common";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  checkPermission,
  permissionsConfig,
} from "../AdminPermission/PermissionChecker";

const UploadDocument = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const [searchSubCategory, setSearchSubCategory] = useState();
  const [searchData, setSearchData] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [documenList, setDocumenList] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  const [orderBy, setOrderBy] = useState("desc");
  const [deleteData, setDeleteData] = useState();
  const { state } = useLocation();
  const [apiMessage, setApiMessage] = useState({ type: "", message: "" });

  const grantPermissionForAddDocumentList = checkPermission(
    permissionsConfig.addDocumentList.role,
    permissionsConfig.addDocumentList.action,
    permissionsConfig.addDocumentList.type
  );
  const grantPermissionForEditDocumentList = checkPermission(
    permissionsConfig.editDocumentList.role,
    permissionsConfig.editDocumentList.action,
    permissionsConfig.editDocumentList.type
  );

  useEffect(() => {
    if (state && state.message) {
      setApiMessage({ type: "success", message: state.message });
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  useEffect(() => {
    getSubCategoryList();
    getDocumenData("", sortBy, 1, orderBy, searchData);
  }, []);

  const resetFilter = () => {
    setSearchSubCategory("");
    getDocumenData("", sortBy, 1, orderBy, searchData);
  };

  const getDocumenData = async (
    searchedSubCategory,
    sortBy,
    pageNo,
    orderBy,
    searchBy
  ) => {
    try {
      setSearchData(searchBy);
      setOrderBy(orderBy);
      setSortBy(sortBy);
      const documentList = await getDocumentListData(
        sortBy,
        orderBy,
        searchBy,
        pageNo,
        searchedSubCategory?.value ? searchedSubCategory?.value : ""
      );
      if (documentList?.status == 200) {
        setTotalPages(
          Math.ceil(documentList?.data?.total / documentList?.data?.per_page)
        );
        setCurrentPage(pageNo > 0 ? pageNo - 1 : pageNo);
        const data = documentList?.data?.data;
        setDocumenList(data);
      } else {
        setDocumenList([]);
        setApiMessage({ type: "error", message: documentList?.message });
      }
      setTableDataLoaded(true);
    } catch (error) {
      console.log("error on documentList list: ", error);
    }
  };

  const handleEnableDisable = async (e, item) => {
    try {
      const formData = new FormData();
      formData.append("document_id", item?.id);
      formData.append("status", e?.value);
      const data = await handleEnableDisableDocumentData(formData);
      if (data?.status == 200) {
        getDocumenData(
          searchSubCategory,
          sortBy,
          currentPage,
          orderBy,
          searchData
        );
      } else {
        setApiMessage({ type: "error", message: data?.message });
      }
    } catch (error) {
      setApiMessage({ type: "error", message: error.message });
    }
  };

  const deleteDocumnet = async (item) => {
    try {
      const data = await deleteUploadDocumentData(item?.id);
      if (data.status == 200) {
        setDeleteData("");
        getDocumenData(
          searchSubCategory,
          sortBy,
          currentPage,
          orderBy,
          searchData
        );
        setApiMessage({ type: "success", message: data.message });
      } else {
        setDeleteData("");
      }
    } catch (error) {
      console.error(error);
      setDeleteData("");
      setApiMessage({ type: "error", message: error.message });
    }
  };

  const handlePageChange = (selectedPage) => {
    getDocumenData(
      searchSubCategory,
      sortBy,
      selectedPage.selected + 1,
      orderBy,
      searchData
    ); // Increment by 1 if the API starts page numbers from 1
  };

  const getSubCategoryList = async () => {
    try {
      const subCategoryList = await getSubCategoriesDropdown();
      const data = subCategoryList?.data?.map((item) => ({
        value: item?.id,
        label: item?.category_name,
      }));
      setSubCategoryList(data);
    } catch (err) {
      setApiMessage({ type: "error", message: err?.message });
    }
  };

  return (
    <AdminDashboard>
      <div className="aod-outer">
        {apiMessage && (
          <Message
            apiMessage={apiMessage}
            setApiMessage={setApiMessage}
          ></Message>
        )}
        <div className="ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb">
          <div>
            <HomeIcon />
            <b onClick={() => navigate("/admin/")}>Home /</b>{" "}
            <span> Documents </span>
          </div>
          {grantPermissionForAddDocumentList ? (
            <button
              className="adm-tab-btn"
              onClick={() => navigate("/admin/category/documents/add")}
            >
              <span>
                <HiPlus />
              </span>{" "}
              Add Document{" "}
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="aod-inner pt-0">
          <div className="aod-bottom">
            <div className="adm-tabs-outer d-flex justify-content-between">
              <h4 className="mb-0">Documents </h4>
              {/* <input className='w-25 px-2 form-control' placeholder='search' type="text" onChange={(e) => getDocumenData(searchSubCategory, sortBy, 1, orderBy, e.target.value)} /> */}
              <div className="position-relative w-25">
                <input
                  className="px-2 form-control"
                  placeholder="Search"
                  type="text"
                  value={searchTerm} // Add a state for the input value
                  onChange={(e) => {
                    setSearchTerm(e.target.value); // Update the state
                    getDocumenData(
                      searchSubCategory,
                      sortBy,
                      1,
                      orderBy,
                      e.target.value
                    );
                  }}
                />
                {searchTerm && ( // Show the clear button only when there's text
                  <button
                    className="btn btn-clear position-absolute"
                    onClick={(e) => {
                      setSearchTerm(""); // Clear the input value
                      getDocumenData(
                        searchSubCategory,
                        sortBy,
                        1,
                        orderBy,
                        e.target.value
                      ); // Fetch data with empty search term
                    }}
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "transparent",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    &times; {/* This is the "X" icon */}
                  </button>
                )}
              </div>
            </div>
            <div className="aod-head"></div>
            <div className="row py-4">
              <div className="col-md-4 aod-resp-tab">
                <div className="suf-input-box aod-form-input mb-2">
                  <Select
                    styles={customStylesAcc}
                    theme={selectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="menu_open_select w-100"
                    classNamePrefix="ad-menu_open_select_class"
                    aria-label="Default select example"
                    value={searchSubCategory}
                    options={subCategoryList}
                    onChange={(e) => {
                      setSearchSubCategory(e);
                      getDocumenData(e, sortBy, 1, orderBy, searchData);
                    }}
                    placeholder="Select Sub Category"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <button
                  onClick={resetFilter}
                  className="ad-reset-btn w-auto px-4"
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="ad-reg-table">
              <Table size="sm" className="ad-cat-table">
                <thead>
                  <tr>
                    <th className="ad-sno">S. No</th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getDocumenData(
                          searchSubCategory,
                          "name_en",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      English Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getDocumenData(
                          searchSubCategory,
                          "name_hi",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Hindi Name{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getDocumenData(
                          searchSubCategory,
                          "sub_category_name",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Sub Category{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getDocumenData(
                          searchSubCategory,
                          "size",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Size{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getDocumenData(
                          searchSubCategory,
                          "type",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Type{" "}
                      <span>
                        <i className="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    <th
                      className="ad-long cursor-pointer"
                      onClick={(e) =>
                        getDocumenData(
                          searchSubCategory,
                          "created_at",
                          currentPage + 1,
                          orderBy == "asc" ? "desc" : "asc",
                          searchData
                        )
                      }
                    >
                      Created At{" "}
                      <span>
                        <i class="fa-solid fa-sort"></i>
                      </span>
                    </th>
                    {grantPermissionForEditDocumentList ? (
                      <>
                        <th className="ad-long">Status</th>
                        <th className="ad-long">Actions</th>
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                <tbody>
                  {documenList?.length > 0 ? (
                    documenList?.map((item, index) => (
                      <tr key={index}>
                        <td>{currentPage * 10 + (index + 1)}</td>
                        <td>{item?.name_en || "--"}</td>
                        <td>{item?.name_hi || "--"}</td>
                        <td>
                          {item?.sub_category_name
                            ? item?.sub_category_name
                            : item?.sub_category_name_hi
                            ? item?.sub_category_name_hi
                            : "--"}
                        </td>
                        <td>{item?.size || "--"}</td>
                        <td>{item?.type}</td>
                        <td>
                          {item?.created_at
                            ? formatDateWithSlash(item?.created_at)
                            : "--"}
                        </td>
                        {grantPermissionForEditDocumentList ? (
                          <>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.status == 1}
                                  onChange={(e) =>
                                    handleEnableDisable(
                                      e.target.checked
                                        ? { value: "1" }
                                        : { value: "0" },
                                      item
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                            </td>
                            {/* <td><div className="action-btn d-flex">
                                                    <span className='ad-cat-edit' onClick={() => navigate("/admin/category/documents/update", { state: item })}><EditIcon /></span><span className='ad-cat-delete' onClick={() => setDeleteData(item)}><DeleteIcon /></span>
                                                </div>
                                                </td> */}
                            <td>
                              <div className="action-btn d-flex">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`edit-tooltip-${item.id}`}>
                                      Edit
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-edit"
                                    onClick={() =>
                                      navigate(
                                        "/admin/category/documents/update",
                                        { state: item }
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </span>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`delete-tooltip-${item.id}`}>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="ad-cat-delete"
                                    onClick={() => setDeleteData(item)}
                                  >
                                    <DeleteIcon />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  ) : tableDataLoaded ? (
                    <NotFoundTable colSpan="9"></NotFoundTable>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
              {totalPages > 1 && documenList?.length > 0 && (
                <div className="d-flex w-100 justify-content-end">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={totalPages} // Total number of pages from API
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange} // Method to handle page click
                    forcePage={currentPage}
                    containerClassName={"pagination justify-content-center"} // Custom CSS classes
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={`page-item${
                      !documenList?.length > 0 ? " disabled" : ""
                    }`}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={deleteData?.id} onHide={() => setDeleteData("")}>
        <Modal.Header closeButton>
          <Modal.Title>
            Do you really want to delete this Document ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="suf-btn p-2 d-flex flex-row">
            <button
              className="mx-2"
              onClick={() => {
                deleteDocumnet(deleteData);
              }}
            >
              Yes
            </button>
            <button
              className="suf-can-butt mx-2"
              onClick={() => setDeleteData("")}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
};

export default UploadDocument;
