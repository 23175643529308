import React, { useEffect, useRef, useState } from "react";
import "./SignUpForm.css";
import { Form, Spinner } from "react-bootstrap";
import {
  Apiurl,
  GetLocalStorageParsed,
  SetLocalStorage,
  SetLocalStorageStringify,
  validateonChangeHandle,
  validateonKeyUpHandle,
  validateonSubmitHandle,
} from "../../../Utils/Utils";
import Select from "react-select";
import leftImg from "../../../assets/images/bg-down.png";
import rightImg from "../../../assets/images/bg-up.png";
import { BiRefresh } from "react-icons/bi";
import { BsCheck, BsX, BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageProvider/LanguageProvider ";
import {
  checkEmailMobile,
  city,
  district,
  registartionForm,
  registerUser,
  states,
  verifyEmail,
  verifyMobile,
  verifyOtp,
} from "../../../services/api/auth";
import { Checkmark } from "react-checkmark";
import {
  customStylesAcc,
  customStylesAccUser,
  selectTheme,
  selectThemeUser,
} from "../../../Utils/common";

const SignupForm = () => {
  // pixel to rem conversion
  function pxToRem(px) {
    return `${px / 16}rem`;
  }
  const inputRefs = useRef([]);
  const emailRefs = useRef([]);
  const navigate = useNavigate();
  // states
  const { language, setLanguage } = useLanguage();
  const getWithoutLoginSubCatClick = GetLocalStorageParsed("subCategoryClick");
  const [allRegistrationFieldsData, setAllRegistrationFieldsData] = useState(
    []
  );
  const [otpButt, setOtpButton] = useState();
  const [verifyOtpButton, setVerifyOtpButton] = useState();
  const [belowButton, setBelowButton] = useState([]);
  const [registrationAllData, setRegistrationAllData] = useState();
  const [resendActive, setResendActive] = useState(0);
  const [resendActiveEmail, setResendActiveEmail] = useState(0);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [passwordVisible, setPasswordVisile] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisile] = useState(false);
  const [showSendMobileButt, setShowSendMobileButt] = useState(false);
  const [showSendEmailButt, setShowSendEmailButt] = useState(false);
  const [showOtpSendButtonMobile, setShowOtpSendButtonMobile] = useState(true);
  const [showOtpSendButtonEmail, setShowOtpSendButtonEmail] = useState(true);
  const [showMobileSendSection, setShowMobileSendSection] = useState(true);
  const [showEmailSendSection, setShowEmailSendSection] = useState(true);
  const [registrationFieldsData, setRegistrationFieldsData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const [otpMobile, setOtpMobile] = useState(new Array(6).fill(""));
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [mobileBtn, setMobileBtn] = useState(false);
  const [emailBtn, setEmailBtn] = useState(false);
  const [passwordWrongMessage, setPasswordWrongMessage] = useState();
  const [captchaWrongMessage, setCaptchaWrongMessage] = useState();
  const [mobileNumberValue, setMobileNumberValue] = useState();
  const [emailValue, setEmailValue] = useState();
  const [mobileNumberError, setMobileNumberError] = useState();
  const [emailError, setEmailError] = useState();
  const [otpErrorMobile, setOtpErrorMobile] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState(new Array(6).fill(""));
  const [emptyClickMessage, setEmptyClickMessage] = useState();
  const [mobileOtpVerified, setMobileOtpVerified] = useState(false); // To track OTP success
  const [emailOtpVerified, setEmailOtpVerified] = useState(false); // To track OTP success

  //  Get registration forms fields
  const GetRegistrationFormField = () => {
    /*const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${Apiurl()}/register-form-fields`,
        }; */
    registartionForm()
      .then((e) => {
        const data = JSON.parse(e?.data?.data?.input_fields?.[0]?.validations);
        setRegistrationAllData(e?.data?.data);

        const sOtpButton = e?.data?.data?.button_fields?.find(
          (i) => i?.id === 132
        );
        const votpButton = e?.data?.data?.button_fields?.find(
          (i) => i?.id === 133
        );
        setOtpButton(sOtpButton);
        setVerifyOtpButton(votpButton);

        const submitButton = e?.data?.data?.button_fields?.filter(
          (i) => i?.id === 134 || i?.id === 135
        );
        setBelowButton(submitButton);

        const filterOnlyRequiredFields = e?.data?.data?.input_fields?.filter(
          (i) => i?.required !== "0"
        );
        const updatedData = filterOnlyRequiredFields?.map((item) => ({
          ...item,
          value: "",
          error: "", // Set the default value as needed
        }));
        setAllRegistrationFieldsData(updatedData);
        setRegistrationFieldsData(updatedData);
      })
      .catch((error) => {});
  };
  // get state
  const GetStates = () => {
    /* const option = {
             method: "GET",
             headers: {
                 "access-control-allow-origin": "*",
                 "content-type": "application/json",
             },
             url: `${Apiurl()}/states`,
         };
         axios(option) */
    states()
      .then((e) => {
        let data = e?.data?.data?.map((state) => ({
          label: state.name_en,
          value: state.id,
        }));
        data.push({ label: "Other", value: "Other" });
        setStatesData(data);
      })
      .catch((error) => {});
  };
  //  Get district
  const GetDistricts = () => {
    /*const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${Apiurl()}/districts`,
        };
        axios(option) */
    district()
      .then((e) => {
        const data = e?.data?.data;
        data.push({ name: "Other", id: "Other" });
        setDistrictsData(data);
      })
      .catch((error) => {
        console.error(error); // Add error handling logic if needed
      });
  };
  //  Get Cities

  const GetCities = () => {
    /*const option = {
            method: "GET",
            headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
            },
            url: `${Apiurl()}/cities`,
        };
        axios(option) */
    city()
      .then((e) => {
        const data = e?.data?.data;
        data.push({ name: "Other", id: "Other" });

        setCitiesData(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    GetCities();
    GetDistricts();
    GetRegistrationFormField();
    GetStates();
  }, []);
  //    format time function
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };
  // mobile already exist api
  const handleMobileCheck = async () => {
    try {
      const formData = new FormData();
      formData.append("mobile_number", mobileNumberValue);
      /*const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                },
                url: `${Apiurl()}/is-email-mobileno-exists `,
                data: formData
            }; */
      return await checkEmailMobile(formData).then((reponse) => {
        return reponse;
      });
    } catch (error) {
      console.error(error);
    }
  };

  // send otp button click for mobile input
  const handleSendMobileClick = async (e) => {
    if (e === "resend") {
      setMobileBtn(false);
      setMobileNumberError({
        en: "Please verify your phone number",
        hi: "कृपया अपना फ़ोन नंबर सत्यापित करें",
      });
    }
    const existNumber = await handleMobileCheck();
    if (existNumber?.status === 200) {
      setMobileNumberError({
        en: "Phone Number already exist",
        hi: "फ़ोन नंबर पहले से मौजूद है",
      });
    } else {
      setMobileNumberError({
        en: "Please verify your phone number",
        hi: "कृपया अपना फ़ोन नंबर सत्यापित करें",
      });
      handleSendMobileOtp();
    }
  };
  // resend button mobile active
  useEffect(() => {
    if (resendActive > 0) {
      const Resendtimer = setInterval(() => {
        setResendActive((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(Resendtimer);
    }
  }, [resendActive]);
  // mobile otp send function
  const handleSendMobileOtp = async () => {
    setOtpMobile(new Array(6).fill(""));
    try {
      const data = {
        mobile_number: mobileNumberValue,
      };
      /*const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json', // Correct content type for JSON payload
                    'access-control-allow-origin': '*',

                },
                url: `${Apiurl()}/verify-mobile`,
                data: JSON.stringify(data), // Stringify the combined data
            }; */
      return await verifyMobile(data).then((reponse) => {
        if (reponse?.data) {
          setMobileNumberError({
            en: `${reponse?.data} Otp sent, Enter the otp below and verify your number`,
            hi: `${reponse?.data} ओटीपी भेजा गया, नीचे ओटीपी दर्ज करें और अपना नंबर सत्यापित करें`,
          });
          setShowOtpSendButtonMobile(false);
          setResendActive(120);
          setMobileBtn(true);
        }
      });
    } catch (error) {}
  };

  // onchange mobile otp
  const handleChangeOtpMobile = (element, index) => {
    const value = element.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otpMobile];
      newOtp[index] = value;
      setOtpMobile(newOtp);
      if (index < 5 && value) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      const newOtp = [...otpMobile];
      newOtp[index] = "";
      setOtpMobile(newOtp);
    }
    setOtpErrorMobile("");
  };
  // on key down mobile otp
  const handleKeyDownOtpMobile = (e, index) => {
    if (e.key === "Backspace" && !otpMobile[index]) {
      if (index > 0) {
        document.getElementById(`otp-input-mobile-${index - 1}`).focus();
      }
    }
  };
  //  verify mobile otp
  const handleVerifyOtpMobile = () => {
    let otpnew = otpMobile?.map((i) => i).join("");
    if (!otpnew || otpnew.trim() === "" || otpnew.length !== 6) {
      setOtpErrorMobile("Please enter correct OTP.");
      return;
    }
    handlesendVerifyMobileOtp(otpnew);
  };

  const handlesendVerifyMobileOtp = async (otp) => {
    setOtpErrorMobile("");
    try {
      const data = {
        type: "register",
        mobile_number: mobileNumberValue,
        otp: otp,
      };
      //const reponse = await axios.post(`${Apiurl()}/verify-otp`, data);
      return await verifyOtp(data).then((reponse) => {
        if (reponse?.success === true) {
          setMobileNumberError({
            en: "Mobile number verified",
            hi: "मोबाइल नंबर सत्यापित",
          });
          setShowOtpSendButtonMobile(false);
          setShowMobileSendSection(false);
          setMobileBtn(false);
          setMobileOtpVerified(true); // Set OTP as verified
        } else {
          setOtpErrorMobile(
            "The One-Time Password (OTP) you entered isn't valid or may be expired"
          );
        }
      });
    } catch (error) {
      setOtpErrorMobile(
        "The One-Time Password (OTP) you entered isn't valid or may be expired"
      );
    }
  };

  // email already exist api
  const handleEmailCheck = async () => {
    try {
      const formData = new FormData();
      formData.append("email", emailValue);
      /* const option = {
                 method: "POST",
                 headers: {
                     'Content-Type': 'multipart/form-data',
                     'access-control-allow-origin': '*',
                 },
                 url: `${Apiurl()}/is-email-mobileno-exists `,
                 data: formData
             };
             const response = await axios(option) */
      return await checkEmailMobile(formData).then((reponse) => {
        return reponse;
      });
    } catch (error) {
      console.error(error);
    }
  };
  // email otp send function
  const handleSendEmailOtp = async (e) => {
    setLoadingEmail(true);
    if (e === "resend") {
      setEmailBtn(false);
      setEmailError({
        en: "Please verify your email",
        hi: "कृपया अपना ईमेल सत्यापित करें",
      });
    }
    const existEmail = await handleEmailCheck();
    if (existEmail?.status === 200) {
      setLoadingEmail(false);
      setEmailError({ en: "Email already exist", hi: "ईमेल पहले से मौजूद है" });
    } else {
      setEmailError({
        en: "Please verify your email",
        hi: "कृपया अपना ईमेल सत्यापित करें",
      });
      setOtp(new Array(6).fill(""));
      try {
        const data = {
          email: emailValue,
        };
        /*const option = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json', // Correct content type for JSON payload
                        'access-control-allow-origin': '*',
                    },
                    url: `${Apiurl()}/verify-email`,
                    data: JSON.stringify(data), // Stringify the combined data
                };
                const response = await axios(option); */
        return await verifyEmail(data).then((reponse) => {
          if (reponse?.data) {
            setLoadingEmail(false);
            setEmailError({
              en: "Otp sent, Enter the otp below and verify your email",
              hi:
                "ओटीपी भेजा गया, नीचे ओटीपी दर्ज करें और अपना ईमेल सत्यापित करें",
            });
            setResendActiveEmail(120);
            setShowOtpSendButtonEmail(false);
            setEmailBtn(true);
          }
        });
      } catch (error) {}
    }
  };
  // onchange email otp
  const handleChangeOtp = (element, index) => {
    const value = element.value;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5 && value) {
        emailRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
    setOtpError("");
  };
  // on key down email otp
  const handleKeyDownOtp = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };
  // verify email otp
  const handleVerifyOtp = () => {
    let otpnew = otp?.map((i) => i).join("");
    if (!otpnew || otpnew.trim() === "" || otpnew.length !== 6) {
      setOtpError("Please enter correct OTP.");
      return;
    }

    handlesendVerifyOtp(otpnew);
  };
  const handlesendVerifyOtp = async (otp) => {
    setOtpError("");
    try {
      const data = {
        type: "register",
        email: emailValue,
        otp: otp,
      };
      //const reponse = await axios.post(`${Apiurl()}/verify-otp`, data);
      return await verifyOtp(data).then((reponse) => {
        if (reponse?.success === true) {
          setEmailError({ en: "Email verified", hi: "ईमेल सत्यापित" });
          setShowOtpSendButtonEmail(false);
          setEmailBtn(false);
          setShowEmailSendSection(false);
          setEmailOtpVerified(true);
        } else {
          setOtpError(
            "The One-Time Password (OTP) you entered isn't valid or may be expired"
          );
        }
      });
    } catch (error) {
      setOtpError(
        "The One-Time Password (OTP) you entered isn't valid or may be expired"
      );
    }
  };
  // resend button email active
  useEffect(() => {
    if (resendActiveEmail > 0) {
      const Resendtimer = setInterval(() => {
        setResendActiveEmail((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(Resendtimer);
    }
  }, [resendActiveEmail]);
  // captcha generate
  const generateCaptcha = () => {
    const characters = "ABCDEFGHJKLMNOPQRSTUVWXYZ123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setCaptcha(result);
    const updatedFields = registrationFieldsData?.map((field) => {
      if (field.id === 17) {
        return {
          ...field,
          value: "",
          error: "",
        };
      }
      return field;
    });
    setRegistrationFieldsData(updatedFields);
  };
  useEffect(() => {
    generateCaptcha();
  }, []);

  // submit registration form
  const handleSubmit = async () => {
    // if (!handleKeyUp("both")) return;
    try {
      const existsMobile = await handleMobileCheck();
      if (existsMobile?.status === 200) {
        return;
      }
      const existEmail = await handleEmailCheck();
      if (existEmail?.status === 200) {
        return;
      }

      const formData = new FormData();
      formData.append("captcha", captcha);
      registrationFieldsData?.forEach((field) => {
        const inputName = field.input_field_name;
        const fieldValue = field?.value;

        formData.append(inputName, fieldValue);
      });

      /*const option = {
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                },
                url: `${Apiurl()}/register`,
                data: formData
            };

            const response = await axios(option) */
      return await registerUser(formData).then(response => {
        if (response?.status === 200) {
          SetLocalStorage("userInfo", response?.token)
          SetLocalStorageStringify("tokenExpiry", response?.expire_at)
          SetLocalStorageStringify("userDetail", response?.data);

          if (getWithoutLoginSubCatClick) {
            navigate("/application/application-form", { state: "register" });
          } else {
            navigate("/dashboard", { state: "register" });
          }
        } else {
          setEmptyClickMessage({
            hi: Object.values(response[0]?.errors)[0].toString() ?? "",
            en: Object.values(response[0]?.errors)[0].toString() ?? "",
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  //  cancel registration form
  const handleCancelSignUp = () => {
    setRegistrationFieldsData(allRegistrationFieldsData);
    window.scrollTo(0, 0);
    navigate("/");
  };

  //    get mobile number value
  useEffect(() => {
    if (
      registrationFieldsData?.find(
        (field) => field.input_field_name === "mobile_number"
      )?.value?.length >= 10
    ) {
      const mobile = registrationFieldsData?.find(
        (field) => field.input_field_name === "mobile_number"
      )?.value;
      setMobileNumberValue(mobile);
      setShowSendMobileButt(true);
      setMobileNumberError({
        en: "Please verify your phone number",
        hi: "कृपया अपना फ़ोन नंबर सत्यापित करें",
      });
    } else {
      setShowMobileSendSection(true);
      setShowOtpSendButtonMobile(true);
      setMobileNumberError("");
      setMobileOtpVerified(false);
      setShowSendMobileButt(false);
      setMobileBtn(false);
    }
  }, [
    registrationFieldsData?.find(
      (field) => field.input_field_name === "mobile_number"
    )?.value,
  ]);

  //    get emailvalue
  useEffect(() => {
    if (
      registrationFieldsData?.find(
        (field) => field.input_field_name === "email"
      )?.error === "" &&
      registrationFieldsData?.find(
        (field) => field.input_field_name === "email"
      )?.value?.length
    ) {
      const email = registrationFieldsData?.find(
        (field) => field.input_field_name === "email"
      )?.value;
      setEmailValue(email);
      setShowSendEmailButt(true);
      setEmailError({
        en: "Please verify your email",
        hi: "कृपया अपना ईमेल सत्यापित करें",
      });
    } else {
      setShowEmailSendSection(true);
      setEmailBtn(false);
      setShowOtpSendButtonEmail(true);
      setEmailError("");
      setEmailOtpVerified(false);
      setShowSendEmailButt(false);
    }
  }, [
    registrationFieldsData?.find((field) => field.input_field_name === "email")
      ?.value?.length,
  ]);
  // check password and confirm password match
  useEffect(() => {
    if (
      registrationFieldsData?.find(
        (field) => field.input_field_name === "password_confirmation"
      )?.value?.length >= 6
    ) {
      const passwordValue = registrationFieldsData?.find(
        (field) => field.input_field_name === "password"
      )?.value;
      const ConfirmpasswordValue = registrationFieldsData?.find(
        (field) => field.input_field_name === "password_confirmation"
      );
      if (passwordValue !== ConfirmpasswordValue?.value) {
        setPasswordWrongMessage({
          hi: "पासवर्ड मेल नहीं खाया",
          en: "Password did not match",
        });
      } else {
        setPasswordWrongMessage("");
      }
    } else {
      setPasswordWrongMessage("");
    }
  }, [registrationFieldsData]);
  // check captcha matches
  useEffect(() => {
    if (
      registrationFieldsData?.find(
        (field) => field.input_field_name === "enter_captcha"
      )?.value?.length >= 5
    ) {
      const captchaValue = registrationFieldsData?.find(
        (field) => field.input_field_name === "enter_captcha"
      )?.value;
      if (captcha !== captchaValue) {
        setCaptchaWrongMessage({
          hi: "कैप्चा मेल नहीं खाया",
          en: "Captcha did not match",
        });
      } else {
        setCaptchaWrongMessage("");
      }
    } else {
      setCaptchaWrongMessage("");
    }
  }, [
    registrationFieldsData?.find(
      (field) => field.input_field_name === "enter_captcha"
    )?.value,
  ]);
  //  check every field has value and their error is empty
  const allFieldsValid = () => {
    return registrationFieldsData?.every(
      (item) => item.value && item.error === ""
    );
  };
  // signup click function without filling all the details
  const hadleShowMessgae = () => {
    const updatedFieldsData = registrationFieldsData?.map((item) => {
      const error = validateonSubmitHandle(
        item,
        item.value,
        registrationFieldsData
      );
      return {
        ...item,
        error: error,
      };
    });
    setRegistrationFieldsData(updatedFieldsData);

    // if (registrationFieldsData?.every(item => item.value)) {

    //     setEmptyClickMessage({ hi: "कृपया सभी फ़ील्ड सत्यापित करें", en: "Please validate all fields" })
    // } else {
    //     setEmptyClickMessage({ hi: "कृपया सभी विवरण भरें", en: "Please fill all details" })

    // }
  };
  return (
    <section className="main-outer-container">
      <img src={leftImg} alt="background-image" className="main-bg-imgleft" />
      <img src={rightImg} alt="background-image" className="main-bg-imgright" />
      <div className="container">
        <div className="row lf-row d-flex justify-content-center align-items-center">
          <div className="col-md-9 lf-col p-0 sup-reg-sec">
            <div className="lf-outer-con">
              {registrationAllData?.form_fields?.map((i) => (
                <h2 className="lf-heading">
                  {language ? i?.name_hi : i?.name_en}
                </h2>
              ))}
              <div className="lf-inner sup-inner">
                <div className="lf-outer">
                  {registrationFieldsData?.length ? (
                    <>
                      <div
                        className="row"
                        onClick={() => setEmptyClickMessage("")}
                      >
                        {/* Registation fields Mapping */}
                        {registrationFieldsData?.map((item) => {
                          return (
                            <div className="col-md-6">
                              {/* text fields */}
                              {item?.id === 17 ? (
                                <div
                                  className="suf-input-box mb-3"
                                  style={{
                                    fontSize: pxToRem(item?.font_size),
                                    color: item?.font_color,
                                  }}
                                >
                                  <label className="lf-label w-100">
                                    {language ? item?.name_hi : item?.name_en}
                                    <span className="suf-required_detail">
                                      *
                                    </span>
                                  </label>
                                  <div className="suf-captcha-box ">
                                    <div className="col-md-6 captcha-head">
                                      <input
                                        className="col-md-12"
                                        placeholder={
                                          language
                                            ? item?.placeholder_hi
                                            : item?.placeholder_en
                                        }
                                        type="text"
                                        value={item?.value}
                                        onChange={(e) => {
                                          validateonChangeHandle(
                                            item,
                                            e,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          );
                                        }}
                                        onKeyUp={(e) => {
                                          validateonKeyUpHandle(
                                            item,
                                            e?.target?.value,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          );
                                        }}
                                      />
                                      {item?.error && (
                                        <p className="validate-error">
                                          {language
                                            ? item?.error?.hi
                                            : item?.error?.en}
                                        </p>
                                      )}
                                      {captchaWrongMessage && (
                                        <p className="validate-error">
                                          {language
                                            ? captchaWrongMessage?.hi
                                            : captchaWrongMessage?.en}
                                        </p>
                                      )}
                                    </div>
                                    <div className="input_field captch_box ps-3 col-md-6">
                                      <input
                                        type="text"
                                        className="generatedCaptcha"
                                        name="box"
                                        disabled
                                        value={captcha}
                                      />
                                      <BiRefresh
                                        onClick={generateCaptcha}
                                        className="suf-captcha"
                                      />
                                      <input
                                        type="hidden"
                                        id="storedData"
                                        name="storedData"
                                        value={captcha}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : item?.id === 6 ? (
                                <>
                                  <div
                                    className="suf-input-box mb-3"
                                    style={{
                                      fontSize: pxToRem(item?.font_size),
                                      color: item?.font_color,
                                    }}
                                  >
                                    <label className="lf-label w-100">
                                      {language ? item?.name_hi : item?.name_en}
                                    </label>
                                    <div className="input-but-div-otp">
                                      <div className="input-field-phone-num">
                                        <input
                                          placeholder={
                                            language
                                              ? item?.placeholder_hi
                                              : item?.placeholder_en
                                          }
                                          type="text"
                                          maxLength="10"
                                          onChange={(e) => {
                                            validateonChangeHandle(
                                              item,
                                              e,
                                              registrationFieldsData,
                                              setRegistrationFieldsData
                                            );
                                          }}
                                          onKeyUp={(e) => {
                                            validateonKeyUpHandle(
                                              item,
                                              e?.target?.value,
                                              registrationFieldsData,
                                              setRegistrationFieldsData
                                            );
                                          }}
                                          value={item?.value}
                                        />
                                      </div>
                                      <div
                                        className={
                                          !showSendMobileButt
                                            ? "butt-field-phone-num "
                                            : "butt-field-phone-num-ina "
                                        }
                                      >
                                        {showMobileSendSection ? (
                                          showOtpSendButtonMobile ? (
                                            <button
                                              disabled={!showSendMobileButt}
                                              onClick={handleSendMobileClick}
                                            >
                                              {language
                                                ? otpButt?.name_hi
                                                : otpButt?.name_en}
                                            </button>
                                          ) : resendActive <= 1 ? (
                                            <button
                                              disabled={!showSendMobileButt}
                                              onClick={() =>
                                                handleSendMobileClick("resend")
                                              }
                                            >
                                              {language
                                                ? "ओटीपी पुनः भेजें"
                                                : "Resend OTP"}
                                            </button>
                                          ) : (
                                            <button
                                              disabled
                                              className="resend_in_butt"
                                            >
                                              {language
                                                ? `${formatTime(
                                                    resendActive
                                                  )} में पुनः भेजें`
                                                : `Resend in ${formatTime(
                                                    resendActive
                                                  )}`}
                                            </button>
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                    {item?.error ? (
                                      <p className="validate-error">
                                        {language
                                          ? item?.error?.hi
                                          : item?.error?.en}
                                      </p>
                                    ) : mobileNumberError ? (
                                      mobileNumberError?.hi ===
                                        "कृपया अपना फ़ोन नंबर सत्यापित करें" ||
                                      mobileNumberError?.hi ===
                                        "फ़ोन नंबर पहले से मौजूद है" ||
                                      mobileNumberError?.en ==
                                        "Please verify your phone number" ||
                                      mobileNumberError?.en ==
                                        "Phone Number already exist" ? (
                                        <p className="validate-error">
                                          {language
                                            ? mobileNumberError?.hi
                                            : mobileNumberError?.en}
                                        </p>
                                      ) : (
                                        <p className="sucess-message-otp">
                                          <span className="verification-done-otp">
                                            {mobileOtpVerified ? (
                                              <Checkmark
                                                size="small"
                                                color="green"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <span
                                              style={{
                                                marginLeft: "8px",
                                                color: mobileOtpVerified
                                                  ? "green"
                                                  : "inherit",
                                              }}
                                            >
                                              {language
                                                ? mobileNumberError?.hi
                                                : mobileNumberError?.en}
                                            </span>
                                          </span>
                                        </p>
                                      )
                                    ) : null}
                                  </div>
                                  {mobileBtn && (
                                    <>
                                      <div className="fp-otp">
                                        {otpMobile?.map((data, index) => (
                                          <input
                                            key={index}
                                            id={`otp-input-mobile-${index}`}
                                            ref={(el) =>
                                              (inputRefs.current[index] = el)
                                            }
                                            type="text"
                                            placeholder="*"
                                            maxLength="1"
                                            className="fp-otp-input"
                                            value={otpMobile[index]}
                                            onChange={(e) =>
                                              handleChangeOtpMobile(
                                                e.target,
                                                index
                                              )
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDownOtpMobile(e, index)
                                            }
                                          />
                                        ))}
                                      </div>
                                      {otpErrorMobile ? (
                                        <p className="validate-error">
                                          {otpErrorMobile}
                                        </p>
                                      ) : null}
                                      {/* Verify otp */}
                                      <div className="lf-btn border-0 pt-3">
                                        <button onClick={handleVerifyOtpMobile}>
                                          {language
                                            ? verifyOtpButton?.name_hi
                                            : verifyOtpButton?.name_en}
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : item?.id === 7 ? (
                                <>
                                  <div
                                    className="suf-input-box mb-3"
                                    style={{
                                      fontSize: pxToRem(item?.font_size),
                                      color: item?.font_color,
                                    }}
                                  >
                                    <label className="lf-label w-100">
                                      {language ? item?.name_hi : item?.name_en}
                                    </label>
                                    <div className="input-but-div-otp">
                                      <div className="input-field-phone-num">
                                        <input
                                          placeholder={
                                            language
                                              ? item?.placeholder_hi
                                              : item?.placeholder_en
                                          }
                                          type="text"
                                          onChange={(e) => {
                                            validateonChangeHandle(
                                              item,
                                              e,
                                              registrationFieldsData,
                                              setRegistrationFieldsData
                                            );
                                          }}
                                          onKeyUp={(e) => {
                                            validateonKeyUpHandle(
                                              item,
                                              e?.target?.value,
                                              registrationFieldsData,
                                              setRegistrationFieldsData
                                            );
                                          }}
                                          value={item?.value}
                                          onBlur={() => {
                                            if (item?.id === 7) {
                                              handleEmailCheck();
                                            }
                                          }}
                                        />
                                      </div>
                                      <div
                                        className={
                                          !showSendEmailButt
                                            ? "butt-field-phone-num "
                                            : "butt-field-phone-num-ina "
                                        }
                                      >
                                        {showEmailSendSection ? (
                                          showOtpSendButtonEmail ? (
                                            <>
                                              {loadingEmail ? (
                                                <>
                                                  <button>
                                                    <Spinner
                                                      animation="border"
                                                      role="status"
                                                    >
                                                      <span className="visually-hidden">
                                                        Loading...
                                                      </span>
                                                    </Spinner>
                                                  </button>
                                                </>
                                              ) : (
                                                <button
                                                  disabled={!showSendEmailButt}
                                                  onClick={handleSendEmailOtp}
                                                >
                                                  {language
                                                    ? otpButt?.name_hi
                                                    : otpButt?.name_en}
                                                </button>
                                              )}
                                            </>
                                          ) : resendActiveEmail <= 1 ? (
                                            <>
                                              {loadingEmail ? (
                                                <>
                                                  <button>
                                                    <Spinner
                                                      animation="border"
                                                      role="status"
                                                    >
                                                      <span className="visually-hidden">
                                                        Loading...
                                                      </span>
                                                    </Spinner>
                                                  </button>
                                                </>
                                              ) : (
                                                <button
                                                  disabled={!showSendEmailButt}
                                                  onClick={() =>
                                                    handleSendEmailOtp("resend")
                                                  }
                                                >
                                                  {language
                                                    ? "ओटीपी पुनः भेजें"
                                                    : "Resend OTP"}
                                                </button>
                                              )}
                                            </>
                                          ) : (
                                            <button
                                              disabled
                                              className="resend_in_butt"
                                            >
                                              {language
                                                ? `${formatTime(
                                                    resendActiveEmail
                                                  )} में पुनः भेजें`
                                                : `Resend in ${formatTime(
                                                    resendActiveEmail
                                                  )}`}
                                            </button>
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                    {item?.error ? (
                                      <p className="validate-error">
                                        {language
                                          ? item?.error?.hi
                                          : item?.error?.en}
                                      </p>
                                    ) : emailError ? (
                                      emailError?.hi ==
                                        "कृपया अपना ईमेल सत्यापित करें" ||
                                      emailError?.hi ==
                                        "ईमेल पहले से मौजूद है" ||
                                      emailError?.en ==
                                        "Please verify your email" ||
                                      emailError?.en ==
                                        "Email already exist" ? (
                                        <p className="validate-error">
                                          {language
                                            ? emailError?.hi
                                            : emailError?.en}
                                        </p>
                                      ) : (
                                        <p className="sucess-message-otp">
                                          <span className="verification-done-otp">
                                            {emailOtpVerified ? (
                                              <Checkmark
                                                size="small"
                                                color="green"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            <span
                                              style={{
                                                marginLeft: "8px",
                                                color: emailOtpVerified
                                                  ? "green"
                                                  : "inherit",
                                              }}
                                            >
                                              {language
                                                ? emailError?.hi
                                                : emailError?.en}
                                            </span>
                                          </span>
                                        </p>
                                      )
                                    ) : null}
                                  </div>
                                  {/* Otp */}
                                  {emailBtn && (
                                    <>
                                      <div className="fp-otp">
                                        {otp?.map((data, index) => (
                                          <input
                                            key={index}
                                            id={`otp-input-${index}`}
                                            ref={(el) =>
                                              (emailRefs.current[index] = el)
                                            }
                                            type="text"
                                            placeholder="*"
                                            maxLength="1"
                                            className="fp-otp-input"
                                            value={otp[index]}
                                            onChange={(e) =>
                                              handleChangeOtp(e.target, index)
                                            }
                                            onKeyDown={(e) =>
                                              handleKeyDownOtp(e, index)
                                            }
                                          />
                                        ))}
                                      </div>
                                      {otpError ? (
                                        <p className="validate-error">
                                          {otpError}
                                        </p>
                                      ) : null}

                                      {/* Verify otp */}
                                      <div className="lf-btn border-0 pt-3">
                                        <button onClick={handleVerifyOtp}>
                                          {language
                                            ? verifyOtpButton?.name_hi
                                            : verifyOtpButton?.name_en}
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : item?.type === "text" ? (
                                <div
                                  className="suf-input-box mb-3"
                                  style={{
                                    fontSize: pxToRem(item?.font_size),
                                    color: item?.font_color,
                                  }}
                                >
                                  <label className="lf-label w-100">
                                    {language ? item?.name_hi : item?.name_en}
                                  </label>
                                  <input
                                    className="w-100"
                                    placeholder={
                                      language
                                        ? item?.placeholder_hi
                                        : item?.placeholder_en
                                    }
                                    type="text"
                                    onChange={(e) => {
                                      const value = e.target.value; // Replace non-digit characters
                                      validateonChangeHandle(
                                        item,
                                        {
                                          ...e,
                                          target: { ...e.target, value },
                                        },
                                        registrationFieldsData,
                                        setRegistrationFieldsData
                                      );
                                    }}
                                    onKeyUp={(e) => {
                                      const value = e.target.value; // Ensure non-digit characters are removed on keyup
                                      validateonKeyUpHandle(
                                        item,
                                        value,
                                        registrationFieldsData,
                                        setRegistrationFieldsData
                                      );
                                    }}
                                    value={item?.value} // Ensure non-digit characters are removed in the value prop
                                  />
                                  {item?.error && (
                                    <p className="validate-error">
                                      {language
                                        ? item?.error?.hi
                                        : item?.error?.en}
                                    </p>
                                  )}
                                </div>
                              ) : // drodpown fields
                              item?.type === "dropdown" ? (
                                <div
                                  className="suf-input-box mb-3"
                                  style={{
                                    fontSize: pxToRem(item?.font_size),
                                    color: item?.font_color,
                                  }}
                                >
                                  {
                                    <>
                                      <label className="lf-label w-100">
                                        {language
                                          ? item?.name_hi
                                          : item?.name_en}
                                      </label>
                                      <Select
                                        styles={customStylesAccUser}
                                        theme={selectThemeUser}
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                        className="menu_open_select"
                                        classNamePrefix="menu_open_select_class"
                                        aria-label="Default select example"
                                        value={
                                          item?.input_field_name === "gender"
                                            ? item?.value
                                              ? {
                                                  label: item?.value,
                                                  value: item?.value,
                                                }
                                              : null
                                            : item?.input_field_name === "state"
                                            ? item?.value
                                              ? statesData?.find(
                                                  (state) =>
                                                    state.value === item?.value
                                                )
                                              : null
                                            : item?.input_field_name ===
                                              "district"
                                            ? item?.value
                                              ? {
                                                  label: districtsData?.find(
                                                    (district) =>
                                                      district.id ===
                                                      item?.value
                                                  )?.name_en,
                                                  value: item?.value,
                                                }
                                              : null
                                            : item?.input_field_name === "city"
                                            ? item?.value
                                              ? {
                                                  label: citiesData?.find(
                                                    (city) =>
                                                      city.id === item?.value
                                                  )?.name_en,
                                                  value: item?.value,
                                                }
                                              : null
                                            : null
                                        }
                                        options={(() => {
                                          if (
                                            item?.input_field_name === "gender"
                                          ) {
                                            return JSON.parse(
                                              item?.dropdown_values
                                            )?.gender?.map((gen) => ({
                                              label: gen,
                                              value: gen,
                                            }));
                                          } else if (
                                            item?.input_field_name === "state"
                                          ) {
                                            return statesData;
                                          } else if (
                                            item?.input_field_name ===
                                            "district"
                                          ) {
                                            const selectedState = registrationFieldsData?.find(
                                              (field) =>
                                                field.input_field_name ===
                                                "state"
                                            )?.value;
                                            let districtOptions = districtsData
                                              ?.filter(
                                                (district) =>
                                                  district.state_id ===
                                                  selectedState
                                              )
                                              ?.map((district) => ({
                                                label: district.name_en,
                                                value: district.id,
                                              }));
                                            if (selectedState) {
                                              districtOptions.push({
                                                label: "Other",
                                                value: "Other",
                                              });
                                            }
                                            return districtOptions;
                                          } else if (
                                            item?.input_field_name === "city"
                                          ) {
                                            const selectedState = registrationFieldsData?.find(
                                              (field) =>
                                                field.input_field_name ===
                                                "state"
                                            )?.value;
                                            const selectedDistrict = registrationFieldsData?.find(
                                              (field) =>
                                                field.input_field_name ===
                                                "district"
                                            )?.value;
                                            let cityOptions = citiesData
                                              ?.filter(
                                                (city) =>
                                                  city.district_id ===
                                                    selectedDistrict &&
                                                  city.state_id ===
                                                    selectedState
                                              )
                                              ?.map((city) => ({
                                                label: city.name_en,
                                                value: city.id,
                                              }));
                                            if (
                                              selectedState &&
                                              selectedDistrict
                                            ) {
                                              cityOptions.push({
                                                label: "Other",
                                                value: "Other",
                                              });
                                            }
                                            return cityOptions;
                                          }

                                          return [];
                                        })()}
                                        onChange={(e) => {
                                          validateonKeyUpHandle(
                                            item,
                                            e?.value,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          );
                                          if (
                                            item?.input_field_name === "state"
                                          ) {
                                            setRegistrationFieldsData(
                                              (prevData) =>
                                                prevData.map((field) => {
                                                  if (
                                                    field.input_field_name ===
                                                      "district" ||
                                                    field.input_field_name ===
                                                      "city"
                                                  ) {
                                                    return {
                                                      ...field,
                                                      value: "",
                                                    }; // Reset district and city values if state changes
                                                  }
                                                  return field;
                                                })
                                            );
                                          }
                                          if (
                                            item?.input_field_name ===
                                            "district"
                                          ) {
                                            setRegistrationFieldsData(
                                              (prevData) =>
                                                prevData.map((field) => {
                                                  if (
                                                    field.input_field_name ===
                                                    "city"
                                                  ) {
                                                    return {
                                                      ...field,
                                                      value: "",
                                                    }; // Reset city value if district changes
                                                  }
                                                  return field;
                                                })
                                            );
                                          }
                                        }}
                                        placeholder={
                                          language
                                            ? item?.placeholder_hi
                                            : item?.placeholder_en
                                        }
                                        onMenuOpen={(e) => {
                                          validateonKeyUpHandle(
                                            item,
                                            e?.value,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          );
                                          if (
                                            item?.input_field_name === "state"
                                          ) {
                                            setRegistrationFieldsData(
                                              (prevData) =>
                                                prevData.map((field) => {
                                                  if (
                                                    field.input_field_name ===
                                                      "district" ||
                                                    field.input_field_name ===
                                                      "city"
                                                  ) {
                                                    return {
                                                      ...field,
                                                      value: "",
                                                    }; // Reset district and city values if state changes
                                                  }
                                                  return field;
                                                })
                                            );
                                          }
                                          if (
                                            item?.input_field_name ===
                                            "district"
                                          ) {
                                            setRegistrationFieldsData(
                                              (prevData) =>
                                                prevData.map((field) => {
                                                  if (
                                                    field.input_field_name ===
                                                    "city"
                                                  ) {
                                                    return {
                                                      ...field,
                                                      value: "",
                                                    }; // Reset city value if district changes
                                                  }
                                                  return field;
                                                })
                                            );
                                          }
                                        }}
                                      />
                                      {item?.error && (
                                        <p className="validate-error">
                                          {language
                                            ? item?.error?.hi
                                            : item?.error?.en}
                                        </p>
                                      )}
                                    </>
                                  }
                                </div>
                              ) : // password fields
                              item?.id === 14 ? (
                                <>
                                  <div
                                    className="suf-input-box mb-3"
                                    style={{
                                      fontSize: pxToRem(item?.font_size),
                                      color: item?.font_color,
                                    }}
                                  >
                                    <label className="lf-label w-100">
                                      {language ? item?.name_hi : item?.name_en}
                                    </label>
                                    <div className="used-icon-password-visible">
                                      <input
                                        className="w-100"
                                        placeholder={
                                          language
                                            ? item?.placeholder_hi
                                            : item?.placeholder_en
                                        }
                                        type={
                                          passwordVisible ? "text" : "password"
                                        }
                                        value={item?.value}
                                        onKeyUp={(e) =>
                                          validateonKeyUpHandle(
                                            item,
                                            e.target.value,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          )
                                        }
                                        onChange={(e) => {
                                          validateonChangeHandle(
                                            item,
                                            e,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          );
                                        }}
                                        onCopy={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                      />
                                      {passwordVisible ? (
                                        <BsEye
                                          className="icon-password-visible"
                                          onClick={() => {
                                            setPasswordVisile(!passwordVisible);
                                          }}
                                        />
                                      ) : (
                                        <BsEyeSlash
                                          className="icon-password-visible"
                                          onClick={() => {
                                            setPasswordVisile(!passwordVisible);
                                          }}
                                        />
                                      )}
                                    </div>
                                    {item?.error && (
                                      <p className="validate-error">
                                        {language
                                          ? item?.error?.hi
                                          : item?.error?.en}
                                      </p>
                                    )}
                                  </div>
                                </>
                              ) : // confirm password
                              item?.id === 15 ? (
                                <>
                                  <div
                                    className="suf-input-box mb-3"
                                    style={{
                                      fontSize: pxToRem(item?.font_size),
                                      color: item?.font_color,
                                    }}
                                  >
                                    <label className="lf-label w-100">
                                      {language ? item?.name_hi : item?.name_en}
                                    </label>
                                    <div className="used-icon-password-visible">
                                      <input
                                        className="w-100"
                                        placeholder={
                                          language
                                            ? item?.placeholder_hi
                                            : item?.placeholder_en
                                        }
                                        value={item?.value}
                                        onKeyUp={(e) =>
                                          validateonKeyUpHandle(
                                            item,
                                            e.target.value,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          )
                                        }
                                        onChange={(e) => {
                                          validateonChangeHandle(
                                            item,
                                            e,
                                            registrationFieldsData,
                                            setRegistrationFieldsData
                                          );
                                        }}
                                        type={
                                          confirmPasswordVisible
                                            ? "text"
                                            : "password"
                                        }
                                        onCopy={(e) => e.preventDefault()}
                                        onCut={(e) => e.preventDefault()}
                                        onPaste={(e) => e.preventDefault()}
                                      />
                                      {confirmPasswordVisible ? (
                                        <BsEye
                                          className="icon-password-visible"
                                          onClick={() =>
                                            setConfirmPasswordVisile(
                                              !confirmPasswordVisible
                                            )
                                          }
                                        />
                                      ) : (
                                        <BsEyeSlash
                                          className="icon-password-visible"
                                          onClick={() =>
                                            setConfirmPasswordVisile(
                                              !confirmPasswordVisible
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                    {item?.error && (
                                      <p className="validate-error">
                                        {language
                                          ? item?.error?.hi
                                          : item?.error?.en}
                                      </p>
                                    )}
                                    {passwordWrongMessage && (
                                      <p className="validate-error">
                                        {language
                                          ? passwordWrongMessage?.hi
                                          : passwordWrongMessage?.en}
                                      </p>
                                    )}
                                  </div>
                                </>
                              ) : // :
                              // // image uploader
                              // item?.type === "file" ? <>
                              //     <div className='suf-input-box mb-3' style={{ fontSize: pxToRem(item?.font_size), color: item?.font_color }}>
                              //         <label className='lf-label w-100'>
                              //             Upload Image
                              //         </label>
                              //         <input type="file" name="partsuploadfile" id="profileimg" className="w-100 suf-input-field-image"
                              //         />
                              //         <label htmlFor="profileimg" className="suf-input-upload-image"
                              //         >
                              //             <h5 className="para-parts-upload-image">
                              //                 Choose Profile Photo
                              //             </h5>
                              //         </label>
                              //     </div>
                              // </>
                              null}
                            </div>
                          );
                        })}
                      </div>
                      <div className="suf-btn">
                        <p className="validate-error">
                          {language
                            ? emptyClickMessage?.hi
                            : emptyClickMessage?.en}
                        </p>
                        <div className="button-reg-cls">
                          {belowButton?.map((but) => (
                            <>
                              {but.id === 135 ? (
                                <button
                                  key={but.id}
                                  className="suf-can-butt green-btn"
                                  onClick={handleCancelSignUp}
                                >
                                  {language ? but.name_hi : but.name_en}
                                </button>
                              ) : but.id !== 135 &&
                                allFieldsValid() &&
                                (emailError?.en === "Email verified" ||
                                  emailError?.hi === "ईमेल सत्यापित") &&
                                (mobileNumberError?.en ===
                                  "Mobile number verified" ||
                                  mobileNumberError?.hi ===
                                    "मोबाइल नंबर सत्यापित") &&
                                (passwordWrongMessage?.en === "" ||
                                  passwordWrongMessage?.hi === "" ||
                                  passwordWrongMessage === "") &&
                                (captchaWrongMessage?.en === "" ||
                                  captchaWrongMessage?.hi === "" ||
                                  captchaWrongMessage === "") ? (
                                <button key={but.id} onClick={handleSubmit}>
                                  {language ? but.name_hi : but.name_en}
                                </button>
                              ) : (
                                <button
                                  className="suf-btn-regis-inactive"
                                  key={but.id}
                                  onClick={hadleShowMessgae}
                                >
                                  {language ? but.name_hi : but.name_en}
                                </button>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SignupForm;
