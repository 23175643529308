import React, { useEffect, useState } from 'react'
import LoginForm from '../../../components/Auth/Login/LoginForm'
import Banner from '../../../components/Banner/Banner'
import MainLayout from '../../../components/MainLayout/MainLayout';
import { useLocation } from 'react-router-dom';
import { Apiurl } from '../../../Utils/Utils';
import axios from 'axios';
import { useLanguage } from '../../../components/LanguageProvider/LanguageProvider ';
import { getLoginFormField } from '../../../services/api/auth';
const Login = () => {
  const { language, setLanguage } = useLanguage();

  const [loginFormData, setLoginFormData] = useState([]);
  const [buttData, setButtData] = useState([]);
  const [formData, setFormData] = useState([]);

  //  Get login forms fields
  const GetLoginFormField = () => {
    getLoginFormField().then(res => {
      const updatedData = res?.data?.input_fields?.map(item => ({
        ...item,
        value: '',
        error: ''
      }));
      setLoginFormData(updatedData)
      setFormData(res?.data?.formFields)
      setButtData([...res?.data?.button_fields, ...res?.data?.link_fields]);
    }).catch((error) => {
    });
    // const option = {
    //   method: "GET",
    //   headers: {
    //     "access-control-allow-origin": "*",
    //     "content-type": "application/json",
    //   },
    //   url: `${Apiurl()}/login-form`,
    // };
    // axios(option)
    //   .then((e) => {
    //     const updatedData = e?.data?.data?.input_fields?.map(item => ({
    //       ...item,
    //       value: '',
    //       error: ''
    //     }));
    //     setLoginFormData(updatedData)
    //     setFormData(e?.data?.data?.formFields)
    //     setButtData([...e?.data?.data?.button_fields, ...e?.data?.data?.link_fields]);
    //   })
    //   .catch((error) => {
    //   });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetLoginFormField();
  }, []);
  return (
    <>
      <MainLayout>
        <Banner first={language ? "होम" : "Home"} second={language ? "लोगइन पृष्ठ" : "Login Page"} head={language ? "लोगइन" : "Login "} />
        <LoginForm loginFormData={loginFormData} buttData={buttData} formData={formData} setLoginFormData={setLoginFormData} />
      </MainLayout>
    </>
  )
}
export default Login