import AdminDashboard from '../AdminDashboard/AdminDashboard'
import React, { useEffect, useState } from 'react'
import "../Admin.css"
import { BsChevronLeft } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import { HomeIcon } from '../../../components/Application/ApplicationIcon'
import { useForm, useFieldArray } from "react-hook-form"
import { useLocation, useNavigate } from 'react-router-dom'
import { addNewDropDownList, addSubSectionData, getFontFamilyData, updateNewDropDownList, updateStateData, updateSubSectionData } from '../../../services/api/admin'
import { checkPattern, checkPatternWithSpecialCharacter, customStylesAcc, selectTheme } from '../../../Utils/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DeleteIcon } from '../AdminIcon'
import Select from 'react-select';
import FormSubSectionAccordion from '../../../components/Accordion/FormSubSectionAccordion'



const AddAdminSubSection = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [nameEn, setNameEn] = useState('');
    const [nameHi, setNameHi] = useState('');
    const [nameEnError, setNameEnError] = useState('');
    const [nameHiError, setNameHiError] = useState('');
    const [editData, setEditData] = useState();  // Holds data for edit scenario
    // const [validation, setValidation] = useState([{ value: '' }])
    // const [validationFields, setValidationFields] = useState([{ value: '' }])
    const [fontColors, setFontColors] = useState({});
    const [backgroundColors, setBackgroundColors] = useState({});
    const [fontFamily, setFontFamily] = useState();
    const [fontFamilyError, setFontFamilyError] = useState();
    const [fontsList, setFontsList] = useState()
    const [openIndex, setOpenIndex] = useState(0); // State to track the open accordion index
    const { control, handleSubmit, register, reset, setValue, getValues } = useForm({
        defaultValues: {
            name_en: '',
            name_hi: '',
            items: [{
                name_en: '',
                name_hi: '',
                placeHolder_en: '',
                placeHolder_hi: '',
                font_size: '',
                font_color: '#000000',  // Default font color
                // background_color: ''  // Default background color
            }],
        },
    });

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'items',
    });
    const [apiMessage, setApiMessage] = useState({ type: '', message: '' });


    // Populate form for edit scenario
    useEffect(() => {
        getFontFamily()
        if (location?.state && location.pathname === "/admin/form/sub-sections/update") {
            const item = location.state;
            editStoredData(item);

            const parsedData = JSON.parse(item.json_value);
            reset({
                name_en: item?.name_en,
                name_hi: item?.name_hi,
                items: parsedData.items,
            });
        }
    }, [location?.state, reset]);

    const editStoredData = (item) => {
        const parsedData = JSON.parse(item?.json_value); // Parse the dropdown values

        setEditData(item);
        setNameEn(item?.name_en);
        setNameHi(item?.name_hi);

        // Reset form with the parsed data
        reset({
            items: parsedData?.items,
        });
        const initialFontColors = parsedData?.items?.reduce((acc, curr, idx) => ({
            ...acc,
            [idx]: curr?.font_color || '#000000'
        }), {});

        // const initialBackgroundColors = parsedData.items.reduce((acc, curr, idx) => ({
        //     ...acc,
        //     [idx]: curr.background_color 
        // }), {});
        setFontColors(initialFontColors);
        // setBackgroundColors(initialBackgroundColors);

        // Set the validation fields if they exist
        // if (parsedData.items && parsedData.items[0].validations) {
        //     const validations = parsedData.items[0].validations.map((value) => ({ value }));
        //     setValidationFields(validations);
        // }
    };

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        const cursorPosition = e.target.selectionStart;
        if (name == "nameEn") {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                return;
            } else {
                setNameEnError("")
                setNameEn(value)
            }
        } else if (name == "nameHi") {
            if (await checkPatternWithSpecialCharacter(value) == false) {
                return;
            } else {
                setNameHiError("")
                setNameHi(value)
            }
        }
        setTimeout(() => {
            e.target.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
        validateForm(name, value);
    };

    const validateForm = (name, value) => {
        console.log(name, value)
        if (name == "nameEn") {
            if (!value.trim()) {
                setNameEnError("Please Enter English Name")
                return false;
            } else {
                setNameEnError("")
            }
        } else if (name == "nameHi") {
            if (!value.trim()) {
                setNameHiError("Please Enter Hindi Name")
                return false;
            } else {
                setNameHiError("")
            }
        }

        return true;
    };

    const onSubmit = async (data) => {
        const isNameEnValid = validateForm("nameEn", nameEn);
        const isNameHiValid = validateForm("nameHi", nameHi);

        if (!isNameEnValid || !isNameHiValid) {
            return;
        }

        const formData = new FormData();
        formData.append('name_en', nameEn);
        formData.append('name_hi', nameHi);
        formData.append('json_value', JSON.stringify({ items: data.items }));
        // formData.append('json_value', JSON.stringify({ items: data.items, validations: validationFields.map(v => v.value) }));


        try {
            if (editData) {
                formData.append('sub_section_id', editData.id);
                formData.append('status', editData.status);
                const response = await updateSubSectionData(formData);
                if (response?.status === 200) navigate("/admin/form/sub-sections", { state: { message: response.message } });
            } else {
                const response = await addSubSectionData(formData);
                if (response?.status === 200) navigate("/admin/form/sub-sections", { state: { message: response.message } });
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // const handleValidationAddInput = () => {
    //     if (validationFields.length < 5) {
    //         setValidationFields([...validationFields, { value: '' }]);
    //     }
    // };

    // const handleValidationRemoveInput = (index) => {
    //     const newValidationFields = validationFields.filter((_, i) => i !== index);
    //     setValidationFields(newValidationFields);
    // };

    // const handleValidationChange = (index, event) => {
    //     const newValue = event.target.value; // Get the new value from the event

    //     // Use functional updates to ensure we're using the latest state
    //     setValidationFields(prevFields =>
    //         prevFields.map((field, i) => {
    //             if (i === index) {
    //                 // Update the specific field at the index
    //                 return { ...field, value: newValue };
    //             }
    //             return field; // Return the other fields unchanged
    //         })
    //     );
    // };

    const getFontFamily = async () => {
        try {
            const fontFamilyData = await getFontFamilyData()
            const data = fontFamilyData?.data?.map(i => ({
                label: i?.name, value: i?.id
            }))

            setFontsList(data)
        } catch (err) {
            console.log("font families error :", err)
            // setApiMessage({ type: 'error', message: err?.message });
        }
    };

    const handleColorChange = (index, colorType, colorValue) => {
        if (colorType === "font") {
            setFontColors({ ...fontColors, [index]: colorValue || '#000000' });
        }
    };

    return (
        <AdminDashboard>
            <div className='aod-outer'>
                <div className='ap-breadcrumb pt-2 pb-2 mb-2 adm-breadcrumb'>
                    <div>
                        <HomeIcon />
                        <b onClick={() => navigate("/admin/")}>Home /</b>
                        <span> Sub Section Form</span>
                    </div>
                </div>

                <div className="aod-inner">
                    <div className={editData ? "aod-update aod-bottom" : "aod-bottom"}>
                        <div className="aod-head text-center adm-head">
                            <button onClick={() => navigate("/admin/form/sub-sections")}>
                                <BsChevronLeft /> Back
                            </button>
                            {editData ? "Update" : "Add"} Sub Section
                        </div>

                        <div className="row py-4">
                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'> English Name</label>
                                    <input
                                        className='w-100'
                                        placeholder='Enter English Name'
                                        type="text"
                                        name="nameEn"
                                        value={nameEn}
                                        maxLength={40}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    {nameEnError && <p className='validate-error'>{nameEnError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4 aod-resp-tab">
                                <div className='suf-input-box aod-form-input mb-2'>
                                    <label className='mb-1 w-100'> Hindi Name</label>
                                    <input
                                        className='w-100'
                                        placeholder='Enter Hindi Name'
                                        type="text"
                                        name="nameHi"
                                        maxLength={40}
                                        value={nameHi}
                                        onChange={(e) => { handleInputChange(e) }}
                                    />
                                    {nameHiError && <p className='validate-error'>{nameHiError}</p>}
                                </div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label className='mb-1 w-100 fw-semibold'>Sub Section Fields</label>
                            {fields.map((item, index) => (
                                <FormSubSectionAccordion
                                    key={item.id}
                                    title={`Field ${index + 1}`}
                                    isOpen={openIndex === index} // Determine if this accordion is open
                                    onToggle={() => setOpenIndex(openIndex === index ? null : index)} // Toggle the accordion
                                    onRemove={() => remove(index)} // Remove the item
                                >
                                    <div className="row py-1">
                                        {/* Input Fields */}
                                        <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <input
                                                    className='w-100 mb-2'
                                                    maxLength={40}
                                                    {...register(`items.${index}.name_en`)}
                                                    placeholder="Name (EN)"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <input
                                                    className='w-100 mb-2'
                                                    maxLength={40}
                                                    {...register(`items.${index}.name_hi`)}
                                                    placeholder="Name (HI)"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <input
                                                    className='w-100 mb-2'
                                                    maxLength={100}
                                                    {...register(`items.${index}.placeHolder_en`)}
                                                    placeholder="PlaceHolder (EN)"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <input
                                                    className='w-100 mb-2'
                                                    maxLength={100}
                                                    {...register(`items.${index}.placeHolder_hi`)}
                                                    placeholder="PlaceHolder (HI)"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <input
                                                    className='w-100'
                                                    placeholder='Enter Font Size'
                                                    type="number"
                                                    max={25}
                                                    min={1}
                                                    {...register(`items.${index}.font_size`)}
                                                    onInput={(e) => {
                                                        // Limit value to a maximum of 25 and minimum of 1
                                                        if (e.target.value > 25) e.target.value = 25;
                                                        if (e.target.value < 1) e.target.value = '';
                                                    }}
                                                    onPaste={(e) => {
                                                        e.preventDefault(); // Prevent pasting
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <label className='adm-label-color'>
                                                    {fontColors[index] || "Select Font Color"}
                                                    <input
                                                        className='adm-color'
                                                        type="color"
                                                        {...register(`items.${index}.font_color`)}
                                                        value={fontColors[index] || "#000000"}
                                                        onInput={(e) => handleColorChange(index, "font", e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <label className='adm-label-color'>
                                                    {backgroundColors[index] || "Select Background Color"}
                                                    <input
                                                        className='adm-color'
                                                        type="color"
                                                        {...register(`items.${index}.background_color`)}
                                                        value={backgroundColors[index] || ""}
                                                        onInput={(e) => handleColorChange(index, "background", e.target.value)}
                                                    />
                                                </label>
                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <input
                                                    className='w-100 mb-2'
                                                    {...register(`items.${index}.validation`)}
                                                    placeholder="Validation"
                                                    required
                                                />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-4 aod-resp-tab">
                                            <div className='suf-input-box aod-form-input mb-2'>
                                                <Select
                                                    styles={customStylesAcc}
                                                    theme={selectTheme}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    className='menu_open_select'
                                                    classNamePrefix='ad-menu_open_select_class'
                                                    value={fontFamily}
                                                    options={fontsList}
                                                    onChange={(e) => { setFontFamily(e); setFontFamilyError("") }}
                                                    placeholder="Select Font Family"
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                </FormSubSectionAccordion>
                            ))}
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="gap-4 d-flex justify-content-between">
                                        <button className='add-btn cta-f'
                                            type="button"
                                            onClick={() => {
                                                append({
                                                    name_en: "",
                                                    name_hi: "",
                                                    placeHolder_en: "",
                                                    placeHolder_hi: "",
                                                    font_size: "",
                                                    font_color: "",
                                                    background_color: "",
                                                    validation: "",
                                                });
                                                setOpenIndex(fields.length);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} className="me-1" />
                                            Add More
                                        </button>
                                        <button className="ad-reset-btn" type="submit">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </AdminDashboard>
    )
}

export default AddAdminSubSection
