import React, { useEffect, useState } from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import "../User/User.css";
import { updateUserEmailMobileNumberAPI, getUserEmailMobileNumberAPI } from "../../services/api/user";
import { Message } from '../../components/message';

const ChangeRequest = () => {
  const { language , setLanguage } = useLanguage();
  const userDetail = JSON.parse(localStorage.getItem("userDetail") || "{}");
  const [oldEmail, setOldEmail] = useState(userDetail.email || "");
  const [oldPhoneNumber, setOldPhoneNumber] = useState(userDetail.mobile_number || "");
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [apiMessage, setApiMessage] = useState({ type: '', message: '' });
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetail] = useState({});
  const [status, setStatus] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setUserId(userDetail.id || "");
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await getUserEmailMobileNumberAPI();
        setUserDetail(response?.data);
        setStatus(response?.data?.status); // Save status for disable check
        if (response?.data && response?.data?.status === '0') {
          if (response.data.new_email) {
            setNewEmail(response.data.new_email);
          }
          if (response.data.new_mobile) {
            setNewPhoneNumber(response.data.new_mobile);
          }
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    loadData();
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[6-9]\d{9}$/;

  const validateEmail = () => {
    if (newEmail && !emailRegex.test(newEmail)) {
      setErrors((prevErrors) => ({ ...prevErrors, newEmail: "Invalid email format." }));
      return false;
    }
    setErrors((prevErrors) => {
      const { newEmail, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validatePhoneNumber = () => {
    if (newPhoneNumber && !phoneRegex.test(newPhoneNumber)) {
      setErrors((prevErrors) => ({ ...prevErrors, newPhoneNumber: "Phone number must be 10 digits long." }));
      return false;
    }
    setErrors((prevErrors) => {
      const { newPhoneNumber, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateAtLeastOneField = () => {
    if (!newEmail && !newPhoneNumber) {
      setErrors({ form: "Please enter at least one of the new email or new phone number." });
      return false;
    }
    setErrors((prevErrors) => {
      const { form, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAtLeastOneFieldFilled = validateAtLeastOneField();
    const isEmailValid = validateEmail();
    const isPhoneValid = validatePhoneNumber();

    if (isAtLeastOneFieldFilled && isEmailValid && isPhoneValid) {
      let requestType = 0;
      if (newEmail && newPhoneNumber) {
        requestType = 3;
      } else if (newEmail) {
        requestType = 1;
      } else if (newPhoneNumber) {
        requestType = 2;
      }

      const payload = {
        old_email: oldEmail,
        old_mobile_number: oldPhoneNumber,
        new_email: newEmail,
        new_mobile_number: newPhoneNumber,
        user_id: userId,
        type: requestType,
      };

      try {
        const response = await updateUserEmailMobileNumberAPI(payload);
        if (response?.status === 200) {
          setApiMessage({ type: "success", message: response.message });
        } else if (response?.data) {
          const errorMessages = [];
          for (const field in response.data) {
            if (response.data[field] && response.data[field].length > 0) {
              errorMessages.push(response.data[field][0]);
            }
          }
          setApiMessage({ type: "error", message: errorMessages.join(", ") || response.message });
        }
      } catch (error) {
        setApiMessage({ type: "error", message: error.message });
      }
    }
  };

const isNewEmailDisabled = status === '0' && !!newEmail;
const isNewPhoneDisabled = status === '0' && !!newPhoneNumber;
const isSubmitDisabled = isNewEmailDisabled && isNewPhoneDisabled;

  return (
    <div>
      <Dashboard
        first={language ? "होम" : "Home"}
        second={language ? "मोबाइल/ईमेल परिवर्तन अनुरोध" : "Mobile/Email Change Request"}
        head={language ? "मोबाइल/ईमेल परिवर्तन अनुरोध" : "Mobile/Email Change Request"}
      >
        <form onSubmit={handleSubmit} className="aod-bottom">
          {apiMessage && (<Message apiMessage={apiMessage} setApiMessage={setApiMessage} />)}
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">Email</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="test@gmail.com"
                  value={oldEmail}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">Phone Number</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="9876543210"
                  value={oldPhoneNumber}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">New Email</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new Email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  onBlur={validateEmail}
                  disabled={isNewEmailDisabled}
                />
                {errors.newEmail && <p className="text-danger">{errors.newEmail}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3 f_fields">
                <label className="form-label">
                  <span className="en-label">New Phone Number</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter New Phone Number"
                  value={newPhoneNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setNewPhoneNumber(e.target.value);
                    }
                  }}
                  onBlur={validatePhoneNumber}
                  disabled={isNewPhoneDisabled}
                />
                {errors.newPhoneNumber && <p className="text-danger">{errors.newPhoneNumber}</p>}
              </div>
            </div>
          </div>
          {errors.form && <p className="text-danger">{errors.form}</p>}
          <button type="submit" className="btn btn-primary user-theme-btn mt-3" disabled={isSubmitDisabled}>
            Submit
          </button>
        </form>
      </Dashboard>
    </div>
  );
};

export default ChangeRequest;
