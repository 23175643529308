import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";
import {
  toUploadFile,
  uploadDocumentlistData,
  handleDeleteDocumentAPI,
} from "../../services/api/user";
import Upload from "../../assets/images/upload.svg";
import { formatDateWithdash } from "../../Utils/common";
import { Baseurl, GetLocalStorage } from "../../Utils/Utils";

const DocsUpload = (subCatId) => {
  const [documentList, setDocumentList] = useState([]);
  const [baseUrl, setBaseUrl] = useState([]);
  const applicationId = GetLocalStorage("application_id");
  const fileTypeList = [
    { label: "Docx", value: "1", accept: ".docx" },
    { label: "PDF", value: "2", accept: ".pdf" },
    { label: "Xlsx", value: "3", accept: ".xlsx" },
    { label: "Pptx", value: "4", accept: ".pptx" },
    { label: "Txt", value: "5", accept: ".txt" },
    { label: "Csv", value: "6", accept: ".csv" },
    { label: "Jpg/Jpeg", value: "7", accept: ".jpg,.jpeg" },
    { label: "Png", value: "8", accept: ".png" },
  ];

  useEffect(() => {
    docsList();
    setBaseUrl(Baseurl);
  }, []);

  const docsList = async () => {
    try {
      const formData = new FormData();
      formData.append("sub_category_id", subCatId?.subCatId);
      const getList = await uploadDocumentlistData(formData);
      if (getList?.status === 200) {
        setDocumentList(getList?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileChange = (doc, index) => async (e) => {
    const uploadedFile = e.target.files[0];
    const fileType = fileTypeList.find((x) => x.label === doc?.type);

    // Validate file type
    if (
      fileType &&
      fileType.accept &&
      !fileType.accept.includes(uploadedFile.type.split("/")[1])
    ) {
      alert(`Invalid file type. Only ${doc?.type} files are allowed.`);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("upload_doc", uploadedFile);
      formData.append("upload_date", formatDateWithdash(new Date()));
      formData.append("category_id", subCatId?.subCatId);
      formData.append("document_type_id", doc?.id);
      formData.append("application_id", applicationId);
      formData.append(
        "upload_document_id",
        doc.upload_documents[0] && doc.upload_documents[0].id
          ? doc.upload_documents[0].id
          : ""
      );
      const data = await toUploadFile(formData);
      if (data?.success === true) {
        docsList();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteDocument = (doc, index) => async (e) => {
    try {
      const payload = {
        upload_document_id:
          doc.upload_documents[0] && doc.upload_documents[0].id
            ? doc.upload_documents[0].id
            : "",
      };
      const data = await handleDeleteDocumentAPI(payload);
      if (data?.status === 200) {
        docsList();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="">
      <div className="row mt-5">
        {documentList?.map((doc, index) => (
          <div className="col-lg-6 col-md-12 col-sm-12" key={index}>
            <div className="documents_block">
              <div className="documents_detail w-100">
                <h5 className="mb-0">
                  <span className="en-label">
                    {index + 1} {doc.name_en}
                  </span>
                </h5>
                <div className="upload-document-text">
                  {doc.upload_documents[0] && doc.upload_documents[0].filename
                    ? doc.upload_documents[0].filename
                    : "Name of Upload"}
                </div>
              </div>

              <div className="upload-icons d-flex align-items-end flex-column justify-content-end w-100">
                <div className="u_inner_icons d-flex mb-2">
                  <label
                    htmlFor={`general_certificate_${index}`}
                    className="upload-label"
                  >
                    <div className="doc-icon upload file_need_to_be_upload">
                      <div className="doc-icon upload-icon preview">
                        <img src={Upload} alt="Upload Icon" />
                      </div>
                    </div>
                    <input
                      id={`general_certificate_${index}`}
                      type="file"
                      className="upload_document"
                      accept={
                        fileTypeList.find((x) => x.label === doc?.type)
                          ?.accept || ""
                      }
                      required
                      onChange={(e) => {
                        handleFileChange(doc, index)(e);
                        e.target.value = null; // Reset input value to allow re-selection of the same file
                      }}
                      style={{ display: "none" }}
                    />
                  </label>

                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        doc.upload_documents[0] &&
                        doc.upload_documents[0].path
                      ) {
                        window.open(
                          baseUrl + "/" + doc.upload_documents[0].path,
                          "_blank"
                        );
                      } else {
                        alert("No preview available.");
                      }
                    }}
                  >
                    <div className="doc-icon preview-icon delete">
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                  </a>
                  <a href="#">
                    <div
                      className="doc-icon del-icon preview"
                      onClick={(e) => handleDeleteDocument(doc, index)(e)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </div>
                  </a>
                </div>

                <div className="instructions">
                  <p className="mb-0 pdf-support">(Only {doc?.type} upload)</p>
                  <p className="mb-0 file-size">
                    File Size {doc?.size / 1000}MB Max.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocsUpload;
