import React from "react";
import { useLanguage } from "../LanguageProvider/LanguageProvider ";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ComingSoon from "../ComingSoon/ComingSoon";

const Certificate = () => {
  const { language, setLanguage } = useLanguage();
  return (
    <Dashboard
      first={language ? "होम" : "Home"}
      second={language ? "प्रमाणपत्र" : "Certificate"}
      head={language ? "प्रमाणपत्र" : "Certificate"}
    >
      <>
        <div className="aod-bottom">
          <div className="page_heading">
            <h5>Certificate</h5>
          </div>
          <div class="row">
            <div class="reciept-table table-responsive mt-2">
              <table
                class="table table mb-0 dataTable transaction_table"
                id="receipt_table"
                role="grid"
              >
                <thead class="">
                  <tr role="row">
                    <th>S.No</th>
                    <th>Certificate Number</th>
                    <th>Date</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row">
                    <td>1</td>
                    <td>chamoli/09-2024/169918</td>
                    <td>16-09-2024 11:25:05 PM</td>
                    <td>
                      <a
                        href=""
                        class="btn btn-green-view"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </Dashboard>
  );
};

export default Certificate;
