import React, { useEffect, useState } from 'react'
import { empDesignationData, subSectionData } from '../../services/api/user';

const TableTab = ({
    fieldData,
    subCatId,
    language,
    formValues,
    onChange,
}) => {
    const [designationList, setDesignationList] = useState([])
    const [tableValue, setTableValue] = useState()
    useEffect(() => {
       
        // If formValues exist, use them to initialize tableValue
        if (formValues && formValues[subCatId]) {
            setTableValue(formValues[subCatId]);
        } else {
            // If no initial formValues, fetch data to populate designationList
            const fetchOptions = async () => {
                try {
                    const formData = new FormData();
                    formData.append("sub_category_id", subCatId);

                    const response = await empDesignationData(formData);
                    setDesignationList(response?.data || []);

                    // Initialize tableValue for each item in designationList if not already in formValues
                    const initialTableValue = {};
                    response?.data.forEach(item => {
                        initialTableValue[item.id] = formValues[item.id] || { name: '', age: '' }; // Use formValues if available
                    });
                    setTableValue(initialTableValue);

                } catch (error) {
                    console.error("Error fetching dropdown options:", error);
                }
            };
            fetchOptions();
        }
    }, [fieldData, subCatId, formValues]);

      

    const handleInputChanges = (id, name, value) => {
        setTableValue(prevTableValue => {
            const updatedValue = {
                ...prevTableValue,
                [id]: {
                    ...(prevTableValue[id] || {}),
                    [name]: value,
                },
            };
            console.log("==========???>>>>>>>>", tableValue)
            // Call onChange prop with updated data for parent component
            onChange({
                target: {
                    name,
                    value: updatedValue
                }
            });

            return updatedValue;
        });
    };
  


    return (
        <div className="">
            <div className="row">
                <div className="col-md-12">
                    <div className="additional-detail-c">
                        <p className="fw-semibold">
                            Owner must provide correct and current staff
                            details to complete the registration. Once final
                            submission is done, owner will not be able to
                            modify any staff detail. In case of replacement
                            or any change in the staff details, owner may
                            request to the respective DTDO to make changes
                            in the records.
                            {/* {JSON.stringify(designationList)} */}
                        </p>
                    </div>
                    <div className=""></div>
                </div>
                <div class="col-lg-12">
                    <div class="card mb-3">
                        <div class="card-body bg-card user-tab-table ">
                            <div class="table-responsive">
                                <table class="table  bg-table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Designation </th>
                                            <th scope="col"> No. of People </th>
                                            <th scope="col"> Average Experiance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {designationList?.map((item) => (
                                            <tr key={item.id}>
                                                <td>{language ? item?.name_hi : item?.name_en}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        max={25}
                                                        // min={1}
                                                        name="name"
                                                        value={(tableValue[item.id] && tableValue[item.id].name) || undefined}
                                                        className="form-control txt-input"
                                                        placeholder="Enter Name"
                                                        // onChange={(e) => handleInputChanges(item.id, e.target.name, e.target.value)}
                                                        onInput={(e) => {
                                                            // Limit value to a maximum of 25 and minimum of 1
                                                            if (e.target.value > 25) e.target.value = 25;
                                                            if (e.target.value < 1) e.target.value = '';
                                                            handleInputChanges(item.id, e.target.name, e.target.value)
                                                        
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault(); // Prevent pasting
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        max={150}
                                                        // min={1}
                                                        name="age"
                                                        // value={(tableValue[item.id] && tableValue[item.id].age) || ''}
                                                        className="form-control txt-input"
                                                        placeholder="Enter age"
                                                        // onChange={(e) => handleInputChanges(item.id, e.target.name, e.target.value)}
                                                        onInput={(e) => {
                                                            // Limit value to a maximum of 25 and minimum of 1
                                                            if (e.target.value > 150) e.target.value = 150;
                                                            if (e.target.value < 1) e.target.value = '';
                                                            handleInputChanges(item.id, e.target.name, e.target.value)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault(); // Prevent pasting
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                       
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default TableTab
