import Banner from "../../components/Banner/Banner";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider ";
import MainLayout from "../../components/MainLayout/MainLayout";
import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import { ClearLocalStorage } from "../../Utils/Utils";
import { getHomePageContent } from "../../services/api/outer";

const PrivacyPolicy = () => {
  const { language, setLanguage } = useLanguage();
  const [homePageData, setHomePageData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const [homePageDataResponse] = await Promise.all([
          getHomePageContent(),
        ]);
        setHomePageData(homePageDataResponse);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    ClearLocalStorage();
  }, []);

  if (loading || homePageData === null) {
    return <></>;
  }
  return (
    <>
      <div>
        <MainLayout homePageData={homePageData}>
          {/* <section class="banner-section contact-banner">
            <div class="container">
              <div class="banner-breadcrumb">
                <h5>
                  <a class="home-link" href="/">
                    <span>Home &gt;</span>
                  </a>
                  <span> Privacy Policy</span>
                </h5>
              </div>
            </div>
          </section> */}
          <section class="privacy-policy section-padding">
            <div class="container">
              <div className="privacy-wrapper">
                <div className="privacy-heading">
                  <div class="text-center pt-2 pb-3 hcat-main">
                    <p class="hex-p">Privacy Policy</p>
                    <div class="hex-explore">
                      Explore Travel Trade Privacy Policies
                    </div>
                  </div>
                </div>
                <p>
                  <span>
                    There are many such tourist places located in the remote
                    rural areas of Uttarakhand, which contain their natural
                    beauty and cultural heritage, but due to lack of proper
                    accommodation and food facilities for the tourists at those
                    places, they are unable to visit these tourist places. Are
                    deprived of enjoying. Through the Guest Uttarakhand Homestay
                    Rules, efforts are being made to attract tourists in urban
                    areas as well as remote tourist areas of the state, to
                    increase quality residential facilities, and to promote
                    self-employment at the local level.
                  </span>
                </p>
                <p>
                  <span>
                    Through the Guest Uttarakhand Homestay Rules, to attract
                    tourists in the urban areas of the state as well as in
                    remote tourist areas, to increase the standard of
                    residential facilities, to provide self-employment
                    opportunities at the local level and to provide additional
                    income to the building owners. To provide resources, guest
                    Uttarakhand homestay rules are being prepared.&nbsp;
                  </span>
                </p>
                <p>&nbsp;</p>

                <div class="pdf-link-block mt-5">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Sr. no</th>
                        <th>File</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="align-middle">
                        <td>1</td>
                        <td>Uttarakhand Travel Trade Policy One</td>

                        <td>
                          <a class="btn btn-green desktopBtn d-block" href="">
                            <i class="bi bi-download bi-icon-font me-1"></i>{" "}
                            Download
                          </a>
                        </td>
                      </tr>
                      <tr class="align-middle">
                        <td>2</td>
                        <td>Uttarakhand Travel Trade Policy two</td>

                        <td>
                          <a
                            class="btn btn-green desktopBtn green-view d-block"
                            href=""
                          >
                            <i class="bi bi-download bi-icon-font me-1"></i>{" "}
                            Download
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </MainLayout>
      </div>
    </>
  );
};
export default PrivacyPolicy;
